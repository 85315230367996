const campaigns = {
    advanceSearch: 'بحث متقدم',
    duration: 'المدَّة',
    regionCoverage: 'المنطقة',
    AverageEarn: 'متوسط الإيرادات',
    like: 'إعجاب',
    joinNow: 'انضم الآن!',
    joined:"الانتقال إلى المهمة",
    comingSoon: 'قريباً',
    expired: 'منتهي الصلاحية',
    rewards:'المكافآت',
    worldWide:'حول العالم',

    


}

export default campaigns