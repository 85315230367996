var host

if(window.location.host ==="p.anystarr.com") {
    host = 'http://papi.anystarr.com'
}else if(window.location.host === "any.clubchopp.com") {
    host = 'http://any.clubchopp.com'
}else if(window.location.host === "local.clubchopp.com:3001") {
    host = 'http://any.clubchopp.com'
}
else {
    host = 'https://app.anystarr.com/anystarr-new'
}


module.exports = host
