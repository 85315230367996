import TaskDetail from "../../views/TaskDetail/TaskDetail"

const taskDetail = {
    statusFur: 'التفسير الإضافي للحالة، عند الحاجة',
    step1: 'الخطوة الأولى',
    stepDes1: 'تقدّم واحصل على رمز الحسم الخاص بك',
    applyPromoCode: 'قم بتطبيق رمز الترويج',
    howToUse: 'كيف تستخدم رمز الترويج بكفاءة؟',
    copyCode: 'نسخ',
    step2: 'الخطوة الثانية',
    stepDes2: 'شاركه على مواقع التواصل الاجتماعي',
    step3: 'الخطوة الثالثة',
    stepDes3: 'حمِّل لقطات شاشة كدليل',
    newImage: 'صورة جديدة',
    inputWebsitelink: 'حمِّل روابط المشاركة كدليل',
    addUri: 'أضف المزيد من الروابط',
    submit: 'إرسال',



    endDate: 'تاريخ الإغلاق',
    discountCode: 'رمز الحسم',

    clicktoApply: 'رمز الترويج',
    noCode: 'تاريخ استحقاق الرمز الجديد',

    step1: "تقدّم واحصل على رمز الحسم الخاص بك ",
    step2: "شاركه على مواقع التواصل الاجتماعي",
    step3: "حمّل لقطات شاشة وروابط لمنشوراتك الخاصة بهذه الحملة",

    newImages: "ملفات جديدة",

    upPic:"يدعم 5 صيغ وهي: JPG, JPEG, PNG (3 ميغا بايت كحد أقصى)",

    upLink: "3 روابط كحد أقصى",

    addMoreLink: "إضافة المزيد من الروابط",

    submit: "إرسال",


    submitScreenshot:"أرسل لقطة شاشة لمنشورك على مواقع التواصل الاجتماعي",
    submitLinkL:"أرسل رابطاً لمنشورك على مواقع التواصل الاجتماعي",
    

    sampleStepDes:"تقدّم واحصل على عيِّنة",
    applySample:"انقر للتقديم",

}

export default taskDetail