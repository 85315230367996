const account = {
   myAccount: 'حسابي',
   myProfile: "ملفي التعريفي",
   shippingAddress: "عنوان الشحن",
   bankAccount: "حساب المصرف",
   myEarnings: "أرباحي",
   earningHistory: "سجل الأرباح",
   withdraw: "سحب",



   logout: "تسجيل الخروج",

   personalInfo: "المعلومات الشخصية",
   email: "البريد الإلكتروني",
   invitationCode: "رمز الدعوة",
   name:"الاسم",
   gender: "الجنس",
   language:"اللغة",
   password:"كلمة المرور",
   confirmPassword:"تأكيد كلمة المرور",
   changePassword:"تغيير كلمة المرور",
   male:"ذكر",
   female:"أنثى",
   other:"أفضِّل عدم الإفصاح",

   socialProfiles: "ملف التعريف على مواقع التواصل الاجتماعي",
   follower:"موقع أكثر المتابعين",
   fbName:"اسم المستخدم في فيسبوك",
   insName:"حساب إنستغرام",
   tiktokName:"حساب تيكتوك",

   save:"حفظ",


   nil:"لا شيء",
   edit:"تعديل",
   

}

export default account