const login = {
    emailPlacehold: 'البريد الإلكتروني',
    passwordPlacehold: 'كلمة المرور',
    login: 'تسجيل الدخول',
    forgetPassword: 'نسيت كلمة المرور',
    notMember: "لا تملك حساباً؟",
    clickToRegister: 'التسجيل لأول مرة',
    or: 'أو',
    socialAccount: 'تسجيل الدخول باستخدام حسابات مواقع التواصل الاجتماعي',
    pleaseAccount: 'البريد الإلكتروني مفقود',
    pleasePassword: 'كلمة المرور مفقودة',

    resetPassword:"إعادة تعيين كلمة المرور",
    resetPasswordDes:"يرجى إدخال كلمة المرور وتأكيدها في الأسفل للوصول إلى الحساب",
    invitecodeTitle:"للتقدُّم، يرجى إدخال رمز الدعوة الذي حصلت عليه من ممثلينا.",
    emailCodeTitle:"أدخل رمز التحقق الذي تم إرساله إلى بريدك الإلكتروني",
    emailCodeDes:"أخبرنا إن كان هذا عنوان بريدك الإلكتروني",
    complete:"اكتمل",
    sendAgain:"إعادة الإرسال",

    haveAccount:'تملك حساباً مسبقاً',

    activationDes:"اقتربت من الانتهاء",
    activationDes1:"أعد تعيين كلمة المرور لتفعيل حسابك",

    linkExpired:"انتهت صلاحية رابط التفعيل",
    requestNewActivation:"اطلب رسالة تفعيل جديدة أو أنشئ حساباً جديداً",
    confirm:"تأكيد",
    googleRegister:"سجِّل باستخدام غوغل",
    sendAcEmail:"ربما قمت بالتسجيل مسبقاً، تابع لتفعيل حسابك من خلال رسالة التفعيل المرسلة إلى ",
    hadRegister:"ربما أنشأت حساباً مسبقاً، استمر بتسجيل الدخول",

}
export default login