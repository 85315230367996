const register = {
    yourInvitecode: 'Kod zaproszenia',
    yourEmail: 'E-mail',
    passwordPlacehold: 'Has³o',
    passwordPlease: 'Nie podano has³a',
    passwordRegx: 'Has³o musi zawieraæ od 8 do 16 znaków i co najmniej jedna wielk¹ literê (A–Z) lub jedn¹ ma³¹ literê (a–z)',
    passwordConfirmPlacehold: 'PotwierdŸ has³o',
    passwordConfirmPlease: 'Nie potwierdzono has³a',
    passwordConfirmRegx: 'Has³a s¹ niezgodne.',
    // countryUpText: 'jak obszar dystrybucji? (maks. 5)',
    countryPlease: 'Kraj/region',
    register: 'Zarejestruj siê',
    agreeTo: 'Rejestruj¹c siê, wyra¿asz zgodê na nasz',
    termsAndConditions: 'Regulamin',
    follower: "G³ówne lokalizacje obserwuj¹cych (maks. 5)",
    submit:'Zarejestruj siê',
    howHearAbout:'Skąd dowiedzieli się Państwo o naszej firmie?',

}
export default register