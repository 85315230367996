import zh_CN from './zh_CN'
import en_US from './en_US'
import fr_FR from './fr_FR'
import es_ES from './es_ES'
import pl_PL from './pl_PL'
import pt_BR from './pt_BR'
import ar_AR from './ar_AR'

export default {
    pl_PL,
    zh_CN,
    en_US,
    fr_FR,
    es_ES,
    pt_BR,
    ar_AR,
}