const common =  {
    login: ' Entrar/Se inscrever',
    loginS: "Entrar",
    signUp: "Se inscrever",
    contactUs: 'Contate-nos',
    allCampaigns: 'Todas campanhas',
    myTasks: 'Minhas tarefas',
    messages: 'Mensagens',
    search: 'Pesquisar',
    allRegion: 'Todas regiões',
    france: 'França',
    spain: 'Espanha',
    rejectReason:'Razão da rejeição',

    next:"Próximo",
    forgetPwd:"Esqueci a senha",
    pleasePwd:"Por favor digite sua senha atual",
    pleaseEmail:"Por favor digite seu email",
    invalidLink:"Link inválido",

    referNow: "REFERIR AGORA E SER RECOMPENSADO",
    enjoyColl:"Desfrute de colaborar com suas marcas favoritas? Diga à um amigo!",
    earn:"Ganhar",
    commissionOf:" de comissão de quaisquer ganhos seus amigos ganham",
    referralCode:"Código de Referencia",
    inviteFiendsAndEarn:"Convidar amigos e Ganhe",
    howToParti:"Como participar",
    launchanyStarr:"Lançar anyStarr aplicativo e clique em",
    referYouFri:"Indique seus amigos",
    generateYour:"gere seu",
    andClickOn:"e clique em",
    inviteMoreFri:"Convide mais amigos",
    share:"Compartilhar",
    yourUniqueRefe:"seu código de referência único, com seus amigos e comece a ganhar",
    earningRules:"ganhando Regras",
    aSuccessfulReferral:"Um sucesso meios de referência que o seu amigo sinais referidos acima de usar seu código de referência.",
    youWillOnlyRe:"Você só vai receber uma comissão de lucros dos seus amigos por 90 dias a partir da data de inscrição até $ 1000 no total.",
    myInvite:"meus convida",
    inviteDes1:"Pendente campanha início",
    inviteDes2:"campanha participou",
    inviteMoreFri:"Convide mais amigos",
    FAQ:"Perguntas frequentes",
    question1:"Por que não recebi minhas recompensas depois que meus amigos I refere inscreveram para anyStarr?",
    questionDes1:"Seu amigo pode ter esquecido de incluir o seu código de referência quando se inscrever, ou ele / ela não começou a ganhar ainda.",
    question2:"Como posso saber se meu amigo referido tem uma inscrição bem-sucedida?",
    questionDes2:"Uma vez que seus amigos de inscrição em anyStarr com seu código de referência, a ID do usuário willl mostrar-se [Meus convida] em [Conta - Convidar amigos e ganhar]",
    question3:"Onde posso encontrar meu código de referência?",
    questionDes3:"Você pode encontrar seu código de referência exclusivo em [Conta - Convidar amigos e ganhar]",
    termsAndCon:"Termos e Condições",
    tc1:"A Consulte-seu-amigos só é elegível para os novos usuários que não se inscreveram.",
    tc2:"Os usuários que já se inscreveram sem um código CAN referência não reaplicar com o código. Suas referências não serão elegíveis para receber esta recompensa.",
    tc3:"anyStarr reserva o direito de alterar a T & C, a qualquer momento sem aviso prévio.",
    tc4:"Ao participar do um Amigo Consulte Programa, você concorda com o T & C.",
    inviteFriendEarn:"Convidar amigos e ganhar",
    mySamples:"Minhas amostras", 
    invitationCode:"Código de convite (opcional)",

    postRequirement:"Requisito de postagem",
    postExample:"Postar exemplo",
    downloadImagesShare:"Baixar imagens e compartilhar",
    caption:"Rubrica",
    copy:"cópia de",
    by:"De",
    submitContent:"Envie sua descrição de conteúdo",
    submitLink:"Envie um link do seu post de mídia social",
    mySampleApplication:"Meu aplicativo de amostra",
    sample:"Amostra",
    status:"Status",
    applicationSubmitted:"Aplicação submetida",
    shippedOn:"Embarcado",
    clickToTrack:"Clique para rastrear",
    congrats:"Parabéns! Sua amostra está pronta para resgatar! O desconto aplicado automaticamente no checkout",
    pendingShipment:"envio pendente",
    applicationUnsuccess:"Aplicação sem sucesso",
    rewards:"Recompensas",
    rewardsDes:"Para cada novo usuário que seu código, você receberá comissão com base no seguinte",
    numberOfCode:"Número de uso de código",
    rewardPerUsage:"Recompensa por uso",
    uploaded:"Carregado!",
    loginFacebookShare:"Login Facebook para compartilhar",
    uploadScreenshots:"Envie suas capturas de tela e link (s)",
    plsApplySampleFirst:"Por favor, aplique uma amostra primeiro",
    plsPatientSampleApproval:"Por favor, seja paciente com a aprovação da amostra",
    taskClosed:"Tarefa fechada",
    newTask:"Nova tarefa",
    nextStep:"Próxima Etapa",
    submitContent:"Enviar conteúdo",
    pendingReview:"Revisão pendente",
    resubmitContent:"Reenvie o conteúdo",
    stepCompleted:"Tarefa completa",

    countryCode:"País",
    firstName:"Primeiro nome",
    lastName:"Último nome",
    bankName:"nome do banco",
    streetAddress:"Endereço",
    city:"Cidade",
    state:"Estada",
    postCode:"Código postal",
    sampleRedeemed:"Amostra resgatada",
    redeemNow:"Resgatar agora",
    deliveryStatus:"Status de entrega",
    inTransit:"Em trânsito",
    notShipped:"Não enviado",
    receivedGoods:"Bens recebidos",
    trackingNum:"Numero de rastreio",
    shippedOn:"Embarcado",
    worldwide:"No mundo todo",
    sampleStatus:"status da amostra",
    applied:"aplicado",
    sampleApplication:"Aplicativo de amostra",
    yourInstagramAccount:"Sua conta do instagram",
    screenshotUpload:"Upload de captura de tela",
    sampleSelected:"Amostra selecionada",
    agreeTerms:"Eu concordo com todos os termos acima",
    myActivity:"Minhas atividades",
    promotions:"Promoções",

}

export default common
