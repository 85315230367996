const register = {
    yourInvitecode: "Code d'invitation",
    yourEmail: 'Adresse e-mail',
    passwordPlacehold: 'Mot de passe',
    passwordPlease: 'Mot de passe est manquante',
    passwordRegx: "Votre mot de passe doit contenir entre 8 à 16 caractères, dont au moins une minuscule, une majuscule ou un caractère spécial.",
    passwordConfirmPlacehold: "Confirmer le mot de passe",
    passwordConfirmPlease: 'Le mot de passe est manquant',
    passwordConfirmRegx: 'Le code de vérification est incorrect',
    // countryUpText: 'D'où viennent vos abonnés ? (max. 5 réponses)',
    countryPlease: 'Pays/Région',
    register: "S'inscrire",
    agreeTo: "En vous inscrivant, vous acceptez nos Termes et Conditions",
    follower: "D'où viennent vos abonnés ? (max. 5 réponses)",
    submit:"S'inscrire",
    howHearAbout:'Comment avez-vous entendu parler de nous?',

}
export default register

