import {
    message
} from 'antd'

// 统一处理服务端的返回结果
export const dealResult = (resp, props) => {
    if (resp.code == '1') {
        return true
    }
    if(resp.code == '45000') {
        let hrefNow = window.location.href
        return window.location.href="/login?redirect="+encodeURI(hrefNow)
    }
    return false
}