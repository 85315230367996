const account = {
   myAccount: 'Moje konto',
   myProfile: "Mój profil",
   shippingAddress: "Adres wysy³ki",
   bankAccount: "Konto bankowe",
   myEarnings: "Moje zarobki",
   earningHistory: "Historia zarobków",
   withdraw: "Wyp³aæ",



   logout: "Wyloguj siê",

   personalInfo: "Dane osobowe",
   email: "E-mail",
   invitationCode: "Kod zaproszenia",
   name:"Imiê",
   gender: "P³eæ",
   language:"Jêzyk",
   password:"Has³o",
   confirmPassword:"PotwierdŸ has³o",
   changePassword:"Zmieñ has³o",
   male:"Mê¿czyzna",
   female:"Kobieta",
   other:"Wolê nie ujawniaæ",

   socialProfiles: "Profil spo³ecznoœciowy",
   follower:"G³ówne lokalizacje obserwuj¹cych",
   fbName:"Nazwa na Facebooku",
   insName:"Nazwa na Instagramie",
   tiktokName:"Nazwa na TikToku",

   save:"Zapisz",


   nil:"Brak",
   edit:"Edytuj",
   

}

export default account