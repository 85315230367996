const common =  {
    login: ' Logowanie/rejestracja',
    loginS: "Zaloguj siê",
    signUp: "Zarejestruj siê",
    contactUs: 'Kontakt',
    allCampaigns: 'Kampanie',
    myTasks: 'Moje zadania',
    messages: 'Wiadomoœci',
    search: 'Szukaj',
    allRegion: 'Wszystkie regiony',
    france: 'Francja',
    spain: 'Hiszpania',
    rejectReason:'Powód odrzucenia',

    next:"dalej",
    forgetPwd:"Nie pamiêtam has³a",
    pleasePwd:"WprowadŸ obecne has³o",
    pleaseEmail:"WprowadŸ adres e-mail",
    invalidLink:"Nieprawid³owe ³¹cze",

    referNow: "POLEĆ TERAZ I ZDOBĄDŹ NAGRODĘ",
    enjoyColl:"Ciesz współpracując z ulubionymi markami? Powiedz przyjacielowi!",
    earn:"Zarabiać",
    commissionOf:" prowizji od jakichkolwiek zarobków znajomych zarobić",
    referralCode:"kod polecającego",
    inviteFiendsAndEarn:"Zaproś przyjaciół i Zarabiaj",
    howToParti:"Jak uczestniczyć",
    launchanyStarr:"Uruchom anyStarr aplikację i kliknij",
    referYouFri:"Polecenie znajomym",
    generateYour:"wygenerować",
    andClickOn:"i kliknij",
    inviteMoreFri:"Zaproś więcej przyjaciół",
    share:"Dzielić",
    yourUniqueRefe:"Twój unikalny kod referencyjny ze swoimi przyjaciółmi i zacznij zarabiać",
    earningRules:"Zarabianie Rules",
    aSuccessfulReferral:"A sukcesy środki skierowań, że określone podpisuje znajomych Korzystanie swój kod polecający.",
    youWillOnlyRe:"Będziesz otrzymywać tylko prowizję od zarobków swoich znajomych do 90 dni od daty ich rejestracji aż do 1000 $ w sumie.",
    myInvite:"Moi zaproszenia",
    inviteDes1:"W oczekiwaniu na rozpoczęcie kampanii",
    inviteDes2:"kampania udział",
    inviteMoreFri:"Zaproś więcej przyjaciół",
    FAQ:"FAQ",
    question1:"Dlaczego ja nie otrzymał nagrody po moich znajomych odniosłem podpisały anyStarr?",
    questionDes1:"Twój przyjaciel może zapomnieli podać swój kod polecający podczas rejestracji albo on / ona nie rozpoczęła jeszcze zarabiać.",
    question2:"Skąd mam wiedzieć, czy mój przyjaciel, o którym mowa ma udanej rejestracji?",
    questionDes2:"Raz znajomych rejestrację na anyStarr z Twojego kodu polecającego, ich ID użytkownika willl pokazać się [Kim zaproszeń] w [Konto - Zaproś przyjaciół i zarabiać]",
    question3:"Gdzie mogę znaleźć mój kod polecający?",
    questionDes3:"można znaleźć pod wyłączną kod polecający [Konto - Zaproś przyjaciół i zarabiać]",
    termsAndCon:"Zasady i warunki",
    tc1:"Patrz-Your-Friends kwalifikuje się tylko do nowych użytkowników, którzy nie zarejestrowali się.",
    tc2:"Użytkowników, którzy wcześniej zarejestrowali się bez kodu polecającego nie może ponownie wystąpić z kodem. Ich odsyłające nie będą kwalifikować się do otrzymania tej nagrody.",
    tc3:"anyStarr zastrzega sobie prawo do zmiany T & C w dowolnym czasie bez uprzedniego powiadomienia.",
    tc4:"Uczestnicząc w odnoszą Przyjacielowi Programu, zgadzają się Państwo na T & C.",
    inviteFriendEarn:"Zaproś przyjaciół i zarobić",
    mySamples:"Moje próbki", 
    invitationCode:"Kod zaproszenia (opcjonalnie)",

    downloadImagesShare:"Pobierz zdjęcia i udostępnij",
    caption:"Podpis",
    copy:"Kopiuj",
    by:"Przez",
    submitContent:"Prześlij swój opis treści",
    submitLink:"Prześlij link swojego poświadczenia mediów społecznościowych",
    mySampleApplication:"Moja przykładowa aplikacja",
    sample:"Próba",
    status:"Status",
    applicationSubmitted:"Wniosek złożony",
    shippedOn:"Wysłane na",
    clickToTrack:"Kliknij, aby śledzić",
    congrats:"Gratulacje! Twoja próbka jest gotowa do wykupienia! Zniżka zostanie automatycznie zastosowana przy kasie",
    pendingShipment:"Oczekuje na przesyłkę",
    applicationUnsuccess:"Aplikacja nieudana",
    rewards:"Nagrody",
    rewardsDes:"Dla każdego nowych użytkowników, którzy Twoim kodem zostaniesz płatną Komisję na podstawie następujących",
    numberOfCode:"Liczba użytkowania kodu",
    rewardPerUsage:"Nagroda za użycie",
    uploaded:"Przesłane!",
    loginFacebookShare:"Zaloguj się Facebook, aby się dzielić",
    uploadScreenshots:"Prześlij swój zrzut ekranu Post i link (y)",
    plsApplySampleFirst:"Najpierw zastosuj próbkę",
    plsPatientSampleApproval:"Bądź cierpliwy z aprobatą próbki",
    taskClosed:"Zadanie zamknięte",
    newTask:"Nowe zadanie",
    nextStep:"Następny krok",
    submitContent:"Prześlij treść",
    pendingReview:"W oczekiwaniu na opinię",
    resubmitContent:"Ponownie zawartość",
    stepCompleted:"Zadanie ukończone",

    countryCode:"kraj",
    firstName:"Imię",
    lastName:"Nazwisko",
    bankName:"nazwę banku",
    streetAddress:"Adres ulicy",
    city:"Miasto",
    state:"Państwo",
    postCode:"Kod pocztowy",
    sampleRedeemed:"Próbka wykorzystana",
    redeemNow:"Zrealizuj teraz",
    deliveryStatus:"Status przesyłki",
    inTransit:"W tranzycie",
    notShipped:"Nie dostarczone",
    receivedGoods:"Otrzymane towary",
    trackingNum:" numer przesyłki",
    shippedOn:"Wysłane na",
    worldwide:"Na calym swiecie",
    sampleStatus:"przykładowy status",
    applied:"stosowany",
    sampleApplication:"Przykładowa aplikacja",
    yourInstagramAccount:"Twoje konto na Instagramie",
    screenshotUpload:"Przesyłanie zrzutów ekranu",
    sampleSelected:"Wybrana próbka",
    agreeTerms:"Zgadzam się na wszystkie powyższe warunki",
    myActivity:"Moje zajęcia",
    promotions:"Promocje",

}

export default common
