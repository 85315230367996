const invitecode = {
    invitecode: 'Código de convite',
    pleaseInvitecode: 'Código de convite inválido',
    joinNow: 'Próximo',
    noCode: 'Sem código de convite？',
    registerHere: 'Se inscrever',


}

export default invitecode