const earn = {
    codesClaimed:"Código válido",
    onGoingTasks:"Tareas en curso",
    totalEarning:"Ganancias Totales",
    earnDes:"Las ganancias se divulgan a mediados de cada mes y se proporcionan únicamente a discreción de la marca de esta campaña.",
    duration:"Duración",
    promoCode:"Código promocional",
    earnUpToDate:"Gana al día",
    updatedOn:"Actualizado el",
    AccountBalance:"Saldo de la cuenta",
    withdraw:"Retiros",
    lasestWithdrawRequest:"Última solicitud de retiro",
    toBankAccount:"A cuenta bancaria",
    withdrawAmount:"Cantidad a retirar",
    yourRequestProcessed:"Su petición está siendo procesada",
    inProgress:"En progreso",
    failed:"Fallido",
    completed:"Completado",
    withdrawHistory:"Historial de retiros",
    cancel:"Cancelar",
    apply:"Aplicar",
    minimum:"mínimo",
    pleaseContact:"Para obtener información mas detallada no dude en contactarnos.",

}
export default earn