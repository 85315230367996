const campaignsDetail = {
    campaignId: 'ID de Campaña',
    campaignType: 'Tipo de Campaña',
    campaignDuration: 'Duración',
    distributionCountry: 'Cobertura de región(es)',
    freeSample: 'Muestra Gratuita',
    averageEarn: 'Ganancia media',
    like: 'Favorito',
    applyNow: 'Únase Ahora',
    task: 'Tarea',
    step: 'Paso',
    step1: 'Solicitar y obtener un código promocional exclusivo.',
    step2: 'Compartirlo en su red social.',
    step3: 'Subir capturas de pantalla o enlaces de evidencia a anyStarr.',

    shortStep1:'Cree contenido que cumpla con los requisitos de la campaña',
    shortStep2:'Subir el borrador de contenido para revisión de la marca.',

    shortStepDes:'shortStepDes',
    shortStepDes1:'Suba un borrador de contenido para su revisión',
    shortStepDes2:'Compartir en las redes sociales',
    shortStepDes3:'Suba la captura de pantalla de su publicación(es) y el enlace(s)',
    sampleSelect:'Si tu estilo encaja con abComo y ganas una muestra, puedes canjear tu muestra a través de www.abComo.com',
    followRule1:'1.Follow @abcomoofficial instagram account',
    followRule2:'2.Commited to post unboxing video + post with yourself wearing it',
    followRule3:'3.Sharing of your unique code to your followers in the post',
    sampleApplication: 'Sample application',
    terms: 'Terms',
    mySampleApplication: 'My sample application',
    submitApplication: 'Submit application',
    yourIns: "Your instagram link",

    abcomoStep1:'Solicitar y recibir la muestra',
    abcomoStep2:'Compartirlo en su red social.',
    abcomoStep3:'Subir capturas de pantalla o enlaces de evidencia a anyStarr.',
}

export default campaignsDetail