const address = {
    shippingAddress: "Endereço de entrega",
    delete: "Deletar",
    edit:"Editar",
    newAddress:"Novo endereço",
    addAddress:"Adicionar endereço",
    name:"Nome",
    contactNum:"Número de contato",
    address:"Endereço",
    country:"País",
    postCode:"Código postal",
    save:"Salvar",
    province:"Província",
    city:"Cidade"

}

export default address