const bank = {
    AddAccount:"إضافة حساب",
    bankPaypal:"حساب المصرف/بيبال",
    accountType:"نوع الحساب",
    accountNo:"حسابك",
    fullNameBank:"الاسم الكامل في حسابك المصرفي",
    bankName:"اسم المصرف",
    swiftCode:"رمز سويفت",
    bank:"مصرف",
    bankAccount:"حساب المصرف",
    paypalAccount:"حساب بيبال",
    acountNo:"حسابك",
    otherBank:"مصرف آخر"

}
export default bank