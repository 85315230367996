const task = {
    searchInput: 'Szukaj we wszystkich zadaniach',
    taskState0: 'Zadanie zamkniête',
    // taskState1: 'Aktywacja w toku',
    taskState1: 'Nowe zadanie',
    taskState2: 'Oczekuje na wys³anie dowodu',
    taskState3: 'Oczekuje na ocenê',
    taskState4: 'Oczekuje na nowy dowód',
    taskState5: 'Potwierdzono dowód',
    all: "Wszystkie zadania",
    status:"Stan zadania",

    taskState7: 'Zawartoœæ oczekuje na ocenê',
    taskState8: 'Ponownie wyœlij treœci',


    applicationDate: 'Data aplikacji',

    activated:"Aktywowano",
    submitted:"Przes³ano",
    verified:"Potwierdzono",

    taskPendContent: 'Oczekiwanie wysyłania treści',

    status:"Status",
    trackingNumber:"Numer identyfikacyjny",
    applicationUnsuccessful:"Wniosek rozpatrzony negatywnie",
    applicationSubmited:"Aplikacja wysłana",
    sampleRedeem:"Próbka jest gotowa do realizacji",
    applicationTime:"Czas stosowania",
    sampleHowToRedeem:"Jak wykupić?",
    samplePending:"Twój przykładowy wniosek jest obecnie sprawdzany przez markę, co może potrwać do 48 godzin",
    samplePendingDes:"Podczas oczekiwania na zatwierdzenie próbki, możesz zacząć dzielić się swoim kodem rabatowym z Twoimi zwolennikami, aby zacząć zarabiać prowizję.",
    sampleDelivery:"O dostawie próbek",
    deliveryDes:"Proszę oczekiwać od 7 do 14 dni roboczych na dostarczenie próbki. Wypełnij swoje zadanie po otrzymaniu próbki. Pomoże nam to zidentyfikować Cię i zaprosić do naszej ekskluzywnej kampanii następnym razem.",
    redeemNow:"Skorzystaj Teraz",

}

export default task