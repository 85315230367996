
const campaigns = {
    advanceSearch: 'Recherche avancée',
    searchInputPlaceholder: 'Recherche',
    duration: "Durée",
    regionCoverage:"Région",
    distributionCountry: 'Couverture de la campagne par région',
    AverageEarn: 'Revenu moyen',
    like: "J'aime",
    joinNow: 'Rejoindre!',
    joined:"Aller à la mission",
    comingSoon: 'Sera lancée ',
    expired: 'Terminé',
    rewards:'Récompenses',
    worldWide:'Dans le monde',
    
    



}

export default campaigns

