import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {message, ConfigProvider} from 'antd';
import {getAntdLocal} from './locales/util.js';
import Layout from './components/Layout'
import {changeLanguage,changeMessageUnread} from './modules/appReducers'
import * as AppActions from './actions/AppActions';
import AsyncLoadable from './utils/AsyncLoadable';
import $ from 'jquery'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './style/base.css'
import './style/App.css'
import 'animate.css'
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import frFR from 'antd/es/locale/fr_FR';
import esES from 'antd/es/locale/es_ES';
import plPL from 'antd/es/locale/pl_PL';
import ptBR from 'antd/es/locale/pt_BR';
import arAR from 'antd/es/locale/ar_EG';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {APIgetPostNum} from './api'
import Cookies from 'js-cookie'
import Index from './components/Layout';
import LanguageModal from './components/LanguageModal'
import SimpleRoutes from './SimpleRoutes/index.js';
import Item from 'antd/lib/list/Item';
import { getValue, setCurrentLanguage } from './locales/util'
moment.locale('en');

//公共模块
const DefaultLayout = AsyncLoadable(() => import('./containers'))
const SimpleLayout = AsyncLoadable(() => import('./simpleContainers'))

//基础页面
const Login = AsyncLoadable(() => import('./views/Login'))
const RegisterPre = AsyncLoadable(() => import('./views/RegisterPre'))
const Invitecode = AsyncLoadable(() => import('./views/Invitecode'))
const Home = AsyncLoadable(() => import('./views/home'))
const Register = AsyncLoadable(() => import('./views/Register'))
const Email = AsyncLoadable(() => import('./views/Email'))
const RegisterOther = AsyncLoadable(() => import('./views/RegisterOther'))
const Landing = AsyncLoadable(() => import('./views/Landing'))
const View404 = AsyncLoadable(() => import('./views/Others/404'))
const ModifyPassword = AsyncLoadable(() => import('./views/ModifyPassword'))
const Vip = AsyncLoadable(() => import('./views/Vip'))
const MyProfileMobile = AsyncLoadable(() => import('./views/MyProfileMobile'))
const MyProfileInfoMobile = AsyncLoadable(() => import('./views/MyProfileInfoMobile'))
const ChangeLanguage = AsyncLoadable(() => import('./views/ChangeLanguage'))
const ChangeGender = AsyncLoadable(() => import('./views/ChangeGender'))
const ChangePassword = AsyncLoadable(() => import('./views/ChangePassword'))
const ChangeName = AsyncLoadable(() => import('./views/ChangeName'))
const TermConditionPdf = AsyncLoadable(() => import('./views/TermConditionPdf'))
const ChangeSocial = AsyncLoadable(() => import('./views/ChangeSocial'))
const MyAddressMobile = AsyncLoadable(() => import('./views/MyAddressMobile'))
const AddAddressMobile = AsyncLoadable(() => import('./views/AddAddressMobile'))
const BankAccountMobile = AsyncLoadable(() => import('./views/BankAccountMobile'))
const AddAccountMobile = AsyncLoadable(() => import('./views/AddAccountMobile'))
const MessageContactMobile = AsyncLoadable(() => import('./views/MessageContactMobile'))
const MessageListMobile = AsyncLoadable(() => import('./views/MessageListMobile'))
const MessageDetailMobile = AsyncLoadable(() => import('./views/MessageDetailMobile'))
const EarnWithdrawMobile = AsyncLoadable(() => import('./views/EarnWithdrawMobile'))
const TotalEarn = AsyncLoadable(() => import('./views/TotalEarn'))
const EditHeaderMobile = AsyncLoadable(() => import('./views/EditHeaderMobile'))
const RegisterCode = AsyncLoadable(() => import('./views/RegisterCode'))
const CampaignDetailMobile = AsyncLoadable(() => import('./views/CampaignDetailMobile'))
const LandingMobile = AsyncLoadable(() => import('./views/LandingMobile'))
const PrivacyPolicy = AsyncLoadable(() => import('./views/PrivacyPolicy'))
const SamplesMobile = AsyncLoadable(() => import('./views/SamplesMobile'))
const SampleDetailMobile = AsyncLoadable(() => import('./views/SampleDetailMobile'))

const chooseLocale = (locale) => {
   console.log('localllll',locale) 
   switch(locale) {
     case 'en_US':
       return enUS;
     case 'zh_CN':
       return zhCN;
     case 'fr_FR':
       return frFR;
     case 'es_ES':
       return esES;  
     case 'pl_PL':
       return plPL;  
     case 'pt_BR':
       return ptBR;  
     case 'ar_AR':
       return arAR;  
     default:
       return enUS;    
   }
     
 
}



const App = (props) => {
  const {stores} = props;
  let locale = stores.appReducers.language;
  // console.log('getAntdLocal(locale)',getAntdLocal(locale))
  console.log('当前的语言是', locale)
  const [state, setState] = useState({
    visible: true
  })


  const getPostNum = () => {
    if(!Cookies.get('dsb_user_info')){
      return false
    }
    APIgetPostNum().then(resp => {
      // setPostNum1(resp.data.quantity)
      props.changeMessageUnread(resp.data.quantity)
    })
  }

  const rightLnValue = (value) => {
     switch (value) {
       case 'en':
         return 'en_US'
       case 'es':
         return 'es_ES'
       case 'fr':
         return 'fr_FR'
       case 'pl':
         return 'pl_PL'
       case 'pt-BR':
         return 'pt_BR'
       case 'ar':
         return 'ar_AR'          
       default:
         return null
     }

  }

  const initLanguage = () => {
    console.log('xixixix',props)
    localStorage.setItem("babelLang", "en_US")
    let pathName = props.stores.router.location.pathname
    let babelLang = localStorage.getItem('babelLang')
    console.log('pathnnn',pathName)
    let ln = pathName.split('/')[1]
    console.log('ln',ln)
    let lnV = rightLnValue(ln)
    console.log('lnVxxxxxxx',lnV)
    if(lnV){
      if(lnV == 'ar_AR') {
        $('body').addClass('ar-AR')
        $('html').addClass('ar-Ar')
      }else{
        $('body').removeClass('ar-AR')
        $('html').removeClass('ar-Ar')
      }
      props.changeLanguage(lnV)
      setCurrentLanguage(lnV)
    }
    if(babelLang == 'ar_AR') {
      $('body').addClass('ar-AR')
      $('html').addClass('ar-Ar')
    }else {
      $('body').removeClass('ar-AR')
      $('html').removeClass('ar-Ar')
    }
  }


  useEffect(() => {
    // getPostNum()
    initLanguage()
 
  },[])
  
  return (
     <ConfigProvider locale={chooseLocale(locale)}>
        <Layout language={locale}>
          <LanguageModal/>
            <Router>
             <Switch>
               {/* <Route path='/' exact  component={Home} /> */}
               {/* <Route path='/index' exact render={() => <Redirect to='/' />} />{' '} */}
               {/* <Route path='/login' component={Login} />{' '} */}
               {
                 SimpleRoutes.map((item,index)=> {
                   return(
                     <Route path={item.path} component={item.component} key={item.path} />
                   )
                 })
               }
               {/* <Route path='/register_pre' component={RegisterPre} />
               <Route path='/invitecode' component={Invitecode} />
               <Route path='/register' component={Register} />
               <Route path='/registerOther' component={RegisterOther} />
               <Route path='/register_code' component={RegisterCode} />
               <Route path='/email' component={Email} />
               <Route path='/landing' exact render={() => <Redirect to='/' />} />
               <Route path='/modify-password' component={ModifyPassword} />
               <Route path="/vip" component={Vip}  />
               <Route path='/404' component={View404} />
               <Route path='/my_profile_m' component={MyProfileMobile} />
               <Route path='/my_profile_info_m' component={MyProfileInfoMobile} />
               <Route path='/change_language_m' component={ChangeLanguage} />
               <Route path='/change_gender_m' component={ChangeGender}/>
               <Route path='/change_password_m' component={ChangePassword}   />
               <Route path='/change_name_m' component={ChangeName} />
               <Route path='/term_condition_pdf' component={TermConditionPdf} />
               <Route path='/change_social_m/:id' component={ChangeSocial} />
               <Route path='/my_address_m' component={MyAddressMobile} />
               <Route path='/add_address_m' component={AddAddressMobile} />
               <Route path='/bank_account_m' component={BankAccountMobile} />
               <Route path='/add_account_m' component={AddAccountMobile} />
               <Route path='/message_contact_m' component={MessageContactMobile} />
               <Route path='/message_list_m' component={MessageListMobile} />
               <Route path='/message_detail_m/:id' component={MessageDetailMobile} />
               <Route path='/earn_withdraw_m' component={EarnWithdrawMobile} />
               <Route path='/total_earn_m' component={TotalEarn} />
               <Route path='/edit_header_m' component={EditHeaderMobile} />
               <Route path='/campaign_detail_mobile/:id' component={CampaignDetailMobile} />
               <Route path='/landing_mobile' component={LandingMobile} />
               <Route path='/privacy_policy' component={PrivacyPolicy} />
               <Route path='/samples_m' component={SamplesMobile} />
               <Route path='/sample_detail_m/:id' component={SampleDetailMobile} /> */}
               
               <Route component={DefaultLayout} /> 
               {/* <Route component={SimpleLayout}/> */}
             
             </Switch> 
            </Router>
        </Layout> 
     </ConfigProvider>

  )
}

const mapStateToProps = (state) => ({
   stores: state,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLanguage,
      changeMessageUnread,
      AppActions
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
