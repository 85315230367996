const common =  {
    login: ' تسجيل الدخول/التسجيل لأول مرة',
    loginS: "تسجيل الدخول",
    signUp: "التسجيل",
    contactUs: 'اتصل بنا',
    allCampaigns: 'جميع الحملات',
    myTasks: 'مهامي',
    messages: 'الرسائل',
    search: 'بحث',
    allRegion: 'جميع المناطق',
    france: 'فرنسا',
    spain: 'إسبانيا',
    rejectReason:'سبب الرفض',

    next:"التالي",
    forgetPwd:"نسيت كلمة المرور",
    pleasePwd:"يرجى إدخال كلمة المرور الحالية",
    pleaseEmail:"يرجى إدخال عنوان بريدك الالكتروني",
    invalidLink:"رابط غير صالح",

    referNow: "قم بتحويله الآن واحصل على مكافأة",
    enjoyColl:"هل تستمتع بالتعاون مع علاماتك التجارية المفضلة؟ أخبر صديقاً!",
    earn:"اكسب",
    commissionOf:"احصل على عمولة على الأرباح التي يحققها أصدقاؤك",
    referralCode:"رمز الإحالة",
    inviteFiendsAndEarn:"قم بدعوة أصدقائك واكسب",
    howToParti:"كيف أشارك؟",
    launchanyStarr:" شَغِّل تطبيق anyStarr وانقر على",
    referYouFri:"قم بتحويل أصدقائك",
    generateYour:"توليد",
    andClickOn:"وانقر على",
    inviteMoreFri:"قم بدعوة المزيد من الأصدقاء",
    share:"شارك",
    yourUniqueRefe:"رمز الإحالة الخاص بك مع أصدقائك واكسب",
    earningRules:"قواعد الكَسب",
    aSuccessfulReferral:"الإحالة الناجحة تعني أن صديقك المُحال سجَّل من خلال رمز الإحالة الخاص بك.",
    youWillOnlyRe:"ستحصل على عمولة من أصدقائك الذين يحققون أرباحاً فقط، ولمدة 90 يوماً من تاريخ تسجيلهم وبما لا يتجاوز XX $",
    myInvite:"دعوتي",
    inviteDes1:"بانتظار بدء الحملة",
    inviteDes2:"الحملة المشارك بها",
    inviteMoreFri:"قم بدعوة المزيد من الأصدقاء",
    FAQ:"الأسئلة الأشيع",
    question1:"لماذا لم أحصل على مكافآتي بعد تسجيل أصدقائي الذين أحلتهم في تطبيق anyStarr؟",
    questionDes1:"قد يكون أصدقاؤك نسوا تضمين رمز الإحالة عند تسجيلهم أو أنهم لم يكسبوا بعد.",
    question2:"كيف أعلم إن قام صديقي الذي أحلته بالتسجيل بنجاح؟",
    questionDes2:"حالما يقوم أصدقاؤك بالتسجيل باستخدام رمز إحالتك، فإن هوية المستخدم ستظهر في [دعواتي] ضمن [الحساب – قم بدعوة أصدقائك واكسب]",
    question3:"أين أجد رمز إحالتي",
    questionDes3:"يمكن إيجاد رمز إحالتك الحصري ضمن [الحساب – قم بدعوة أصدقائك واكسب] ",
    termsAndCon:"الشروط والأحكام",
    tc1:"الإحالة إلى الأصدقاء صالحة فقط للمستخدمين الجدد الذين لم يقوموا بالتسجيل.",
    tc2:"المستخدمين الذين سجلوا سابقاً دون رمز إحالة، لا يمكنهم إعادة التَقدُّم باستخدام الرمز. والذين أحالوا إليهم لن يكون مؤهلين لاستلام المكافأة. ",
    tc3:" يحتفظ تطبيق anyStarr بحق تعديل الشروط والأحكام في أي وقت دون إشعارٍ مسبق.",
    tc4:"بمشاركتك في برنامج الإحالة إلى صديق فأنت توافق على الشروط والأحكام",
    inviteFriendEarn:"قم بدعوة أصدقائك واكسب",
    mySamples:"عيِّناتي",
    invitationCode:"رمز الدعوة (اختياري)",


    postRequirement:"متطلبات المنشور",
    postExample:"مثال على المنشور",
    downloadImagesShare:"حمِّل الصور وشاركها",
    caption:"التعليق",
    copy:"نسخ",
    by:"بواسطة",
    submitContent:"أرسل وصف المحتوى الخاص بك",
    submitLink:"أرسل رابطاً لمنشورك على مواقع التواصل الاجتماعي",
    mySampleApplication: "نموذج طلبي",
    sample:"عيِّنة",
    status:"الحالة",
    applicationSubmitted:"تم إرسال الطلب",
    shippedOn:"تم شحنها",
    clickToTrack:"انقر للتعقب",
    congrats:"Congrats! عيّنتك جاهزة للاستبدال، سيتم تطبيق الحسم آلياً عند خروجك. ",
    pendingShipment:"بانتظار الشحن",
    applicationUnsuccess:"طلب غير ناجح",
    rewards:"المكافآت",
    rewardsDes:"ستتلقى عمولة عن كل مستخدمٍ يستخدم رمزك وفقاً للتالي:",
    numberOfCode:"عدد مرات استخدام الرمز",
    rewardPerUsage:"المكافأة مقابل الاستخدام الواحد",
    uploaded:"تم التحميل!",
    loginFacebookShare:"سجِّل الدخول في فيسبوك لتشارك",
    uploadScreenshots:"حمِّل لقطة/لقطات شاشة ورابط/روابط لمنشورك",
    plsApplySampleFirst:"يرجى تقديم عيِّنة أولاً",
    plsPatientSampleApproval:"يرجى الانتظار حتى تتم الموافقة على العيِّنة",
    taskClosed:"أغلقت المهمة",
    newTask:"مهمة جديدة",
    nextStep:"الخطوة التالية",
    submitContent:"إرسال المحتوى",
    pendingReview:"بانتظار المراجعة",
    resubmitContent:"إعادة إرسال المحتوى",
    stepCompleted:"اكتملت الخطوة",

    countryCode:"البلد",
    firstName:"الاسم الاول",
    lastName:"الكنية",
    bankName:"اسم البنك",
    streetAddress:"عنوان الشارع",
    city:"مدينة",
    state:"حالة",
    postCode:"الرمز البريدي",
    sampleRedeemed:"عينة مستردة",
    redeemNow:"استرد الآن",
    deliveryStatus:"حالة التوصيل",
    inTransit:"في مرحلة انتقالية",
    notShipped:"لم تشحن",
    receivedGoods:"البضائع المستلمة",
    trackingNum:"أرقام التتبع",
    shippedOn:"تم شحنها بتاريخ",
    worldwide:"في جميع أنحاء العالم",
    sampleStatus:"حالة العينة",
    applied:"مطبق",
    sampleApplication:"تطبيق العينة",
    yourInstagramAccount:"حساب الانستغرام الخاص بك",
    screenshotUpload:"تحميل لقطة الشاشة",
    sampleSelected:"عينة مختارة",
    agreeTerms:"أوافق على جميع الشروط أعلاه",
    myActivity:"نشاطاتي",
    promotions:"الترقيات",

     

}

export default common

