const invitecode = {
    invitecode: 'Invitation Code',
    pleaseInvitecode: 'Invalid invitation code',
    joinNow: 'Next',
    noCode: 'No invitation code？',
    registerHere: 'Sign up',


}

export default invitecode