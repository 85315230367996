const invitecode = {
    invitecode: 'Kod zaproszenia',
    pleaseInvitecode: 'Nieprawidłowy kod zaproszenia',
    joinNow: 'Kolejny',
    noCode: 'Brak kodu zaproszenia?',
    registerHere: 'Zapisz się',


}

export default invitecode