const earn = {
    codesClaimed:"Code Valide",
    onGoingTasks:"Mission en cours",
    totalEarning:"Total des gains",
    earnDes:"Les revenus sont divulgués au milieu de chaque mois et fournis uniquement à la discrétion de la marque de cette campagne.",
    duration:"Duration",
    promoCode:"Promo code",
    earnUpToDate:"Gagnez à jour",
    updatedOn:"Mise à jour",
    AccountBalance:"Solde de compte",
    withdraw:"Retirer",
    lasestWithdrawRequest:"Nouvelle demande de retrait",
    toBankAccount:"Compte bénéficiaire",
    withdrawAmount:"Montant de retrait",
    yourRequestProcessed:"Your request is being processed",
    inProgress:"In progress",
    failed:"Failed",
    completed:"Completed",
    withdrawHistory:"Historique de retrait",
    cancel:"Annuler",
    apply:"Enregistrer",
    minimum:"minimum",
    pleaseContact:"Contactez-nous si vous avez besoin d'information supplémentaire.",

}
export default earn