const task = {
    searchInput: 'Pesquisar em todas as tarefas',
    taskState0: 'Tarefa perto',
    // taskState1: 'Ativação pendente',
    taskState1: 'Nova Tarefa',
    taskState2: 'Envio de prova pendente',
    taskState3: 'Revisao pendente',
    taskState4: 'Nova prova pendente',
    taskState5: 'Prova verificada',
    all: "Todas minhas tarefas",
    status:"status das tarefas",

    taskState7: 'Conteúdo pendente para revisão',
    taskState8: 'Conteúdo Reenviado',


    applicationDate: 'Data de aplicação',

    activated:"Ativado",
    submitted:"Submetido",
    verified:"Verificado",

    taskPendContent: 'Envio do conteúdo pendente',

    status:"Status",
    trackingNumber:"Número de rastreamento",
    applicationUnsuccessful:"Aplicação sem sucesso",
    applicationSubmited:"Pedido apresentado",
    sampleRedeem:"Amostra está pronta para resgate",
    applicationTime:"Tempo de aplicação",
    sampleHowToRedeem:"Como resgatar?",
    samplePending:"A aplicação de sua amostra está sendo atualmente revisada pela marca, o que pode levar até 48 horas,",
    samplePendingDes:"Enquanto espera sua amostra ser aprovada, você pode começar a compartilhar seu código de desconto com seus seguidores e começar a ganhar comissões.",
    sampleDelivery:"Sobre entrega de amostra,",
    deliveryDes:"Por favor espere de 7 a 14 dias úteis para a entrega de amostras. Complete sua tarefa depois de receber sua amostra. Isso irá nos ajudar a te indentificar para nossa campanha exclusiva na próxima vez.,",
    redeemNow:"Redimir Agora",

}

export default task