const bank = {
    AddAccount:"Dodaj konto",
    bankPaypal:"Konto bankowe/PayPal",
    accountType:"Typ konta",
    accountNo:"Twoje konto",
    fullNameBank:"Imiê i nazwisko na koncie bankowym",
    bankName:"Nazwa banku",
    swiftCode:"Kod swift",
    bank:"Bank",
    bankAccount:"Konto bankowe",
    paypalAccount:"Konto PayPal",
    acountNo:"Twoje konto",
    otherBank:"Inny bank"

}
export default bank