const campaigns = {
    advanceSearch: 'Szukaj',
    duration: 'Czas trwania',
    regionCoverage: 'Region',
    AverageEarn: 'Średnie zarobki',
    like: 'Lubię to',
    joinNow: 'Aplikuj teraz!',
    joined:"Przejdź do zadania",
    comingSoon: 'Wkrótce ',
    expired: 'Zakończone',
    rewards:'Nagrody',
    worldWide:'Worldwide',

    


}

export default campaigns