const account = {
   myAccount: 'My Account',
   myProfile: "My Profile",
   shippingAddress: "Shipping address",
   bankAccount: "Bank account",
   myEarnings: "My Earnings",
   earningHistory: "Earning history",
   withdraw: "Withdraw",



   logout: "Log out",

   personalInfo: "Personal infomation",
   email: "Email",
   invitationCode: "Invitation code",
   name:"Name",
   gender: "Gender",
   language:"Language",
   password:"Password",
   confirmPassword:"Confirm password",
   changePassword:"Change Password",
   male:"Male",
   female:"Female",
   other:"Prefer not to disclose",

   socialProfiles: "Social profile",
   follower:"Follower's top location",
   fbName:"Facebook username",
   insName:"Instgram Handle",
   tiktokName:"Tiktok Handle",

   save:"Save",


   nil:"Nil",
   edit:"Edit",
   

}

export default account