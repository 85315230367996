const campaignsDetail = {
    campaignId: 'ID de campagne',
    campaignType: 'Type de campagne',
    campaignDuration: 'Durée',
    distributionCountry: 'Région concernée',
    freeSample: 'Echantillon gratuit',
    averageEarn: 'Revenu moyen',
    like: "J'aime",
    applyNow: 'Rejoindre',
    task: 'Mission',
    step: 'Etape',
    step1: ' Faîtes la demande et recevez un code promo exclusif.',
    step2: ' Partagez cela sur vos réseaux sociau.',
    step3: " Envoyez les captures d’écrans ou liens comme élément de preuve à anyStarr.",
    shortStepDes:'Enregistrez une vidéo de campagne répondant aux exigences',

    shortStep1:'Enregistrer du contenu qui répond aux exigences',
    shortStep2:'Envoyez du contenu pour examen par la marque',

    shortStepDes1:'Envoyez le contenu de votre avis',
    shortStepDes2:'Partager sur les médias sociaux',
    shortStepDes3:"Envoyer le capture d'écran et le lien de votre post",
    sampleSelect:"Si votre style correspond à celui d'abComo et que vous gagnez un échantillon, vous pouvez échanger votre échantillon par le biais de www.abComo.com",

    followRule1:'1.Suivez le compte @abcomoofficial sur instagram',
    followRule2:'2.Engagé à poster une vidéo de déballage+ poster avec vous-même en le portant',
    followRule3:'3.Partage de votre code unique avec vos abonnés dans la poste',
    sampleApplication: 'Application pour échantillon',
    terms: 'Les conditions',
    mySampleApplication: 'Mon application pour échantillon',
    submitApplication: 'Présenter une demande',
    yourIns: "Your instagram link",

    abcomoStep1:"Postuler et recevoir l'échantillon",
    abcomoStep2:'Partagez cela sur vos réseaux sociau.',
    abcomoStep3:'Envoyez les captures d’écrans ou liens comme élément de preuve à anyStarr.',

}

export default campaignsDetail

