const messageUpdates = {
    anystarrUpdates: 'Atualizações',
    contactAnystarr: 'Contato anyStarr',
    viewDetail: "Visualizar detalhes",

    contactTitle:"Possui uma pergunta? Nós estaremos felizes em lhe ajudar o mais rápido que pudermos.",
    contactTitle1:" Por favor preencha o formulário abaixo e anexa um print do problema.",
    whatAbout: "Sobre o que é a pergunta?",
    yourCountry:"Seu país",
    yourEmail:"Seu email",
    userName:"Seu nome",
    details:"Detalhes",
    support:"Formatos suportados .JPG .JPEG .PNG .PDF (Max.3MB)",




    campaignsRelated:"Campanha relacionada",
    taskRelated:"Tarefa relacionada",
    withdrawRelated:"Retirada relacionada",
    technicalIssues:"Problemas tecnicos",
    others:"outros",

    save:"Enviar"

}

export default messageUpdates