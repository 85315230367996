const login = {
    emailPlacehold: 'Email',
    passwordPlacehold: 'Senha',
    login: 'Entrar',
    forgetPassword: 'Esqueci a senha',
    notMember: "Não tem uma conta?",
    clickToRegister: 'Se inscreva',
    or: 'or',
    socialAccount: 'Entre com contas sociais',
    pleaseAccount: 'Email faltando',
    pleasePassword: 'Senha faltando',

    resetPassword:"Redefinir sua senha",
    resetPasswordDes:"Por favor entre e confirme sua nova senha abaixo para acessar sua conta",
    invitecodeTitle:"Para continuar, por favor coloque o código de convite que você recebeu do seu representante.",
    emailCodeTitle:"Coloque o código de verificação do seu email registrado.",
    emailCodeDes:"Deixe-nos saber se esse endereço de email pertence a você.",
    complete:"Completo",
    sendAgain:"Envie de novo",

    haveAccount:'Possuo uma conta',
    activationDes:"Quase pronto ",
    activationDes1:"Redefina sua senha para ativar sua conta",
    linkExpired:"Esse link de ativação expirou",
    requestNewActivation:"Solicite um novo e-mail de ativação ou crie uma nova conta",
    confirm:"confirm",
    googleRegister:"Registe-se no Google",
    hadRegister:"Você já deve ter criado uma conta. Continue o login.",

}
export default login