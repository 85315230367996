import TaskDetail from "../../views/TaskDetail/TaskDetail"

const taskDetail = {
    statusFur: 'Explicação adicional ao status, se necessário',
    step1: 'Passo 1',
    stepDes1: 'Aplicar e receber seu código de desconto exclusivo',
    applyPromoCode: 'Aplicar código promocional',
    howToUse: 'Como usar código promocional eficientemente',
    copyCode: 'Copiar',
    step2: 'Passo 2',
    stepDes2: 'Compartilhar nas mídias sociais',
    step3: 'Passo 3',
    stepDes3: 'Enviar print como evidência',
    newImage: 'Nova imagem',
    inputWebsitelink: 'Enviar links de compartilhamento como evidência',
    addUri: 'Adicionar mais links',
    submit: 'Enviar',



    endDate: 'Data de encerramento',
    discountCode: 'Código de desconto',

    clicktoApply: 'Código promocional',
    noCode: 'Novo código',

    step1: "Aplique e receba seu código de desconto exclusivo",
    step2: "Compartilhe na Mídia Social",
    step3: "Envie prints e links dos seus posts para essa campanha",

    newImages: "Novos arquivos",

    upPic:"Até 5 imagens, formatos suportados: JPG, JPEG, PNG (Max. 3MB)",

    upLink: "Até 3 links",

    addMoreLink: "Adicionar mais links",

    submit: "Enviar",


    submitScreenshot:"Enviar um print do seu post na mídia social",
    submitLinkL:"Enviar o link do post na sua mídia social",
    

    sampleStepDes:"Aplique e receba a amostra",
    applySample:"Clique para aplicar",

}

export default taskDetail