const invitecode = {
    invitecode: 'Código de Invitación',
    pleaseInvitecode: 'Falta el Código de Invitación',
    joinNow: 'Siguiente',
    noCode: 'Regístrese sin un código de invitación',
    registerHere: 'Regístrese',


}

export default invitecode