const campaignsDetail = {
    campaignId: 'Identyfikator kampanii',
    campaignType: 'Rodzaj kampanii',
    campaignDuration: 'Czas trwania',
    freeSample: 'Bezpłatna próbka',
    yes:'Tak',
    no:'Nie',
    averageEarn: 'Średnie zarobki',
    like: 'Lubię to',
    applyNow: 'Aplikuj teraz!',
    task: 'Zadanie',
    step: 'Krok',
    step1: 'Aplikuj i otrzymaj ekskluzywny kod promocyjny.',
    step2: 'Udostępnij kampanię w mediach społecznościowych.',
    step3: 'Prześlij zrzuty ekranu lub linki do anyStarr jako dowód.',

    shortStep1:'Twórz treści, które spełniają warunki kampanii',
    shortStep2:'Prześlij marce szkic treści do oceny',

    shortStepDes:'shortStepDes',
    shortStepDes1:'Prześlij wersję roboczą treści do przeglądu',
    shortStepDes2:'Udostępnij w mediach społecznościowych',
    shortStepDes3:'Prześlij zrzut ekranu swojego postu(postów) i link(linki)',
    sampleSelect:"Jeśli Twój styl pasuje do abComo i wygrałeś próbkę, możesz ją zrealizować poprzez www.abComo.com",
    followRule1:'1.Follow @abcomoofficial instagram account',
    followRule2:'2.Commited to post unboxing video + post with yourself wearing it',
    followRule3:'3.Sharing of your unique code to your followers in the post',
    sampleApplication: 'Sample application',
    terms: 'Terms',
    mySampleApplication: 'My sample application',
    submitApplication: 'Submit application',
    yourIns: "Your instagram link",

    abcomoStep1:'Złóż wniosek i odbierz próbkę',
    abcomoStep2:'Udostępnij kampanię w mediach społecznościowych.',
    abcomoStep3:'Prześlij zrzuty ekranu lub linki do anyStarr jako dowód.',

}

export default campaignsDetail