const email = {
    emailPlacehold: 'Votre adresse e-mail:',
    codePlacehold: 'Code de vérification à 6 chiffres',
    emailPlease: "L'adresse mail est manquante",
    codePlease: "Code d'invitation est manquante",
    register: "S'inscrire",
    emailRegx: 'L’adresse e-mail n’est pas valide!',
    getCode: 'Envoyer',
    login: 'Login',
    emailTs1:"Pour continuer, veuillez terminer la vérification de l'e-mail",
    emailTs2:"Code envoyé! Veuillez vérifier votre boîte de réception e-mail et entrez le code de vérification dans la case ci-dessus."
}

export default email

