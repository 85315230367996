const register = {
    yourInvitecode: 'Código de invitación',
    yourEmail: 'Correo electrónico',
    passwordPlacehold: 'Contraseña',
    passwordPlease: 'Falta la contraseña',
    passwordRegx: 'Su contraseña debe tener 8-16 caracteres e incluir por lo menos una letra mayúscula (A-Z) o una letra minúscula (a-z)',
    passwordConfirmPlacehold: 'Confirmar contraseña',
    passwordConfirmPlease: 'falta la contraseña',
    passwordConfirmRegx: 'Las contraseñas no coinciden',
    // countryUpText: 'De dónde vienen sus seguidores? (hasta 5)',
    countryPlease: 'País/Región',
    register: 'Registrarse',
    agreeTo: 'Aceptar nuestros Términos y Condiciones al registrarse',
    termsAndConditions: 'Términos y Condiciones',
    follower: 'De dónde vienen sus seguidores? (hasta 5)',
    submit:'Regístrese',
    howHearAbout:'¿Como nos ha conocido?',

}
export default register