const register = {
    yourInvitecode: 'Código de convite',
    yourEmail: 'Email',
    passwordPlacehold: 'Senha',
    passwordPlease: 'Senha faltando',
    passwordRegx: 'Sua senha deve ter entre 8-16 caracteres incluindo ao menos uma letra maiúscula (A-z) ou uma minúcula (a-z)',
    passwordConfirmPlacehold: 'Confirme a senha',
    passwordConfirmPlease: 'Confirme senha faltando',
    passwordConfirmRegx: 'Senhas não coincidem.',
    // countryUpText: 'segue a área de distribuição? (até 5)',
    countryPlease: 'País/Região',
    register: 'Registrar',
    agreeTo: 'Ao se registrar você concorda com nossos',
    termsAndConditions: 'Termos e Condições',
    follower: "Principal localização dos seguidores (até 5)",
    submit:'Se increver'

}
export default register