const bank = {
    AddAccount:"Ajouter pagos",
    bankPaypal:"Cuenta de banco/PayPal",
    accountType:"Tipo de cuenta",
    accountNo:"La cuenta",
    fullNameBank:"Titular y cotitular?",
    bankName:"Banco",
    swiftCode:"SWIFT BIC",
    bank:"Banco",
    bankAccount:"Cuenta de banco",
    paypalAccount:"Cuenta PayPal"

}
export default bank