const email = {
    emailPlacehold: 'Email',
    codePlacehold: '6-digit verification code',
    emailPlease: 'Email is missing',
    codePlease: 'Verification code is missing',
    register: 'Register',
    emailRegx: 'Email is not validate email!',
    getCode: 'Request',
    login: 'Login',
    emailTs1:"To continue, please complete Email verification",
    emailTs2:"Code sent! Please check your email and use the box above to submit the verification code"
}

export default email