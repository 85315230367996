const task = {
    searchInput: 'Buscar',
    applicationDate: 'Fecha de Solicitud',
    taskState0: 'Esperando código promocional',
    taskState1: 'Nueva tarea',
    taskState2: 'Subida de evidencia',
    taskState3: 'Pendiente de verificación',
    taskState4: 'Subida de evidencia nueva',
    taskState5: 'Evidencia verificada',
    all: "Todas mis tareas",
    status:"estado de la tarea",

    activated:"Activado",
    submitted:"Enviado",
    verified:"Verificado",

    taskPendContent: 'Carga de contenido pendiente',


    status:"Estado",
    trackingNumber:"Número de consulta",
    applicationUnsuccessful:"Solicitud no aceptada",
    applicationSubmited:"Solicitud presentada",
    sampleRedeem:"La muestra está lista para ser canjeada",
    applicationTime:"Tiempo de aplicación",
    sampleHowToRedeem:"¿Cómo se canjea?",
    samplePending:"Su solicitud de muestra está siendo revisada por la marca, lo que puede tardar hasta 48 horas",
    samplePendingDes:"Mientras esperas a que tu muestra sea aprobada, puedes empezar a compartir tu código de descuento de afiliado con tus seguidores para empezar a ganar comisiones.",
    sampleDelivery:"Sobre la entrega de muestras",
    deliveryDes:"Por favor, espere de 7 a 14 días laborables para la entrega de la muestra. Complete su tarea después de recibir su muestra. Esto nos ayudará a identificarle e invitarle a nuestra campaña exclusiva la próxima vez.",
    redeemNow:"Canjear Ahora",


}

export default task