const task = {
    searchInput: 'search in All tasks',
    taskState0: 'Task close',
    // taskState1: 'Pending activation',
    taskState1: 'New Task',

    taskState2: 'Pending proof upload',
    taskState3: 'Pending for review',
    taskState4: 'Pending new proof',
    taskState5: 'Proof verified',
    all: "All my tasks",
    status:"Task status",

    taskState7: 'Content Pending for Review',
    taskState8: 'Resubmit Content',

    taskPendContent: 'Pending content upload',


    applicationDate: 'Application date',

    activated:"Activated",
    submitted:"Submitted",
    verified:"Verified",

    status:"Status",
    trackingNumber:"tracking number",
    applicationUnsuccessful:"Application unsuccessful",
    applicationSubmited:"Application submitted",
    sampleRedeem:"Sample is ready to redeem",
    applicationTime:"Application time",
    sampleHowToRedeem:"How to redeem?",
    samplePending:"Your sample application is currently being reviewed by the brand, which can take up to 48 hours",
    samplePendingDes:"While waiting for your sample to be approved, you can start sharing your affiliate discount code with your followers to start earning commission.",
    sampleDelivery:"About sample delivery",
    deliveryDes:"Please expect 7 to 14 working days for sample delivery. Complete your task after you receive your sample. This will helps us to identify you and invite your to our exclusive campaign next time.",
    redeemNow:"Redeem Now",

}

export default task