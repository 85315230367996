const login = {
    emailPlacehold: 'Email',
    passwordPlacehold: 'password',
    login: 'Login',
    forgetPassword: 'Forget password',
    notMember: "Don't have an account?",
    clickToRegister: 'Sign up',
    or: 'or',
    socialAccount: 'Login with social accounts',
    pleaseAccount: 'Email is missing',
    pleasePassword: 'Password is missing',

    resetPassword:"Reset your passward",
    resetPasswordDes:"Please enter and confirm your new password bellow to access your account",
    invitecodeTitle:"To proceed, please input the invitation code that you received from our representative.",
    emailCodeTitle:"Input the verification code from your registered email.",
    emailCodeDes:"Let us know that this email address belongs to you.",
    complete:"Complete",
    sendAgain:"Send again",

    haveAccount:'Already have an account',

    activationDes:"Almost there",
    activationDes1:"Reset your password to activate your account",

    linkExpired:"That activation link has expired",
    requestNewActivation:"Request a new activation email or create a new account",
    confirm:"confirm",
    googleRegister:"Sign Up  with Google",
    sendAcEmail:"You might already registered. Continue to activate your account through activation email sent to ",
    hadRegister:"You might already have created an account. Please continue login",

}
export default login