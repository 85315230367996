const earn = {
    codesClaimed:"Uzyskane kody",
    onGoingTasks:"Bie¿¹ce zadania",
    totalEarning:"Ca³kowite zarobki",
    earnDes:"Zyski s¹ przedstawiane w po³owie ka¿dego miesi¹ca i przekazywane wy³¹cznie wed³ug uznania marki prowadz¹cej kampaniê.",
    duration:"Czas trwania",
    promoCode:"Kod promocyjny",
    earnUpToDate:"Dotychczasowe zarobki",
    updatedOn:"Ostatnia aktualizacja",
    AccountBalance:"Saldo na koncie",
    withdraw:"Wyp³aæ",
    lasestWithdrawRequest:"Ostatnie polecenie wyp³aty",
    toBankAccount:"Na konto",
    withdrawAmount:"Kwota wyp³aty",
    yourRequestProcessed:"Polecenie jest przetwarzane",
    inProgress:"W toku",
    failed:"Niepowodzenie",
    completed:"Ukoñczono",
    withdrawHistory:"Historia wyp³at",
    cancel:"Anuluj",
    apply:"Zastosuj",
    minimum:"minimum",
    pleaseContact:"Jeœli chcesz dowiedzieæ siê wiêcej, prosimy o kontakt",
    to:"na",

}
export default earn