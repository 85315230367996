import TaskDetail from "../../views/TaskDetail/TaskDetail"

const taskDetail = {
    statusFur: 'Status further explanation , if needed',
    // step1: "Etape1'",
    stepDes1: 'Faîtes la demande et recevez un code promo exclusif',
    applyPromoCode: 'Demander le code promo',
    howToUse: 'Comment utiliser son code promo efficacement?',
    copyCode: 'Copié',
    // step2: 'Etape 2',
    stepDes2: 'Partager sur les réseaux sociaux',
    // step3: 'Etape 3',
    stepDes3: "Envoyer les captures d'écrans comme élément de preuve",
    newImage: 'Nouveau image',
    inputWebsitelink: 'Envoyer les liens partagées comme élément de preuve',
    addUri: 'Ajouter plus de liens',
    submit: 'Envoyer',

    step1: "Faîtes la demande et recevez un code promo exclusif",
    step2: "Partager sur les réseaux sociaux",
    step3: "Envoyer les captures d'écrans comme élément de preuve",

    endDate: "Date de fin",

    clicktoApply: 'Code promo',
    noCode: 'Plus de code à venir',

    newImages: "Nouveau fichier",

    upPic:"Maximum 5 images, formats acceptés : JPG, JPEG, PNG (Max. 3MB)",

    upLink: "Maximum 3 liens",

    addMoreLink: "Ajouter plus de liens",


    submitScreenshot:"Submit a screenshot of your social media post",
    submitLinkL:"Submit a link of your social media post",

    sampleStepDes:"Apply and receive the sample",
    applySample:"Click to apply",


}

export default taskDetail

