const messageUpdates = {
    anystarrUpdates: 'anyStarr تحديثات',
    contactAnystarr: 'اتصل ب  anyStarr',
    viewDetail: "إظهار التفاصيل",

    contactTitle:" هل لديك أي سؤال؟ سنكون سعداء بمساعدتك في أقرب وقت ",
    contactTitle1:" يرجى ملء النموذج أدناه وأرفق لقطة شاشة للمشكلة",
    whatAbout: "عن ماذا يدور التساؤل؟",
    yourCountry:"دولتك",
    yourEmail:"بريدك الإلكتروني",
    userName:"اسمك",
    details:"التفاصيل",
    support:"الصيغ المدعومة .JPG .JPEG .PNG .PDF (Max.3MB)",




    campaignsRelated:"الحملة ذات الصلة",
    taskRelated:"المهمة ذات الصلة",
    withdrawRelated:"السحب ذو الصلة",
    technicalIssues:"مشاكل تقنية",
    others:"آخرون",

    save:"إرسال"

}

export default messageUpdates
