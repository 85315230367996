const task = {
    searchInput: 'ابحث في جميع المهام',
    taskState0: 'إغلاق المهمة',
    // taskState1: 'بانتظار التفعيل',
    taskState1: 'مهمة جديدة',

    taskState2: 'بانتظار تحميل الدليل',
    taskState3: 'بانتظار المراجعة',
    taskState4: 'بانتظار دليل جديد',
    taskState5: 'تم التحقق من الدليل',
    all: "جميع مهامي",
    status:"حالة المهمة ",

    taskState7: 'المحتوى بانتظار المراجعة',
    taskState8: 'إعادة إرسال المحتوى',

    taskPendContent: 'بانتظار تحميل المحتوى',


    applicationDate: 'تاريخ الطلب',

    activated:"مُفعَّل",
    submitted:"مُرسل",
    verified:"تم التحقق منه",

    status:"الحالة",
    trackingNumber:"تتبع العدد",
    applicationUnsuccessful:"طلب غير ناجح",
    applicationSubmited:"تم إرسال الطلب",
    sampleRedeem:"العيِّنة جاهزة للاستبدال",
    applicationTime:"توقيت الطلب",
    sampleHowToRedeem:"كيف يتم الاستبدال؟",
    samplePending:"تعالج الشركة صاحبة العلامة التجارية طلبك حالياً وقد يستغرق الأمر 48 ساعة.",
    samplePendingDes:"أثناء انتظار الموافقة على عيِّنتك يمكنك البدء بمشاركة رمز الحسم الخاص بك مع متابعيك لتبدأ بكسب العمولات.",
    sampleDelivery:"حول استلام العيّنة",
    deliveryDes:" يرجى الانتظار من 7 إلى 14 يوم عمل لوصول العيِّنة. أكمل مهمتك بعد استلامك للعيِّنة. هذا سيساعدنا في التعرّف عليك ودعوتك إلى حملتنا الحصرية في المرة القادمة",
    redeemNow:"استبدل الآن",

}

export default task