const table = {
    projectID: '项目ID',
    projectName:'项目名称',
    beginEndTime:'开始/结束时间',
    beginTime:'开始时间',
    endTime:'结束时间',
    applyTime:'报名时间',
    applyStage:'待报名分期',
    materialAudit:'待审核素材',
    creater:'创建人',
    dept:'部门',
    action:'操作'
  }
export default table;