const register = {
    yourInvitecode: 'رمز الدعوة',
    yourEmail: 'البريد الإلكتروني',
    passwordPlacehold: 'كلمة المرور',
    passwordPlease: 'كلمة المرور مفقودة',
    passwordRegx: 'يجب أن تكون كلمة المرور بطول 8-16 محرفاً وتتضمن حرفاً واحداً كبيراً أو حرفاً واحداً صغيراً على الأقل',
    passwordConfirmPlacehold: 'تأكيد كلمة المرور',
    passwordConfirmPlease: 'لم يتم تأكيد كلمة المرور',
    passwordConfirmRegx: 'كلمتا المرور غير متطابقتين',
    // countryUpText: 'هل تتابع مناطق التوزيع؟ (5 مناطق على الأكثر)',
    countryPlease: 'الدولة/المنطقة',
    register: 'سجِّل',
    agreeTo: 'بقيامك بالتسجيل فأنت توافق على',
    termsAndConditions: 'الشروط والأحكام',
    follower: "مواقع أكثر المتابعين (اذكر 5 مناطق كحد أقصى)",
    submit:'التسجيل للمرة الأولى',
    howHearAbout:'كيف تعرّفت علينا؟',

}
export default register
