const campaignsDetail = {
    campaignId: 'Campaign ID',
    campaignType: 'Campaign Type',
    campaignDuration: 'Duration',
    freeSample: 'Free Sample',
    yes:'Yes',
    no:'No',
    averageEarn: 'Average earnings',
    like: 'Like',
    applyNow: 'Apply now',
    task: 'Task',
    step: 'Step',
    step1: 'Apply and receive an exclusive promo code.',
    step2: 'Share the campaign on your social media.',
    step3: 'Submit proof screenshots or links to anyStarr.',

    shortStep1:'Create content that meets campaign requirements',
    shortStep2:'Upload the content draft for review from brand',

    shortStepDes:'shortStepDes',
    shortStepDes1:'Upload a draft content for review',
    shortStepDes2:'Share on social media',
    shortStepDes3:'Upload your post screenshot and link',
    sampleSelect:'If your style fits abComo and win a sample, you can redeem your sample through www.abComo.com',

    followRule1:'1.Follow @abcomoofficial instagram account',
    followRule2:'2.Commited to post unboxing video + post with yourself wearing it',
    followRule3:'3.Sharing of your unique code to your followers in the post',
    sampleApplication: 'Sample application',
    terms: 'Terms',
    mySampleApplication: 'My sample application',
    submitApplication: 'Submit application',
    yourIns: "Your instagram link",

    abcomoStep1:'Apply and receive the sample',
    abcomoStep2:'Share the campaign on your social media.',
    abcomoStep3:'Submit proof screenshots or links to anyStarr.',



}

export default campaignsDetail