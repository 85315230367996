const common =  {
    login: ' Login/Sign Up',
    loginS: "Login",
    signUp: "Sign Up",
    contactUs: 'Contact us',
    allCampaigns: 'All campaigns',
    myTasks: 'My tasks',
    messages: 'Messages',
    search: 'Search',
    allRegion: 'All regions',
    france: 'France',
    spain: 'Spain',
    rejectReason:'Reason for rejection',

    next:"next",
    forgetPwd:"Forget Password",
    pleasePwd:"Please enter your current password",
    pleaseEmail:"Please enter your Email",
    invalidLink:"Invalid link",

    referNow: "REFER NOW AND GET REWARDED",
    enjoyColl:"Enjoy collaborating with your favourite brands? Tell a friend!",
    earn:"Earn",
    commissionOf:"commission of any earnings your friends earn",
    referralCode:"Referral Code",
    inviteFiendsAndEarn:"Invite Friends and Earn",
    howToParti:"How to Participate",
    launchanyStarr:"Launch anyStarr app and click on",
    referYouFri:"Refer Your Friends",
    generateYour:"Generate your",
    andClickOn:"and click on",
    inviteMoreFri:"invite More Friends",
    share:"Share",
    yourUniqueRefe:"your unique referral code with your friends and start earning",
    earningRules:"Earning Rules",
    aSuccessfulReferral:"A successful referral means that your referred friend signs up using your referral code.",
    youWillOnlyRe:"You will only receive commission from your friends' earnings for 90 days from their registration date up to $XX in total.",
    myInvite:"My invite",
    inviteDes1:"Pending start campaign",
    inviteDes2:"Campaign participated",
    inviteMoreFri:"Invite More Friends",
    FAQ:"FAQ",
    question1:"Why have I not received my rewards after my friends I referred signed up to anyStarr?",
    questionDes1:"Your friend may have forgotten to include your referral code when signing up OR he/she has not started earning yet..",
    question2:"How do I know whether my referred friend has a successful signup?",
    questionDes2:"Once your friends signup on anyStarr with your referral code, their User ID willl show up [My invites] under [Account - Invite Friends and Earn]",
    question3:"Where can I find my referral code?",
    questionDes3:"You may find your exclusive referral code under [Account - Invite Friends and Earn] ",
    termsAndCon:"Terms and Conditions",
    tc1:"The Refer-Your-Friends is only eligible for the NEW users who have not signed up.",
    tc2:"The users who previously signed up without a referral code CANNOT reapply with the code. Their referrers will be ineligible to receive this reward.",
    tc3:"anyStarr reserves the right to amend the T&C at any time without prior notification.",
    tc4:"By participating in the Refer-a-friend Program, you agree to the T&C.",
    inviteFriendEarn:"Invite Friends and Earn",
    mySamples:"My Samples",
    invitationCode:"Invitation code (optional)",


    postRequirement:"Post requirement",
    postExample:"Post example",
    downloadImagesShare:"Download images and share",
    caption:"Caption",
    copy:"Copy",
    by:"By",
    submitContent:"Submit your content description",
    submitLink:"Submit a link of your social media post",
    mySampleApplication: "My sample application",
    sample:"Sample",
    status:"Status",
    applicationSubmitted:"Application submitted",
    shippedOn:"Shipped on",
    clickToTrack:"Click to track",
    congrats:"Congrats! Your sample is ready to redeem!Discount will automatically applied at checkout ",
    pendingShipment:"Pending shipment",
    applicationUnsuccess:"Application unsuccessful",
    rewards:"Rewards",
    rewardsDes:"For each new users who your code，you will be paid commission based on the following",
    numberOfCode:"Number Of Code usage",
    rewardPerUsage:"Reward per usage",
    uploaded:"Uploaded!",
    loginFacebookShare:"Login Facebook to share",
    uploadScreenshots:"Upload your post screenshot(s) and link(s)",
    plsApplySampleFirst:"Please apply a sample first",
    plsPatientSampleApproval:"Please be patient with the sample approval",
    taskClosed:"Task closed",
    newTask:"New task",
    nextStep:"Next step",
    submitContent:"Submit content",
    pendingReview:"Pending review",
    resubmitContent:"Resubmit Content",
    stepCompleted:"Step completed",

    countryCode:"Country",
    firstName:"First Name",
    lastName:"Last Name",
    bankName:"Bank name",
    streetAddress:"Street Address",
    city:"City",
    state:"State",
    postCode:"Post code",
    sampleRedeemed:"Sample redeemed",
    redeemNow:"Redeem now",
    deliveryStatus:"Delivery Status",
    inTransit:"In transit",
    notShipped:"Not shipped",
    receivedGoods:"Received goods",
    trackingNum:" tracking number",
    shippedOn:"Shipped on",
    worldwide:"Worldwide",
    sampleStatus:"sample status",
    applied:"applied",
    sampleApplication:"Sample application",
    yourInstagramAccount:"Your instagram account",
    screenshotUpload:"Screenshot Upload",
    sampleSelected:"Sample selected",
    agreeTerms:"I agree to all terms above",
    myActivity:"My Activities",
    promotions:"Promotions",



     

}

export default common
