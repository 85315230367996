const common =  {
    login: 'Iniciar sesión/Regístrese',
    loginS: "Iniciar sesión",
    signUp: "Regístrese",
    contactUs: 'Contáctenos',
    allCampaigns: 'Campañas',
    myTasks: 'Mis tareas',
    messages: 'Mensaje',
    search: 'Buscar',
    allRegion: 'Todas las regiones',
    france: 'Francia',
    spain: 'España',
    rejectReason:'Motivo del rechazo',

    next:"Siguiente",
    forgetPwd:"Olvidé mi contraseña",
    pleasePwd:"Ingrese la contraseña actual",
    pleaseEmail:"Introduzca la dirección de correo electrónico",
    invalidLink:"Un enlace inválido",

    referNow: "REFIÉRETE AHORA Y OBTÉN UNA RECOMPENSA",
    enjoyColl:"Disfrutar de colaborar con sus marcas favoritas? ¡Dile a un amigo!",
    earn:"Ganar",
    commissionOf:" de comisión de las ganancias ganar a tus amigos",
    referralCode:"código de referencia",
    inviteFiendsAndEarn:"Invitar a los amigos y ganar",
    howToParti:"Como participar",
    launchanyStarr:"AnyStarr lanzar la aplicación y haga clic en",
    referYouFri:"Recomienda a tus amigos",
    generateYour:"generar su",
    andClickOn:"y haga clic en",
    inviteMoreFri:"Invita mas amigos",
    share:"Cuota",
    yourUniqueRefe:"su código de referencia único, con sus amigos y comience a ganar",
    earningRules:"La obtención de Reglas",
    aSuccessfulReferral:"A exitosos medios de referencia que sus signos amigo invitado usando su código de referencia.",
    youWillOnlyRe:"Sólo recibirá comisión de las ganancias de sus amigos durante 90 días a partir de su fecha de registro de hasta $ 1000 en total.",
    myInvite:"Mi invita:",
    inviteDes1:"A la espera de inicio de campaña",
    inviteDes2:"campaña participó",
    inviteMoreFri:"Invita mas amigos",
    FAQ:"Preguntas más frecuentes",
    question1:"¿Por qué no he recibido mi recompensa después de mis amigos me he referido inscrito para anyStarr?",
    questionDes1:"Su amigo puede haber olvidado incluir su código de referencia al registrarse o él / ella no ha comenzado a ganar todavía.",
    question2:"¿Cómo puedo saber si mi amigo referido tiene un registro exitoso?",
    questionDes2:"Una vez que sus amigos de registro en anyStarr con su código de referencia, su ID de usuario willl aparece [Mis invita] en [Cuenta - Invitar amigos y Ganar]",
    question3:"¿Dónde puedo encontrar mi código de referencia?",
    questionDes3:"Usted puede encontrar su código de referencia exclusiva en virtud de [Cuenta - invitar a amigos y Gane]",
    termsAndCon:"Términos y condiciones",
    tc1:"El Refer-Sus-amigos es solamente elegibles para los nuevos usuarios que no han firmado.",
    tc2:"Los usuarios que previamente ha registrado sin utilizar un código de referencia CAN No vuelva a aplicar con el código. Sus referentes no serán elegibles para recibir este premio.",
    tc3:"anyStarr se reserva el derecho a modificar los T & C en cualquier momento sin notificación previa.",
    tc4:"Al participar en el programa de referir a un amigo, usted está de acuerdo a los T & C.", 
    inviteFriendEarn:"Invitar a amigos y ganar",
    mySamples:"Mis muestras",
    invitationCode:"Código de invitación (opcional)",

    postRequirement:"Requisito de publicación",
    postExample:"Ejemplo de publicación",
    downloadImagesShare:"Descargar imágenes y compartir",
    caption:"Subtítulo",
    copy:"Dupdo",
    by:"Por",
    submitContent:"Envíe su descripción de contenido",
    submitLink:"Envía un enlace de tu post de las redes sociales.",
    mySampleApplication:"Mi aplicación de muestra",
    sample:"Muestra",
    status:"Estado",
    applicationSubmitted:"Solicitud presentada",
    shippedOn:"Embarcado",
    clickToTrack:"Haga clic para seguir",
    congrats:"¡Felicitaciones! ¡Tu muestra está lista para canjear! El descuento se aplica automáticamente al finalizar la compra.",
    pendingShipment:"envio pendiente",
    applicationUnsuccess:"Aplicación sin éxito",
    rewards:"Recompensas",
    rewardsDes:"Para cada nuevo usuarios que su código, se le pagarán la comisión según lo siguiente",
    numberOfCode:"Número de uso de código",
    rewardPerUsage:"Recompensa por uso",
    uploaded:"¡Subido!",
    loginFacebookShare:"Iniciar sesión Facebook para compartir",
    uploadScreenshots:"Sube tus capturas posteriores y enlace (s)",
    plsApplySampleFirst:"Por favor, aplique una muestra primero",
    plsPatientSampleApproval:"Por favor, sea paciente con la aprobación de la muestra.",
    taskClosed:"Tarea cerrada",
    newTask:"Nueva tarea",
    nextStep:"Próximo paso",
    submitContent:"Enviar contenido",
    pendingReview:"Revisión pendiente",
    resubmitContent:"Reenviar contenido",
    stepCompleted:"Tarea terminada",

    countryCode:"País",
    firstName:"Nombre",
    lastName:"Apellido",
    bankName:"Nombre del Banco",
    streetAddress:"Dirección de la calle",
    city:"Sidi",
    state:"Declaración",
    postCode:"Código postal",
    sampleRedeemed:"Muestra redentora",
    redeemNow:"Cambio inmediato",
    deliveryStatus:"Estado de entrega",
    inTransit:"En el camino",
    notShipped:"No enviado",
    receivedGoods:"Recepción",
    trackingNum:" Número de consulta",
    shippedOn:"Envío",
    worldwide:"En todo el mundo",
    sampleStatus:"Estado de la muestra",
    applied:"aplicado",
    sampleApplication:"Aplicación de ejemplo",
    yourInstagramAccount:"Su cuenta de Instagram",
    screenshotUpload:"Captura de pantalla",
    sampleSelected:"Muestra seleccionada",
    agreeTerms:"Estoy de acuerdo con todos los términos anteriores",
    myActivity:"Mis actividades",
    promotions:"Promoción",


}

export default common
