const campaignsDetail = {
    campaignId: 'الرقم التعريفي للحملة',
    campaignType: 'نوع الحملة',
    campaignDuration: 'المدَّة',
    freeSample: 'عيِّنة مجانية',
    yes:'نعم',
    no:'لا',
    averageEarn: 'متوسط الإيرادات',
    like: 'إعجاب',
    applyNow: 'تقدّم الآن',
    task: 'مهمة',
    step: 'خطوة',
    step1: 'تقدّم واستلم الرمز الترويجي الحصري',
    step2: 'شارك الحملة على مواقع التواصل الاجتماعي',
    step3: ' أرسل دليلاً على شكل لقطات شاشة أو روابط إلىanyStarr',

    shortStep1:'أنشئ محتوى يوافق متطلبات الحملة',
    shortStep2:'حمّل مسودة المحتوى للمراجعة من قبل الشركة صاحبة العلامة التجارية',

    shortStepDes:'خطوات قصيرة',
    shortStepDes1:'حمّل مسودة المحتوى للمراجعة',
    shortStepDes2:'شاركها على مواقع التواصل الاجتماعي',
    shortStepDes3:'حمّل رابط ولقطة شاشة عن منشورك',
    sampleSelect:'إن ناسب أسلوبك أبكومو وربحت عيّنة، يمكنك استبدالها من خلال موقع www.abComo.com  ',

    followRule1:'1. @abcomoofficial تابع حساب الإنستغرام',
    followRule2:'2.تلتزم بنشر مقاطع فيديو لافتتاح الصندوق + منشورات وأنت ترتديها',
    followRule3:'3.تشارك رمزك الخاص مع متابعيك في المنشور',
    sampleApplication: 'نموذج الطلب',
    terms: 'الشروط',
    mySampleApplication: 'نموذج طلبي',
    submitApplication: 'إرسال الطلب',
    yourIns: "رابط الإنستغرام الخاص بك",

    abcomoStep1:'تقدّم واستلم العيِّنة',
    abcomoStep2:'شارك الحملة على حساباتك في مواقع التواصل الاجتماعي',
    abcomoStep3:' أرسل دليلاً على شكل لقطات شاشة أو روابط إلىanyStarr',



}

export default campaignsDetail