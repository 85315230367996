const address = {
    shippingAddress: "عنوان الشحن",
    delete: "حذف",
    edit:"تعديل",
    newAddress:"العنوان الجديد",
    addAddress:"إضافة عنوان",
    name:"اسم",
    contactNum:"رقم جهة الاتصال",
    address:"العنوان",
    country:"الدولة",
    postCode:"رمز المنشور",
    save:"حفظ",
    province:"المحافظة",
    city:"المدينة"

}

export default address