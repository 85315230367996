const email = {
    emailPlacehold: 'Correo electrónico',
    codePlacehold: '6-digit Código de Verificación',
    emailPlease: 'Correo electrónico es inválido',
    codePlease: 'Código de Verificación es inválido',
    register: 'Regístrese',
    emailRegx: 'Correo electrónico es inválido',
    getCode: 'Solicitud',
    login: 'Se connecter',
    emailTs1:"Para continuar, complete la verificación por correo electrónico",
    emailTs2:"¡Código enviado! Por favor revise su correo electrónico y use el cuadro de arriba para enviar el código de verificación."
}

export default email