const bank = {
    AddAccount:"Adicionar conta",
    bankPaypal:"Conta do Banco/Paypal",
    accountType:"Tipo de conta",
    accountNo:"Sua conta",
    fullNameBank:"Nome completo na sua conta bancária",
    bankName:"Nome do banco",
    swiftCode:"Código swift",
    bank:"Banco",
    bankAccount:"Conta bancária",
    paypalAccount:"Conta PayPal",
    acountNo:"Sua conta",
    otherBank:"Outro banco"

}
export default bank