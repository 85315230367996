const messageUpdates = {
    anystarrUpdates: 'Mises à jour',
    contactAnystarr: 'Nous contacter',
    viewDetail: "Détails",

    contactTitle:" Vous avez une question?",
    contactTitle1:" Contactez-nous",
    whatAbout: "En quoi concerne la demande?",
    yourCountry:"Votre pays",
    yourEmail:"Votre adresse e-mail",
    details:"Détails",
    userName:"Votre nom",
    support:"MAXIMUM 3 FICHIERS, formats acceptés: JPG, JPEG, PNG, PDF",




    campaignsRelated:"Une campagne",
    taskRelated:"Votre mission",
    withdrawRelated:"Un paiement",
    technicalIssues:"Un problème techniques",
    others:"Autre demande",

    save:"Enregistrer"

}

export default messageUpdates