import AsyncLoadable from '../utils/AsyncLoadable';

const Login = AsyncLoadable(() => import('../views/Login'))
const RegisterPre = AsyncLoadable(() => import('../views/RegisterPre'))
const Invitecode = AsyncLoadable(() => import('../views/Invitecode'))
const Home = AsyncLoadable(() => import('../views/home'))
const Register = AsyncLoadable(() => import('../views/Register'))
const Email = AsyncLoadable(() => import('../views/Email'))
const RegisterOther = AsyncLoadable(() => import('../views/RegisterOther'))
const Landing = AsyncLoadable(() => import('../views/Landing'))
const View404 = AsyncLoadable(() => import('../views/Others/404'))
const ModifyPassword = AsyncLoadable(() => import('../views/ModifyPassword'))
const Vip = AsyncLoadable(() => import('../views/Vip'))
const MyProfileMobile = AsyncLoadable(() => import('../views/MyProfileMobile'))
const MyProfileInfoMobile = AsyncLoadable(() => import('../views/MyProfileInfoMobile'))
const ChangeLanguage = AsyncLoadable(() => import('../views/ChangeLanguage'))
const ChangeGender = AsyncLoadable(() => import('../views/ChangeGender'))
const ChangePassword = AsyncLoadable(() => import('../views/ChangePassword'))
const ChangeName = AsyncLoadable(() => import('../views/ChangeName'))
const TermConditionPdf = AsyncLoadable(() => import('../views/TermConditionPdf'))
const ChangeSocial = AsyncLoadable(() => import('../views/ChangeSocial'))
const MyAddressMobile = AsyncLoadable(() => import('../views/MyAddressMobile'))
const AddAddressMobile = AsyncLoadable(() => import('../views/AddAddressMobile'))
const BankAccountMobile = AsyncLoadable(() => import('../views/BankAccountMobile'))
const AddAccountMobile = AsyncLoadable(() => import('../views/AddAccountMobile'))
const MessageContactMobile = AsyncLoadable(() => import('../views/MessageContactMobile'))
const MessageListMobile = AsyncLoadable(() => import('../views/MessageListMobile'))
const MessageDetailMobile = AsyncLoadable(() => import('../views/MessageDetailMobile'))
const EarnWithdrawMobile = AsyncLoadable(() => import('../views/EarnWithdrawMobile'))
const TotalEarn = AsyncLoadable(() => import('../views/TotalEarn'))
const EditHeaderMobile = AsyncLoadable(() => import('../views/EditHeaderMobile'))
const RegisterCode = AsyncLoadable(() => import('../views/RegisterCode'))
const CampaignDetailMobile = AsyncLoadable(() => import('../views/CampaignDetailMobile'))
const LandingMobile = AsyncLoadable(() => import('../views/LandingMobile'))
const PrivacyPolicy = AsyncLoadable(() => import('../views/PrivacyPolicy'))
const SamplesMobile = AsyncLoadable(() => import('../views/SamplesMobile'))
const SampleDetailMobile = AsyncLoadable(() => import('../views/SampleDetailMobile'))
const Referral = AsyncLoadable(() => import('../views/Referral'))
const DownloadApp = AsyncLoadable(() => import('../views/DownloadApp'))
const CampaignDetailMobileNew = AsyncLoadable(() => import('../views/CampaignDetailMobileNew'))
const OtherSampleList = AsyncLoadable(() => import('../views/OtherSampleList'))
const OtherSampleDetail = AsyncLoadable(() => import('../views/OtherSampleDetail'))

const SimpleRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        exact: true
    },
    {
        path: '/register_pre',
        name: 'registerPre',
        component: RegisterPre,
        exact: true
    },
    {
        path: '/invitecode',
        name: 'inviteCode',
        component: Invitecode,
        exact: true
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        exact: true,
    },
    {
        path: '/registerOther',
        name: 'registerOther',
        component: RegisterOther,
        exact: true
    },
    {
        path: '/register_code',
        name: 'registerCode',
        component: RegisterCode,
        exact: true
    },
    {
        path: '/email',
        name: 'email',
        component: Email,
        exact: true,
    },
    {
        path: '/modify-password',
        name: 'modifyPassword',
        component: ModifyPassword,
        exact: true
    },
    {
        path: '/vip',
        name: 'vip',
        component: Vip,
        exact: true,
    },
    {
        path: '/404',
        name: '404',
        component: View404,
        exact: true
    },
    {
        path: '/my_profile_m',
        name: 'myProfileM',
        component: MyProfileMobile,
        exact: true
    },
    {
        path: '/my_profile_info_m',
        name: 'myProfileInfoM',
        component: MyProfileInfoMobile,
        exact: true
    },
    {
        path: '/change_language_m',
        name: 'ChangeLanguageM',
        component: ChangeLanguage,
        exact: true
    },
    {
        path: '/change_gender_m',
        name: 'ChangeGenderM',
        component: ChangeGender,
        exact: true
    },
    {
        path: '/change_password_m',
        name: 'changePassword',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/change_name_m',
        name: 'changeName',
        component: ChangeName,
        exact: true,
    },
    {
        path: '/term_condition_pdf',
        name: 'termConditionPdf',
        component: TermConditionPdf,
        exact: true,
    },
    {
        path: '/change_social_m/:id',
        name: 'changeSocial',
        component: ChangeSocial,
        exact: true
    },
    {
        path: '/my_address_m',
        name: 'MyAddressMobile',
        component: MyAddressMobile,
        exact: true
    },
    {
        path: '/add_address_m',
        name: 'AddAddressMobile',
        component: AddAddressMobile,
        exact: true
    },
    {
        path: '/bank_account_m',
        name: 'BankAccountMobile',
        component: BankAccountMobile,
        exact: true
    },
    {
        path: '/add_account_m',
        name: 'AddAccountMobile',
        component: AddAccountMobile,
        exact: true
    },
    {
        path: '/message_contact_m',
        name: 'MessageContactMobile',
        component: MessageContactMobile,
        exact: true,
    },
    {
        path: '/message_list_m',
        name: 'MessageListMobile',
        component: MessageListMobile,
        exact: true,
    },
    {
        path: '/message_detail_m/:id',
        name: 'MessageDetailMobile',
        component: MessageDetailMobile,
        exact: true
    },
    {
        path: '/earn_withdraw_m',
        name: 'EarnWithdrawMobile',
        component: EarnWithdrawMobile,
        exact: true,
    },
    {
        path: '/total_earn_m',
        name: 'TotalEarn',
        component: TotalEarn,
        exact: true,
    },
    {
        path: '/edit_header_m',
        name: 'EditHeaderMobile',
        component: EditHeaderMobile,
        exact: true 
    },
    {
        path: '/campaign_detail_mobile/:id',
        name: 'CampaignDetailMobile',
        component: CampaignDetailMobile,
        exact: true
    },
    {
        path: '/campaign_detail_mobile_new/:id',
        name: 'CampaignDetailMobileNew',
        component: CampaignDetailMobileNew,
        exact: true
    },
    {
        path: '/landing_mobile',
        name: 'LandingMobile',
        component: LandingMobile,
        exact: true
    },
    {
        path: '/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/samples_m',
        name: 'SamplesMobile',
        component: SamplesMobile,
        exact: true
    },
    {
        path: '/sample_detail_m/:id',
        name: 'SampleDetailMobile',
        component: SampleDetailMobile,
        exact: true
    },
    {
        path: '/referral',
        name: 'Referral',
        component: Referral,
        exact: true
    },
    {
        path: '/download_app',
        name: 'DownloadApp',
        component: DownloadApp,
        exact: true
    },
    {
        path: '/other_sample_list',
        name: 'OtherSampleList',
        component: OtherSampleList,
        exact: true
    },
    {
        path: '/other_sample_detail/:id',
        name: 'OtherSampleDetail',
        component: OtherSampleDetail,
        exact: true
    },



    // en
    {
        path: '/en/login',
        name: 'Login',
        component: Login,
        exact: true
    },
    {
        path: '/en/register_pre',
        name: 'registerPre',
        component: RegisterPre,
        exact: true
    },
    {
        path: '/en/invitecode',
        name: 'inviteCode',
        component: Invitecode,
        exact: true
    },
    {
        path: '/en/register',
        name: 'register',
        component: Register,
        exact: true,
    },
    {
        path: '/en/registerOther',
        name: 'registerOther',
        component: RegisterOther,
        exact: true
    },
    {
        path: '/en/register_code',
        name: 'registerCode',
        component: RegisterCode,
        exact: true
    },
    {
        path: '/en/email',
        name: 'email',
        component: Email,
        exact: true,
    },
    {
        path: '/en/modify-password',
        name: 'modifyPassword',
        component: ModifyPassword,
        exact: true
    },
    {
        path: '/en/vip',
        name: 'vip',
        component: Vip,
        exact: true,
    },
    {
        path: '/en/404',
        name: '404',
        component: View404,
        exact: true
    },
    {
        path: '/en/my_profile_m',
        name: 'myProfileM',
        component: MyProfileMobile,
        exact: true
    },
    {
        path: '/en/my_profile_info_m',
        name: 'myProfileInfoM',
        component: MyProfileInfoMobile,
        exact: true
    },
    {
        path: '/en/change_language_m',
        name: 'ChangeLanguageM',
        component: ChangeLanguage,
        exact: true
    },
    {
        path: '/en/change_gender_m',
        name: 'ChangeGenderM',
        component: ChangeGender,
        exact: true
    },
    {
        path: '/en/change_password_m',
        name: 'changePassword',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/en/change_name_m',
        name: 'changeName',
        component: ChangeName,
        exact: true,
    },
    {
        path: '/en/term_condition_pdf',
        name: 'termConditionPdf',
        component: TermConditionPdf,
        exact: true,
    },
    {
        path: '/en/change_social_m/:id',
        name: 'changeSocial',
        component: ChangeSocial,
        exact: true
    },
    {
        path: '/en/my_address_m',
        name: 'MyAddressMobile',
        component: MyAddressMobile,
        exact: true
    },
    {
        path: '/en/add_address_m',
        name: 'AddAddressMobile',
        component: AddAddressMobile,
        exact: true
    },
    {
        path: '/en/bank_account_m',
        name: 'BankAccountMobile',
        component: BankAccountMobile,
        exact: true
    },
    {
        path: '/en/add_account_m',
        name: 'AddAccountMobile',
        component: AddAccountMobile,
        exact: true
    },
    {
        path: '/en/message_contact_m',
        name: 'MessageContactMobile',
        component: MessageContactMobile,
        exact: true,
    },
    {
        path: '/en/message_list_m',
        name: 'MessageListMobile',
        component: MessageListMobile,
        exact: true,
    },
    {
        path: '/en/message_detail_m/:id',
        name: 'MessageDetailMobile',
        component: MessageDetailMobile,
        exact: true
    },
    {
        path: '/en/earn_withdraw_m',
        name: 'EarnWithdrawMobile',
        component: EarnWithdrawMobile,
        exact: true,
    },
    {
        path: '/en/total_earn_m',
        name: 'TotalEarn',
        component: TotalEarn,
        exact: true,
    },
    {
        path: '/en/edit_header_m',
        name: 'EditHeaderMobile',
        component: EditHeaderMobile,
        exact: true 
    },
    {
        path: '/en/campaign_detail_mobile/:id',
        name: 'CampaignDetailMobile',
        component: CampaignDetailMobile,
        exact: true
    },
    {
        path: '/en/campaign_detail_mobile_new/:id',
        name: 'CampaignDetailMobileNew',
        component: CampaignDetailMobileNew,
        exact: true
    },
    {
        path: '/en/landing_mobile',
        name: 'LandingMobile',
        component: LandingMobile,
        exact: true
    },
    {
        path: '/en/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/en/samples_m',
        name: 'SamplesMobile',
        component: SamplesMobile,
        exact: true
    },
    {
        path: '/en/sample_detail_m/:id',
        name: 'SampleDetailMobile',
        component: SampleDetailMobile,
        exact: true
    },
    {
        path: '/en/referral',
        name: 'Referral',
        component: Referral,
        exact: true
    },
    {
        path: '/en/download_app',
        name: 'DownloadApp',
        component: DownloadApp,
        exact: true
    },
    {
        path: '/en/other_sample_list',
        name: 'OtherSampleList',
        component: OtherSampleList,
        exact: true
    },
    {
        path: '/en/other_sample_detail/:id',
        name: 'OtherSampleDetail',
        component: OtherSampleDetail,
        exact: true
    },

    //spainsh

    {
        path: '/es/login',
        name: 'Login',
        component: Login,
        exact: true
    },
    {
        path: '/es/register_pre',
        name: 'registerPre',
        component: RegisterPre,
        exact: true
    },
    {
        path: '/es/invitecode',
        name: 'inviteCode',
        component: Invitecode,
        exact: true
    },
    {
        path: '/es/register',
        name: 'register',
        component: Register,
        exact: true,
    },
    {
        path: '/es/registerOther',
        name: 'registerOther',
        component: RegisterOther,
        exact: true
    },
    {
        path: '/es/register_code',
        name: 'registerCode',
        component: RegisterCode,
        exact: true
    },
    {
        path: '/es/email',
        name: 'email',
        component: Email,
        exact: true,
    },
    {
        path: '/es/modify-password',
        name: 'modifyPassword',
        component: ModifyPassword,
        exact: true
    },
    {
        path: '/es/vip',
        name: 'vip',
        component: Vip,
        exact: true,
    },
    {
        path: '/es/404',
        name: '404',
        component: View404,
        exact: true
    },
    {
        path: '/es/my_profile_m',
        name: 'myProfileM',
        component: MyProfileMobile,
        exact: true
    },
    {
        path: '/es/my_profile_info_m',
        name: 'myProfileInfoM',
        component: MyProfileInfoMobile,
        exact: true
    },
    {
        path: '/es/change_language_m',
        name: 'ChangeLanguageM',
        component: ChangeLanguage,
        exact: true
    },
    {
        path: '/es/change_gender_m',
        name: 'ChangeGenderM',
        component: ChangeGender,
        exact: true
    },
    {
        path: '/es/change_password_m',
        name: 'changePassword',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/es/change_name_m',
        name: 'changeName',
        component: ChangeName,
        exact: true,
    },
    {
        path: '/es/term_condition_pdf',
        name: 'termConditionPdf',
        component: TermConditionPdf,
        exact: true,
    },
    {
        path: '/es/change_social_m/:id',
        name: 'changeSocial',
        component: ChangeSocial,
        exact: true
    },
    {
        path: '/es/my_address_m',
        name: 'MyAddressMobile',
        component: MyAddressMobile,
        exact: true
    },
    {
        path: '/es/add_address_m',
        name: 'AddAddressMobile',
        component: AddAddressMobile,
        exact: true
    },
    {
        path: '/es/bank_account_m',
        name: 'BankAccountMobile',
        component: BankAccountMobile,
        exact: true
    },
    {
        path: '/es/add_account_m',
        name: 'AddAccountMobile',
        component: AddAccountMobile,
        exact: true
    },
    {
        path: '/es/message_contact_m',
        name: 'MessageContactMobile',
        component: MessageContactMobile,
        exact: true,
    },
    {
        path: '/es/message_list_m',
        name: 'MessageListMobile',
        component: MessageListMobile,
        exact: true,
    },
    {
        path: '/es/message_detail_m/:id',
        name: 'MessageDetailMobile',
        component: MessageDetailMobile,
        exact: true
    },
    {
        path: '/es/earn_withdraw_m',
        name: 'EarnWithdrawMobile',
        component: EarnWithdrawMobile,
        exact: true,
    },
    {
        path: '/es/total_earn_m',
        name: 'TotalEarn',
        component: TotalEarn,
        exact: true,
    },
    {
        path: '/es/edit_header_m',
        name: 'EditHeaderMobile',
        component: EditHeaderMobile,
        exact: true 
    },
    {
        path: '/es/campaign_detail_mobile/:id',
        name: 'CampaignDetailMobile',
        component: CampaignDetailMobile,
        exact: true
    },
    {
        path: '/es/campaign_detail_mobile_new/:id',
        name: 'CampaignDetailMobileNew',
        component: CampaignDetailMobileNew,
        exact: true
    },
    {
        path: '/es/landing_mobile',
        name: 'LandingMobile',
        component: LandingMobile,
        exact: true
    },
    {
        path: '/es/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/es/samples_m',
        name: 'SamplesMobile',
        component: SamplesMobile,
        exact: true
    },
    {
        path: '/es/sample_detail_m/:id',
        name: 'SampleDetailMobile',
        component: SampleDetailMobile,
        exact: true
    },
    {
        path: '/es/referral',
        name: 'Referral',
        component: Referral,
        exact: true
    },
    {
        path: '/es/download_app',
        name: 'DownloadApp',
        component: DownloadApp,
        exact: true
    },
    {
        path: '/es/other_sample_list',
        name: 'OtherSampleList',
        component: OtherSampleList,
        exact: true
    },
    {
        path: '/es/other_sample_detail/:id',
        name: 'OtherSampleDetail',
        component: OtherSampleDetail,
        exact: true
    },


    //fr

    {
        path: '/fr/login',
        name: 'Login',
        component: Login,
        exact: true
    },
    {
        path: '/fr/register_pre',
        name: 'registerPre',
        component: RegisterPre,
        exact: true
    },
    {
        path: '/fr/invitecode',
        name: 'inviteCode',
        component: Invitecode,
        exact: true
    },
    {
        path: '/fr/register',
        name: 'register',
        component: Register,
        exact: true,
    },
    {
        path: '/fr/registerOther',
        name: 'registerOther',
        component: RegisterOther,
        exact: true
    },
    {
        path: '/fr/register_code',
        name: 'registerCode',
        component: RegisterCode,
        exact: true
    },
    {
        path: '/fr/email',
        name: 'email',
        component: Email,
        exact: true,
    },
    {
        path: '/fr/modify-password',
        name: 'modifyPassword',
        component: ModifyPassword,
        exact: true
    },
    {
        path: '/fr/vip',
        name: 'vip',
        component: Vip,
        exact: true,
    },
    {
        path: '/fr/404',
        name: '404',
        component: View404,
        exact: true
    },
    {
        path: '/fr/my_profile_m',
        name: 'myProfileM',
        component: MyProfileMobile,
        exact: true
    },
    {
        path: '/fr/my_profile_info_m',
        name: 'myProfileInfoM',
        component: MyProfileInfoMobile,
        exact: true
    },
    {
        path: '/fr/change_language_m',
        name: 'ChangeLanguageM',
        component: ChangeLanguage,
        exact: true
    },
    {
        path: '/fr/change_gender_m',
        name: 'ChangeGenderM',
        component: ChangeGender,
        exact: true
    },
    {
        path: '/fr/change_password_m',
        name: 'changePassword',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/fr/change_name_m',
        name: 'changeName',
        component: ChangeName,
        exact: true,
    },
    {
        path: '/fr/term_condition_pdf',
        name: 'termConditionPdf',
        component: TermConditionPdf,
        exact: true,
    },
    {
        path: '/fr/change_social_m/:id',
        name: 'changeSocial',
        component: ChangeSocial,
        exact: true
    },
    {
        path: '/fr/my_address_m',
        name: 'MyAddressMobile',
        component: MyAddressMobile,
        exact: true
    },
    {
        path: '/fr/add_address_m',
        name: 'AddAddressMobile',
        component: AddAddressMobile,
        exact: true
    },
    {
        path: '/fr/bank_account_m',
        name: 'BankAccountMobile',
        component: BankAccountMobile,
        exact: true
    },
    {
        path: '/fr/add_account_m',
        name: 'AddAccountMobile',
        component: AddAccountMobile,
        exact: true
    },
    {
        path: '/fr/message_contact_m',
        name: 'MessageContactMobile',
        component: MessageContactMobile,
        exact: true,
    },
    {
        path: '/fr/message_list_m',
        name: 'MessageListMobile',
        component: MessageListMobile,
        exact: true,
    },
    {
        path: '/fr/message_detail_m/:id',
        name: 'MessageDetailMobile',
        component: MessageDetailMobile,
        exact: true
    },
    {
        path: '/fr/earn_withdraw_m',
        name: 'EarnWithdrawMobile',
        component: EarnWithdrawMobile,
        exact: true,
    },
    {
        path: '/fr/total_earn_m',
        name: 'TotalEarn',
        component: TotalEarn,
        exact: true,
    },
    {
        path: '/fr/edit_header_m',
        name: 'EditHeaderMobile',
        component: EditHeaderMobile,
        exact: true 
    },
    {
        path: '/fr/campaign_detail_mobile/:id',
        name: 'CampaignDetailMobile',
        component: CampaignDetailMobile,
        exact: true
    },
    {
        path: '/fr/campaign_detail_mobile_new/:id',
        name: 'CampaignDetailMobileNew',
        component: CampaignDetailMobileNew,
        exact: true
    },
    {
        path: '/fr/landing_mobile',
        name: 'LandingMobile',
        component: LandingMobile,
        exact: true
    },
    {
        path: '/fr/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/fr/samples_m',
        name: 'SamplesMobile',
        component: SamplesMobile,
        exact: true
    },
    {
        path: '/fr/sample_detail_m/:id',
        name: 'SampleDetailMobile',
        component: SampleDetailMobile,
        exact: true
    },
    {
        path: '/fr/referral',
        name: 'Referral',
        component: Referral,
        exact: true
    },
    {
        path: '/fr/download_app',
        name: 'DownloadApp',
        component: DownloadApp,
        exact: true
    },
    {
        path: '/fr/other_sample_list',
        name: 'OtherSampleList',
        component: OtherSampleList,
        exact: true
    },
    {
        path: '/fr/other_sample_detail/:id',
        name: 'OtherSampleDetail',
        component: OtherSampleDetail,
        exact: true
    },


    //polish

    {
        path: '/pl/login',
        name: 'Login',
        component: Login,
        exact: true
    },
    {
        path: '/pl/register_pre',
        name: 'registerPre',
        component: RegisterPre,
        exact: true
    },
    {
        path: '/pl/invitecode',
        name: 'inviteCode',
        component: Invitecode,
        exact: true
    },
    {
        path: '/pl/register',
        name: 'register',
        component: Register,
        exact: true,
    },
    {
        path: '/pl/registerOther',
        name: 'registerOther',
        component: RegisterOther,
        exact: true
    },
    {
        path: '/pl/register_code',
        name: 'registerCode',
        component: RegisterCode,
        exact: true
    },
    {
        path: '/pl/email',
        name: 'email',
        component: Email,
        exact: true,
    },
    {
        path: '/pl/modify-password',
        name: 'modifyPassword',
        component: ModifyPassword,
        exact: true
    },
    {
        path: '/pl/vip',
        name: 'vip',
        component: Vip,
        exact: true,
    },
    {
        path: '/pl/404',
        name: '404',
        component: View404,
        exact: true
    },
    {
        path: '/pl/my_profile_m',
        name: 'myProfileM',
        component: MyProfileMobile,
        exact: true
    },
    {
        path: '/pl/my_profile_info_m',
        name: 'myProfileInfoM',
        component: MyProfileInfoMobile,
        exact: true
    },
    {
        path: '/pl/change_language_m',
        name: 'ChangeLanguageM',
        component: ChangeLanguage,
        exact: true
    },
    {
        path: '/pl/change_gender_m',
        name: 'ChangeGenderM',
        component: ChangeGender,
        exact: true
    },
    {
        path: '/pl/change_password_m',
        name: 'changePassword',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/pl/change_name_m',
        name: 'changeName',
        component: ChangeName,
        exact: true,
    },
    {
        path: '/pl/term_condition_pdf',
        name: 'termConditionPdf',
        component: TermConditionPdf,
        exact: true,
    },
    {
        path: '/pl/change_social_m/:id',
        name: 'changeSocial',
        component: ChangeSocial,
        exact: true
    },
    {
        path: '/pl/my_address_m',
        name: 'MyAddressMobile',
        component: MyAddressMobile,
        exact: true
    },
    {
        path: '/pl/add_address_m',
        name: 'AddAddressMobile',
        component: AddAddressMobile,
        exact: true
    },
    {
        path: '/pl/bank_account_m',
        name: 'BankAccountMobile',
        component: BankAccountMobile,
        exact: true
    },
    {
        path: '/pl/add_account_m',
        name: 'AddAccountMobile',
        component: AddAccountMobile,
        exact: true
    },
    {
        path: '/pl/message_contact_m',
        name: 'MessageContactMobile',
        component: MessageContactMobile,
        exact: true,
    },
    {
        path: '/pl/message_list_m',
        name: 'MessageListMobile',
        component: MessageListMobile,
        exact: true,
    },
    {
        path: '/pl/message_detail_m/:id',
        name: 'MessageDetailMobile',
        component: MessageDetailMobile,
        exact: true
    },
    {
        path: '/pl/earn_withdraw_m',
        name: 'EarnWithdrawMobile',
        component: EarnWithdrawMobile,
        exact: true,
    },
    {
        path: '/pl/total_earn_m',
        name: 'TotalEarn',
        component: TotalEarn,
        exact: true,
    },
    {
        path: '/pl/edit_header_m',
        name: 'EditHeaderMobile',
        component: EditHeaderMobile,
        exact: true 
    },
    {
        path: '/pl/campaign_detail_mobile/:id',
        name: 'CampaignDetailMobile',
        component: CampaignDetailMobile,
        exact: true
    },
    {
        path: '/pl/campaign_detail_mobile_new/:id',
        name: 'CampaignDetailMobileNew',
        component: CampaignDetailMobileNew,
        exact: true
    },
    {
        path: '/pl/landing_mobile',
        name: 'LandingMobile',
        component: LandingMobile,
        exact: true
    },
    {
        path: '/pl/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/pl/samples_m',
        name: 'SamplesMobile',
        component: SamplesMobile,
        exact: true
    },
    {
        path: '/pl/sample_detail_m/:id',
        name: 'SampleDetailMobile',
        component: SampleDetailMobile,
        exact: true
    },
    {
        path: '/pl/referral',
        name: 'Referral',
        component: Referral,
        exact: true
    },
    {
        path: '/pl/download_app',
        name: 'DownloadApp',
        component: DownloadApp,
        exact: true
    },
    {
        path: '/pl/other_sample_list',
        name: 'OtherSampleList',
        component: OtherSampleList,
        exact: true
    },
    {
        path: '/pl/other_sample_detail/:id',
        name: 'OtherSampleDetail',
        component: OtherSampleDetail,
        exact: true
    },
    //pt

    {
        path: '/pt-BR/login',
        name: 'Login',
        component: Login,
        exact: true
    },
    {
        path: '/pt-BR/register_pre',
        name: 'registerPre',
        component: RegisterPre,
        exact: true
    },
    {
        path: '/pt-BR/invitecode',
        name: 'inviteCode',
        component: Invitecode,
        exact: true
    },
    {
        path: '/pt-BR/register',
        name: 'register',
        component: Register,
        exact: true,
    },
    {
        path: '/pt-BR/registerOther',
        name: 'registerOther',
        component: RegisterOther,
        exact: true
    },
    {
        path: '/pt-BR/register_code',
        name: 'registerCode',
        component: RegisterCode,
        exact: true
    },
    {
        path: '/pt-BR/email',
        name: 'email',
        component: Email,
        exact: true,
    },
    {
        path: '/pt-BR/modify-password',
        name: 'modifyPassword',
        component: ModifyPassword,
        exact: true
    },
    {
        path: '/pt-BR/vip',
        name: 'vip',
        component: Vip,
        exact: true,
    },
    {
        path: '/pt-BR/404',
        name: '404',
        component: View404,
        exact: true
    },
    {
        path: '/pt-BR/my_profile_m',
        name: 'myProfileM',
        component: MyProfileMobile,
        exact: true
    },
    {
        path: '/pt-BR/my_profile_info_m',
        name: 'myProfileInfoM',
        component: MyProfileInfoMobile,
        exact: true
    },
    {
        path: '/pt-BR/change_language_m',
        name: 'ChangeLanguageM',
        component: ChangeLanguage,
        exact: true
    },
    {
        path: '/pt-BR/change_gender_m',
        name: 'ChangeGenderM',
        component: ChangeGender,
        exact: true
    },
    {
        path: '/pt-BR/change_password_m',
        name: 'changePassword',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/pt-BR/change_name_m',
        name: 'changeName',
        component: ChangeName,
        exact: true,
    },
    {
        path: '/pt-BR/term_condition_pdf',
        name: 'termConditionPdf',
        component: TermConditionPdf,
        exact: true,
    },
    {
        path: '/pt-BR/change_social_m/:id',
        name: 'changeSocial',
        component: ChangeSocial,
        exact: true
    },
    {
        path: '/pt-BR/my_address_m',
        name: 'MyAddressMobile',
        component: MyAddressMobile,
        exact: true
    },
    {
        path: '/pt-BR/add_address_m',
        name: 'AddAddressMobile',
        component: AddAddressMobile,
        exact: true
    },
    {
        path: '/pt-BR/bank_account_m',
        name: 'BankAccountMobile',
        component: BankAccountMobile,
        exact: true
    },
    {
        path: '/pt-BR/add_account_m',
        name: 'AddAccountMobile',
        component: AddAccountMobile,
        exact: true
    },
    {
        path: '/pt-BR/message_contact_m',
        name: 'MessageContactMobile',
        component: MessageContactMobile,
        exact: true,
    },
    {
        path: '/pt-BR/message_list_m',
        name: 'MessageListMobile',
        component: MessageListMobile,
        exact: true,
    },
    {
        path: '/pt-BR/message_detail_m/:id',
        name: 'MessageDetailMobile',
        component: MessageDetailMobile,
        exact: true
    },
    {
        path: '/pt-BR/earn_withdraw_m',
        name: 'EarnWithdrawMobile',
        component: EarnWithdrawMobile,
        exact: true,
    },
    {
        path: '/pt-BR/total_earn_m',
        name: 'TotalEarn',
        component: TotalEarn,
        exact: true,
    },
    {
        path: '/pt-BR/edit_header_m',
        name: 'EditHeaderMobile',
        component: EditHeaderMobile,
        exact: true 
    },
    {
        path: '/pt-BR/campaign_detail_mobile/:id',
        name: 'CampaignDetailMobile',
        component: CampaignDetailMobile,
        exact: true
    },
    {
        path: '/pt-BR/campaign_detail_mobile_new/:id',
        name: 'CampaignDetailMobileNew',
        component: CampaignDetailMobileNew,
        exact: true
    },
    {
        path: '/pt-BR/landing_mobile',
        name: 'LandingMobile',
        component: LandingMobile,
        exact: true
    },
    {
        path: '/pt-BR/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/pt-BR/samples_m',
        name: 'SamplesMobile',
        component: SamplesMobile,
        exact: true
    },
    {
        path: '/pt-BR/sample_detail_m/:id',
        name: 'SampleDetailMobile',
        component: SampleDetailMobile,
        exact: true
    },
    {
        path: '/pt-BR/referral',
        name: 'Referral',
        component: Referral,
        exact: true
    },
    {
        path: '/pt-BR/download_app',
        name: 'DownloadApp',
        component: DownloadApp,
        exact: true
    },
    {
        path: '/pt-BR/other_sample_list',
        name: 'OtherSampleList',
        component: OtherSampleList,
        exact: true
    },
    {
        path: '/pt-BR/other_sample_detail/:id',
        name: 'OtherSampleDetail',
        component: OtherSampleDetail,
        exact: true
    },

     //ar

    {
        path: '/ar/login',
        name: 'Login',
        component: Login,
        exact: true
    },
    {
        path: '/ar/register_pre',
        name: 'registerPre',
        component: RegisterPre,
        exact: true
    },
    {
        path: '/ar/invitecode',
        name: 'inviteCode',
        component: Invitecode,
        exact: true
    },
    {
        path: '/ar/register',
        name: 'register',
        component: Register,
        exact: true,
    },
    {
        path: '/ar/registerOther',
        name: 'registerOther',
        component: RegisterOther,
        exact: true
    },
    {
        path: '/ar/register_code',
        name: 'registerCode',
        component: RegisterCode,
        exact: true
    },
    {
        path: '/ar/email',
        name: 'email',
        component: Email,
        exact: true,
    },
    {
        path: '/ar/modify-password',
        name: 'modifyPassword',
        component: ModifyPassword,
        exact: true
    },
    {
        path: '/ar/vip',
        name: 'vip',
        component: Vip,
        exact: true,
    },
    {
        path: '/ar/404',
        name: '404',
        component: View404,
        exact: true
    },
    {
        path: '/ar/my_profile_m',
        name: 'myProfileM',
        component: MyProfileMobile,
        exact: true
    },
    {
        path: '/ar/my_profile_info_m',
        name: 'myProfileInfoM',
        component: MyProfileInfoMobile,
        exact: true
    },
    {
        path: '/ar/change_language_m',
        name: 'ChangeLanguageM',
        component: ChangeLanguage,
        exact: true
    },
    {
        path: '/ar/change_gender_m',
        name: 'ChangeGenderM',
        component: ChangeGender,
        exact: true
    },
    {
        path: '/ar/change_password_m',
        name: 'changePassword',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/ar/change_name_m',
        name: 'changeName',
        component: ChangeName,
        exact: true,
    },
    {
        path: '/ar/term_condition_pdf',
        name: 'termConditionPdf',
        component: TermConditionPdf,
        exact: true,
    },
    {
        path: '/ar/change_social_m/:id',
        name: 'changeSocial',
        component: ChangeSocial,
        exact: true
    },
    {
        path: '/ar/my_address_m',
        name: 'MyAddressMobile',
        component: MyAddressMobile,
        exact: true
    },
    {
        path: '/ar/add_address_m',
        name: 'AddAddressMobile',
        component: AddAddressMobile,
        exact: true
    },
    {
        path: '/ar/bank_account_m',
        name: 'BankAccountMobile',
        component: BankAccountMobile,
        exact: true
    },
    {
        path: '/ar/add_account_m',
        name: 'AddAccountMobile',
        component: AddAccountMobile,
        exact: true
    },
    {
        path: '/ar/message_contact_m',
        name: 'MessageContactMobile',
        component: MessageContactMobile,
        exact: true,
    },
    {
        path: '/ar/message_list_m',
        name: 'MessageListMobile',
        component: MessageListMobile,
        exact: true,
    },
    {
        path: '/ar/message_detail_m/:id',
        name: 'MessageDetailMobile',
        component: MessageDetailMobile,
        exact: true
    },
    {
        path: '/ar/earn_withdraw_m',
        name: 'EarnWithdrawMobile',
        component: EarnWithdrawMobile,
        exact: true,
    },
    {
        path: '/ar/total_earn_m',
        name: 'TotalEarn',
        component: TotalEarn,
        exact: true,
    },
    {
        path: '/ar/edit_header_m',
        name: 'EditHeaderMobile',
        component: EditHeaderMobile,
        exact: true 
    },
    {
        path: '/ar/campaign_detail_mobile/:id',
        name: 'CampaignDetailMobile',
        component: CampaignDetailMobile,
        exact: true
    },
    {
        path: '/ar/campaign_detail_mobile_new/:id',
        name: 'CampaignDetailMobileNew',
        component: CampaignDetailMobileNew,
        exact: true
    },
    {
        path: '/ar/landing_mobile',
        name: 'LandingMobile',
        component: LandingMobile,
        exact: true
    },
    {
        path: '/ar/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/ar/samples_m',
        name: 'SamplesMobile',
        component: SamplesMobile,
        exact: true
    },
    {
        path: '/ar/sample_detail_m/:id',
        name: 'SampleDetailMobile',
        component: SampleDetailMobile,
        exact: true
    },
    {
        path: '/ar/referral',
        name: 'Referral',
        component: Referral,
        exact: true
    },
    {
        path: '/ar/download_app',
        name: 'DownloadApp',
        component: DownloadApp,
        exact: true
    },
    {
        path: '/ar/other_sample_list',
        name: 'OtherSampleList',
        component: OtherSampleList,
        exact: true
    },
    {
        path: '/ar/other_sample_detail/:id',
        name: 'OtherSampleDetail',
        component: OtherSampleDetail,
        exact: true
    },
]

export default SimpleRoutes