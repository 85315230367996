import React, {Component} from 'react';
import './style.less';
import PropTypes from 'prop-types';

export default class Index extends Component {
  componentDidMount () {
    window.NProgress && window.NProgress.done (true);
  }

  render () {
    const {children, language} = this.props;
    return (
      <div className="layout-block">
        {children}
      </div>
    );
  }
}

Index.defaultProps = {
  title:{}
};

Index.propTypes = {
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
