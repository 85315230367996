const email = {
    emailPlacehold: 'E-mail',
    codePlacehold: '6-cyfrowy kod weryfikacyjny',
    emailPlease: 'Nie podano adresu e-mail',
    codePlease: 'Nie podano kodu weryfikacyjnego',
    register: 'Zarejestruj siê',
    emailRegx: 'Adres e-mail jest niepoprawny!',
    getCode: 'Wyœlij',
    login: 'Zaloguj siê',
    emailTs1:"Aby móc kontynuowaæ, dokoñcz weryfikacjê e-mail",
    emailTs2:"Kod wys³any! SprawdŸ skrzynkê odbiorcz¹ i wprowadŸ kod weryfikacyjny w polu powy¿ej."
}

export default email