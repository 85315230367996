const account = {
   myAccount: 'Mi cuenta',
   myProfile: "Mi perfil",
   shippingAddress: "Dirección",
   bankAccount: "Cuenta de pago",
   myEarnings: "Mis ganancias",
   earningHistory: "Registro de ganancias",
   withdraw: "Historial de retiros",


   logout: "Cerrar sesión",

   personalInfo: "Informacion personal",
   email: "Correo Electrónico",
   invitationCode: "Código de Invitación",
   name:"Nombre",
   gender: "Sexo",
   language:"Idioma",
   password:"Contraseña",
   confirmPassword:"Confirmar contraseña",
   changePassword:"Cambiar contraseña",
   male:"Masculino",
   female:"Femenino",
   other:"prefiero no contestar eso",

   socialProfiles: "Perfiles sociales",
   follower:"Ubicación de los seguidores",
   fbName:"Usuario de Facebook",
   insName:"Instagram Handle",
   tiktokName:"TikTok Handle",

   save:"Guardar",


   new:"Nuevo",
   nil:"Nul",
   edit:"Editar",
   

}

export default account