export default {
    all: '全部',
    search: '查询',
    reset: '重置',
    confirm: '确认',
    cancel: '取消',
    loading: '加载中',
    upload: '上传',
    delete: '删除',
    edit: '编辑',
    back: '上一步',
    next: '下一步',
    jump: '跳过',
    submit: '提交',
    waring: '提示',
    return: '返回',
    save: '保存',
    projectInfo: '项目介绍',
    rule: '规则',
    pleaseEnter: '请输入',
    supportExtension: '支持扩展名',
    requiredOrNot: '是否必填',
    yes: '是',
    no: '否',
    fieldName: '字段名称',
    ruleWrappedByBrackets: '【规则】'
}
