import Cookies from 'js-cookie'
let userInfo = Cookies.get('dsb_user_info')



export const getYearMonthDay = (nS) => {
    nS = nS?nS:'0'
    let rens = nS.replace(' ','T')
    let timeStamp = Date.parse(rens) 
    // return new Date(timeStamp).getFullYear()
    return (new Date(timeStamp).getDate()<10? '0'+ new Date(timeStamp).getDate(): new Date(timeStamp).getDate() ) + '.' + (new Date(timeStamp).getMonth() <9 ?  '0' + (new Date(timeStamp).getMonth() + 1) :  new Date(timeStamp).getMonth() + 1 )  + '.' + new Date(timeStamp).getFullYear()
    // return new Date(timeStamp).getFullYear() + '/' + (new Date(timeStamp).getMonth() + 1) + '/' + (new Date(timeStamp).getDate())
}

export const getYearMonthDay1 = (nS) => {
    let timeStamp = Date.parse(nS) 
    // return new Date(timeStamp).getFullYear()
    return (new Date(timeStamp).getDate()<10? '0'+ new Date(timeStamp).getDate(): new Date(timeStamp).getDate() ) + '.' + (new Date(timeStamp).getMonth() <9 ?  '0' + (new Date(timeStamp).getMonth() + 1) :  new Date(timeStamp).getMonth() + 1 )  + '.' + new Date(timeStamp).getFullYear()

}


export const getYearMonthDayTime = (timeStamp) => {
    let date = new Date(timeStamp)
    let YY = new Date(timeStamp).getFullYear()
    let MM = new Date(timeStamp).getMonth() < 9 ? '0'+ (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1
    let DD = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate()
    let hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours()
    let mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes()
    let ss = new Date(timeStamp).getSeconds() < 10 ? '0' + new Date(timeStamp).getSeconds() : new Date(timeStamp).getSeconds()
    return  `${DD}.${MM}.${YY} ${hh}:${mm}:${ss}`
}

export const isLogin = () => {
    if(userInfo){
        return true
    }
    return false      
}

export const getTransCard = (str) => {
    var reg = /^(\d{2})\d+(\d{4})$/
    str = str.replace(reg,"$1 ********** $2")
    return str
}

export const isMobile = () => {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

    if(!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)){
      return false
    }else{
      return true
    }
}

export const getLanguage = (str) => {
    switch(str) {
        case 'en_US':
            return '1';
            break;
        case 'es_ES':
            return '3';
            break;
        case 'fr_FR':
            return '2';
            break;
        case 'pl_PL':
            return '4';
            break;
        case 'pt_BR':
            return '5';
            break;
        case 'ar_AR':
            return '6';
            break;    
        default:
            return '1';                   
    }
}

export const getPushPathByLn = () => {
    let babelLang = localStorage.getItem('babelLang')
    let pathName = window.location.pathname
    if(!pathName.includes('/en/') && babelLang == 'en_US'){
      return ''
    }
    switch (babelLang) {
        case 'en_US':
            return '/en'
        case 'es_ES':
            return '/es'
        case 'fr_FR':
            return '/fr'
        case 'pl_PL':
            return '/pl'
        case 'pt_BR':
            return '/pt-BR'    
        case 'ar_AR':
            return '/ar'                
        default:
            return '/en'
    }
}

export const getPushPathByLnInfo = () => {
    let babelLang = localStorage.getItem('babelLang')
    let pathName = window.location.pathname
    if(!pathName.includes('/en/') && babelLang == 'en_US'){
      return ''
    }
    switch (babelLang) {
        case 'en_US':
            return '/en'
        case 'es_ES':
            return '/es'
        case 'fr_FR':
            return '/fr'
        case 'pl_PL':
            return '/pl'
        case 'pt_BR':
            return '/pt-br'   
        case 'ar_AR':
            return '/ar'                 
        default:
            return '/en'
    }
    
}

export const getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
    var r = window.location.search.substr(1).match(reg); 
    if (r != null) return unescape(r[2]); 
    return null; 
}


export const isApp = () => {
    let urlApp = getQueryString('app')
    let storageApp = localStorage.getItem('app')
    if(urlApp==='true' || storageApp==='true' ){
        localStorage.setItem('app','true')
        return true
    }
}