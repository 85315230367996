import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './App'
import Intl from './components/Intl'
import AppDownlaod from './components/AppDownload'

import 'sanitize.css/sanitize.css'
import './index.css'

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <Intl>
      {/* <AppDownlaod> */}
        <App />
      {/* </AppDownlaod> */}
    </Intl>
  </Provider>,
  target
)
