const invitecode = {
    invitecode: "Code d'invitation",
    pleaseInvitecode: "Code d'invitation est manquante",
    joinNow: 'Suivant',
    noCode: "Inscription sans code d'invitation",
    registerHere: "S'inscrire",


}

export default invitecode

