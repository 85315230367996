const campaigns = {
    advanceSearch: 'Advance Search',
    duration: 'Duration',
    regionCoverage: 'Region',
    AverageEarn: 'Average earnings',
    like: 'Like',
    joinNow: 'Join Now!',
    joined:"Go to task",
    comingSoon: 'Coming soon ',
    expired: 'Expired',
    rewards:'Rewards',
    worldWide:'Worldwide',

    


}

export default campaigns