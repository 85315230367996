const common =  {
    login: "Se connecter/S'inscrire",
    loginS: "Se connecter",
    signUp: "S'inscrire",
    contactUs: 'Nous contacter',
    allCampaigns: 'Les campagnes',
    myTasks: 'Mes missions',
    messages: 'Mes messages',
    search: 'Rechercher',
    allRegion: 'Toutes les régions',
    france: 'France',
    spain: 'Espagne',
    rejectReason:'Raison du rejet',

    next:"Suivant",
    forgetPwd:"Mot de passe oublié",
    pleasePwd:"Entrez le mot de pass actuel",
    pleaseEmail:"Saisissez l'adresse e-mail",
    invalidLink:"Un lien invalide",

    referNow: "RÉFÉRER MAINTENANT ET ÊTRE RÉCOMPENSÉ",
    enjoyColl:"Profitez de la collaboration avec vos marques préférées? Dire à un ami!",
    earn:"Gagner",
    commissionOf:"commission de  de tous les revenus de vos amis gagnent",
    referralCode:"Code de Parrainage",
    inviteFiendsAndEarn:"Inviter des amis et gagnez",
    howToParti:"Comment participer",
    launchanyStarr:"Lancez l'application anyStarr et cliquez sur",
    referYouFri:"Parrainez vos amis",
    generateYour:"générer votre",
    andClickOn:"et cliquez sur",
    inviteMoreFri:"Invite plus d'amis",
    share:"Partager",
    yourUniqueRefe:"votre code de parrainage unique avec vos amis et commencer à gagner",
    earningRules:"Règles gagner",
    aSuccessfulReferral:"Un moyen de référence avec succès que vos signes d'ami mentionnés à l'aide de votre code de parrainage.",
    youWillOnlyRe:"Vous ne recevrez une commission sur les gains de vos amis pendant 90 jours à compter de leur date d'inscription jusqu'à 1000 $ au total.",
    myInvite:"Mes invitations:",
    inviteDes1:"Dans l'attente de la campagne de démarrage",
    inviteDes2:"campagne a participé",
    inviteMoreFri:"Invite plus d'amis",
    FAQ:"FAQ",
    question1:"Pourquoi ai-je pas reçu mes récompenses après mes amis je faisais référence à anyStarr signais?",
    questionDes1:"Votre ami a peut-être oublié d'inclure votre code de référence lors de la signature OU il / elle n'a pas commencé à gagner encore.",
    question2:"Comment puis-je savoir si mon ami a mentionné un succès inscription?",
    questionDes2:"Une fois votre inscription sur anyStarr avec votre code de parrainage à vos amis, leur ID utilisateur willl apparaissent [Mes invitations] sous [Compte - Inviter des amis et gagnez]",
    question3:"Où puis-je trouver mon code de référence?",
    questionDes3:"Vous pouvez trouver votre code de référence exclusif sous [Compte - Inviter des amis et gagnez]",
    termsAndCon:"Termes et conditions",
    tc1:"Le Parrainer Vos Amis-est éligible pour les utilisateurs qui n'ont pas NOUVELLES signé.",
    tc2:"Les utilisateurs qui ont déjà signé sans un code de référence ne peut pas une nouvelle demande avec le code. Leurs référants ne seront pas admissibles à recevoir cette récompense.",
    tc3:"anyStarr se réserve le droit de modifier les T & C à tout moment sans notification préalable.",
    tc4:"En participant au programme Parrainez un ami, vous acceptez T & C.",
    inviteFriendEarn:"Inviter des amis et gagner",
    mySamples:"Mes échantillons", 
    invitationCode:"Code d'invitation (facultatif)",

    postRequirement:"Exigence de poste",
    postExample:"Exemple de poste",
    downloadImagesShare:"Télécharger des images et partager",
    caption:"Légende",
    copy:"Copie",
    by:"Par",
    submitContent:"Soumettez votre description de contenu",
    submitLink:"Soumettre un lien de votre message de médias sociaux",
    mySampleApplication:"Mon exemple d'application",
    sample:"Goûter",
    status:"Statut",
    applicationSubmitted:"Application déposée",
    shippedOn:"Expédiés sur",
    clickToTrack:"Cliquez pour suivre",
    congrats:"Félicitations! Votre échantillon est prêt à échanger! Rabais sera automatiquement appliqué à la caisse",
    pendingShipment:"expédition en attente",
    applicationUnsuccess:"Application infructueuse",
    rewards:"Récompenses",
    rewardsDes:"Pour chaque nouvel utilisateur qui votre code, vous recevrez une commission basée sur les éléments suivants.",
    numberOfCode:"Nombre d'utilisation du code",
    rewardPerUsage:"Récompense par usage",
    uploaded:"Téléchargé!",
    loginFacebookShare:"Login Facebook à partager",
    uploadScreenshots:"Téléchargez votre carte d'écran et votre (s) lien (s)",
    plsApplySampleFirst:"S'il vous plaît appliquer d'abord un échantillon",
    plsPatientSampleApproval:"S'il vous plaît soyez patient avec l'approbation de l'échantillon",
    taskClosed:"Tâche fermée",
    newTask:"Nouvelle tâche",
    nextStep:"L'étape suivante",
    submitContent:"Soumettre le contenu",
    pendingReview:"En attendant l'examen",
    resubmitContent:"Contenu à nouveau",
    stepCompleted:"Tâche terminée",

    countryCode:"Pays",
    firstName:"Prénom",
    lastName:"Nom de famille",
    bankName:"Nom de banque",
    streetAddress:"Adresse de la rue",
    city:"Ville",
    state:"État",
    postCode:"Code postal",
    sampleRedeemed:"Échantillon racheté",
    redeemNow:"Échangez maintenant",
    deliveryStatus:"Statut de livraison",
    inTransit:"En transit",
    notShipped:"Pas livré",
    receivedGoods:"Marchandises reçues",
    trackingNum:"Numéro de suivi",
    shippedOn:"Expédiés sur",
    worldwide:"Mondial",
    sampleStatus:"état de l'échantillon",
    applied:"appliqué",
    sampleApplication:"Exemple d'application",
    yourInstagramAccount:"Votre compte Instagram",
    screenshotUpload:"Téléchargement des captures d'écran",
    sampleSelected:"Échantillons sélectionnés",
    agreeTerms:"J'accepte toutes les conditions ci - dessus",
    myActivity:"Mes activités",
    promotions:"Promotion",


    
}

export default common

