import React, { useRef, useState } from "react";
import ReactDOM from 'react-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { CloseOutlined } from '@ant-design/icons'

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/lazy/lazy.min.css"
import "swiper/components/zoom/zoom.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/thumbs/thumbs.min.css"
import { DownloadOutlined } from '@ant-design/icons'


// import Swiper core and required modules
import SwiperCore, {
  Zoom,Navigation,Pagination,Thumbs,Lazy
} from 'swiper/core';
import './style.css'
import { useEffect } from "react";
import { isMobile } from "../../tools/action";
import { message } from "antd";

// install Swiper modules
SwiperCore.use([Zoom,Navigation,Pagination,Thumbs,Lazy]);


export default function App(props) {
  

  const [activeIndex,setActiveIndex] = useState(1)

  const closeSwiper = () => {
      props.closeSwiper()
  }
  
  const onSlideChange = (data) => {
      console.log('datattttt',data)
      setActiveIndex(data.activeIndex+1)
  }

  const downloadImg = (url, filename) => {
    //如果提供filename，则filename需要包含扩展名
    var link,
        evt;
    
    link = document.createElement('a');
    link.href = url;
    filename && link.setAttribute('download', filename);
    if(document.fireEvent) {
        window.open(link.href);
    }else {
        evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, true);
        link.dispatchEvent(evt);
    }
  
  }

  const downloadPic = () => {
      if(isMobile()&&navigator.userAgent.match(/android/i)){
        return message.success("If you can't download it, please press and hold to save the picture")
      }
      let picList = props.imgList
      let picUrl = picList[activeIndex]
      // downloadImg(picUrl,'pic'+activeIndex)
      let img = new Image();
      img.setAttribute('crossOrigin', 'anonymous')
      img.src = picUrl;
      img.onload = function() {
      let canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      let context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      let url = canvas.toDataURL('image/jpeg');
      downloadImg(url,'anystarrpic'+activeIndex);
     }

  }

  
  useEffect(()=>{
     setActiveIndex(props.activeIndex)
     console.log('index', props.activeIndex)
  },[props.visible])

  if(!props.visible){
      return null
  }
  return  ReactDOM.createPortal( 
    <div className="black-mask opa1">
     <div>
         <CloseOutlined className="close-swiper" onClick={closeSwiper}/>
         <span className="page-index">{activeIndex}/{props.imgList?props.imgList.length:0}</span>
           {/* <DownloadOutlined className="download-pic"  onClick={downloadPic} /> */}
     </div>
     <Swiper onSlideChange={onSlideChange} initialSlide={props.activeIndex-1} style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} lazy={true} pagination={{
      "clickable": true
      }} navigation={true} className="mySwiper">
          {
              props.imgList&&props.imgList.length&&props.imgList.map((item,index)=>{
                  return (
                      <SwiperSlide key={index} >
                       <img   data-src={item} className="swiper-lazy" />
                       <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                     </SwiperSlide>
                  )
              })
          }
     </Swiper>
    </div>,
    document.body
  )
}