const address = {
    shippingAddress: "Dirección",
    delete: "Descartar",
    edit:"Edotar",
    newAddress:"Dirección nueva",
    addAddress:"Añadir una dirección",
    name:"Nombre y apellidos",
    contactNum:"Número de teléfono",
    address:"Dirección",
    country:"País/Región",
    postCode:"Código postal",
    save:"Salvar",
    province:"Provincia",
    city:"Ciudad"

}

export default address