import TaskDetail from "../../views/TaskDetail/TaskDetail"

const taskDetail = {
    statusFur: 'Status further explanation , if needed',
    step1: 'Krok 1',
    stepDes1: 'Aplikuj i otrzymaj ekskluzywny kod promocyjny.',
    applyPromoCode: 'Zastosuj kod promocyjny',
    howToUse: 'Jak efektywnie korzystać z kodu promocyjnego',
    copyCode: 'Kopiuj',
    step2: 'Krok 2',
    stepDes2: 'Udostępnij kampanię w mediach społecznościowych.',
    step3: 'Krok 3',
    stepDes3: 'Prześlij zrzuty ekranu lub linki do anyStarr jako dowód.',
    newImage: 'Nowy wygląd',
    inputWebsitelink: 'Prześlij linki jako dowód.',
    addUri: 'Dodaj więcej linków',
    submit: 'Prześlij',



    endDate: 'Data zakonczenia',
    discountCode: 'Kod rabatowy',

    clicktoApply: 'Kod promocyjny',
    noCode: 'Brak kodu',

    step1: "Aplikuj i otrzymaj ekskluzywny kod promocyjny.",
    step2: "Udostępnij kampanię w mediach społecznościowych.",
    step3: "Prześlij zrzuty ekranu lub linki do anyStarr jako dowód.",

    newImages: "Nowe pliki",

    upPic:"Do 5 obrazów, obsługiwane formaty: JPG, JPEG, PNG (Max. 3MB)",

    upLink: "Do 3 łączy",

    addMoreLink: "Dodaj więcej linków",

    submit: "Prześlij",


    submitScreenshot:"Prześlij zrzut ekranu swojego posta w mediach społecznościowych",
    submitLinkL:"Prześlij link do swojego posta w mediach społecznościowych",
    

    sampleStepDes:"Zastosuj i odbierz próbkę",
    applySample:"Kliknij, aby zastosować",

}

export default taskDetail