import React, { useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Modal,
} from 'antd'
import {
    connect
} from 'react-redux'
import {
    chooseLocale
} from '../../locales/util';
import './languagemodal.css';
import IcSelectL from '../../imgs/ic_select_l.png'
import { getValue, setCurrentLanguage } from '../../locales/util'
import UkFlag from '../../imgs/uk.png'
import EsFlag from '../../imgs/es.png'
import FrFlag from '../../imgs/fr.png'
import PlFlag from '../../imgs/pl.png'
import BrFlag from '../../imgs/brazil.png'
import ArFlag from '../../imgs/ar.png'


// const languageList = ['English','Español','Français','Polski','Português']
const languageList = [
    {name:'English',value:'en_US'},
    {name:'Español',value:'es_ES'},
    {name:'Français',value:'fr_FR'},
    {name:'Polski',value:'pl_PL'},
    {name:'Português',value:'pt_BR'},
    {name:'بالعربية',value:'ar_AR'}
    
]

const isVisible = () => {
    let babelLang = localStorage.getItem('babelLang')
    console.log('babelLang',babelLang)
    return babelLang?false:true
}

export default function LanguageModal (props) {
    const [state,setState] = useState({
        selectIndex:0
    })

    const selectLanguage = (index,data) => {
         setState({
            selectIndex: index
        })
        console.log('data',data)
         setCurrentLanguage(data.value)
         window.location.reload()
        


    }

    return (
        <Modal
         className="loading-modal"
         // visible={isVisible()}
         visible={false}
         width={300}
         centered
         closable={false}
         footer={null}>
        <div className="language-wrapper-m">
           <div className="language-item-m select-t-m">Select language: {languageList[state.selectIndex].name}</div>
            {
                languageList.map((item,index) => {
                    return (
                        <div className={index == state.selectIndex ? 'language-item-m active':'language-item-m'}  key={item.name} onClick={ () => {selectLanguage(index,item)} }>
                            {
                                index == 0?<img className="flag-icon" src={UkFlag} />:
                                index == 1?<img className="flag-icon" src={EsFlag} />:
                                index == 2?<img className="flag-icon" src={FrFlag}/>:
                                index == 3?<img className="flag-icon" src={PlFlag} />:
                                index == 4?<img className="flag-icon" src={BrFlag}/>:
                                index == 5?<img className="flag-icon" src={ArFlag} />:null
                            }
                            <span className="flag-name">{item.name}</span>
                            {
                                index == state.selectIndex ? <img className="select-l-icon-m" src={IcSelectL} />: null
                            }
                        </div>
                    )
                })
            }
                
        </div>
        
        </Modal>
    )
}