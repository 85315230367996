const invitecode = {
    invitecode: 'رمز الدعوة',
    pleaseInvitecode: 'رمز دعوة غير صالح',
    joinNow: 'التالي',
    noCode: 'لا تملك رمز دعوة؟',
    registerHere: 'التسجيل لأول مرة',


}

export default invitecode
