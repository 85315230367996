const email = {
    emailPlacehold: 'Email',
    codePlacehold: 'Código de verificação de 6-digitos',
    emailPlease: 'Emailfaltando',
    codePlease: 'Código de verificação faltando',
    register: 'Registrar',
    emailRegx: 'Email não válido!',
    getCode: 'Solicitação',
    login: 'Entrar',
    emailTs1:"Para continuar, por favor complete a verificação do email",
    emailTs2:"Código enviado! Por favor verifique seu email e use a caixa acima para colocar o código de verificação"
}

export default email