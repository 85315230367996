const earn = {
    codesClaimed:"Códigos Reinvidicados",
    onGoingTasks:"Tarefas",
    totalEarning:"Ganhos totais",
    earnDes:"Os ganhos são divulgados no meio de cada mês e são fornecidos a critério da marca desta campanha.",
    duration:"Duração",
    promoCode:"Código promocional",
    earnUpToDate:"Ganho até a data",
    updatedOn:"Atualizado em",
    AccountBalance:"Balanço da conta",
    withdraw:"Retirada",
    lasestWithdrawRequest:"Último pedido de retirada",
    toBankAccount:"Para conta",
    withdrawAmount:"Total da retirada",
    yourRequestProcessed:"Seu  pedido está sendo processado",
    inProgress:"Em progresso",
    failed:"Falha",
    completed:"Completo",
    withdrawHistory:"Histórico de retirada",
    cancel:"Cancelar",
    apply:"Aplicar",
    minimum:"mínimo",
    pleaseContact:"Por favor nos contate para mais informação",
    to:"para",

}
export default earn