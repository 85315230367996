import $ from 'jquery'
import {
    dealResult
} from './dealResult'
// import Headers from './headers';
import {
    message
} from 'antd'
import Cookies from 'js-cookie'

function ajaxRequest(methods, url, params,contentType, token) {
    return new Promise((resolve, reject) => {


        const token1 = localStorage.getItem("token")
        // localStorage.setItem('token', token)
        console.log('requesttoken',token)
        const language = localStorage.getItem('babelLang')?localStorage.getItem('babelLang'):''
        console.log('language',language)
        let resLanguage;
        switch (language) {
            case 'en_US':
                resLanguage = 1;
                break;
            case 'es_ES':
                resLanguage = 3;
                break;
            case 'fr_FR':
                resLanguage = 2;
                break;
            case 'pl_PL':
                resLanguage = 4;
                break;
            case 'pt_BR':
                resLanguage = 5;
                break;
            case 'ar_AR':
                resLanguage = 6;
                break;
            default:
                resLanguage = 1;
                break;
        }

        $.ajax({
            type: methods,
            url: url,
            data: params,
            dataType: 'json',
            contentType: contentType=='json'? 'application/json':'application/x-www-form-urlencoded',
            // headers: headers,
            beforeSend: function (request) {
                if(token1){
                    request.setRequestHeader('language', resLanguage)
                    request.setRequestHeader('token',token1)
                }else {
                    request.setRequestHeader('language', resLanguage)
                }


            },
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            // withCredentials: true,
            success: resp => {
                let flog = dealResult(resp)
                if (!flog) {
                    message.error(resp.message)
                    return
                }
                resolve(resp)
            },
            error: err => {
                console.log('errcc', err)
                reject(err)
            }
        })
    })
}

function ajaxRequestUpload(methods, url, params) {
    return new Promise((resolve, reject) => {
        var formData = new FormData()
        console.log('params',params)
        var fileType = params.file.type.split('/')[0]
        formData.append('file', params.file)
        formData.append('type',fileType)
        $.ajax({
            type: methods,
            url: url,
            processData: false,
            data: formData,
            // dataType: 'json',
            contentType: false,
            success: resp => {
                let flog = dealResult(resp)
                if (!flog) {
                    message.error(resp.msg)
                    return
                }
                resolve(resp)
            },
            error: err => {
                console.log('errcc', err)
                reject(err)
            }
        })
    })
}

export default {
    post(url, params) {
        return ajaxRequest('POST', url, params,'')
    },
    get(url, params, token) {
        return ajaxRequest('GET', url, params, '', token)
    },
    upload(url, params) {
        return ajaxRequestUpload('POST', url, params)
    },
    postJson(url, params,) {
       return ajaxRequest('POST', url, params,'json')
    },
    getJson(url, params,) {
       return ajaxRequest('GET', url, params,'json')
    }


}
