import common from './common'
import home from './home'
import login from './login'
import invitecode from './invitecode'
import register from './register'
import email from './email'
import campaigns from './campaigns'
import campaignDetail from './campaignsDetail'
import task from './task'
import taskDetail from './taskDetail'
import messageUpdates from './messageUpdates'
import account from './account'
import address from './address'
import bank from './bank'
import earn from './earn'
import {
    getMessages
} from '../../locales/util'

export default {
    ...getMessages(common, 'common'),
    ...getMessages(home, 'home'),
    ...getMessages(login, 'login'),
    ...getMessages(invitecode, 'invitecode'),
    ...getMessages(register, 'register'),
    ...getMessages(email, 'email'),
    ...getMessages(campaigns, 'campaigns'),
    ...getMessages(campaignDetail, 'campaignDetail'),
    ...getMessages(task,'task'),
    ...getMessages(taskDetail,'taskDetail'),
    ...getMessages(messageUpdates,'messageUpdates'),
    ...getMessages(account, 'account'),
    ...getMessages(address, 'address'),
    ...getMessages(bank, 'bank'),
    ...getMessages(earn, 'earn')
}