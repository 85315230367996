const messageUpdates = {
    anystarrUpdates: 'Actualizaciones',
    contactAnystarr: 'Contacta con nosotras',
    viewDetail: "Más información",

    contactTitle:"¿Tienes alguna pregunta?",
    contactTitle1:"¡contactanos!",
    whatAbout: "Asunto de la consulta",
    yourCountry:"su país",
    yourEmail:"Su email",
    details:"Detalles",
    userName:"Su nombre",
    support:"Hasta 3 documentos, formatos compatibles: JPG, JPEG, PNG, PDF (máx. 3MB)",




    campaignsRelated:"Relacionado con la campaña",
    taskRelated:"Relacionada con la tarea",
    withdrawRelated:"Relacionado con un pago",
    technicalIssues:"Problemas técnicos",
    others:"Otro",

    save:"Salvar"

}

export default messageUpdates