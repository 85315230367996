const invitecode = {
    invitecode: '请输入邀请码',
    pleaseInvitecode: '邀请码不能为空',
    joinNow: '现在加入',
    noCode: '还没有邀请码 ?',
    registerHere: '点击注册',


}

export default invitecode