import React, { useEffect, useState, Suspense } from 'react'
import {
  Layout,
  Row,
  Col,
  Tag,
  Table,
  Button,
  Modal,
  Input,
  Form,
  DatePicker,
  Select,
  message,
  Pagination,
  Timeline,
  Spin,
  Upload,
  Image,
  Progress
} from 'antd'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  increment,
  incrementAsync,
  decrement,
  decrementAsync,
} from '../../modules/counter'

import { changeLanguage } from '../../modules/appReducers'
import { getValue, setCurrentLanguage } from '../../locales/util'
import {
  APIgetCountry,
  APIgetCampaignDetail,
  APIlikeCampaign,
  APIapplyCampaign,
  APIgetTaskDetail,
  APIuploadFile,
  APIsubmitTask,
  APIgetPromoCode,
  APIgetMyAddress,
  APIcreateOrModifyAddress,
  APIApplySample,
  APIContentSubmit
} from '../../api'
import '../CampaignDetail/campaigndetail.css'
import './taskDetail.css'
import { HeartFilled, CloseCircleFilled, DeleteFilled,LoadingOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {getYearMonthDay, isMobile, getPushPathByLn}  from '../../tools/action.js'
import TaskSt from '../../imgs/task_st.png'
import StepArrow from '../../imgs/arrow.png'
import IcZy from '../../imgs/ic_zy.png'
import IcDelectLink from '../../imgs/ic_delect_link.png'
import reasonArrow from '../../imgs/reason_arrow.png'
import IcStatusZ from '../../imgs/ic_status_z.png'
import $ from 'jquery'
import VideoIcon from '../../imgs/video.png'
import IcProofVe from '../../imgs/ic_proof_verified.png'
import IcPendUpload from '../../imgs/ic_pending_proof upload.png'
import MySwiper from '../../components/MySwiper'
import MoreIcon from '../../imgs/more1.png'



import TextArea from 'antd/lib/input/TextArea'
import { padEnd } from '../../tools/host'


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select
let lnPath = getPushPathByLn()



const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};


const AddressModal = ({ visible, onCreate, onCancel, language }) => {
  const [form] = Form.useForm()

  const [state, setState] = useState({
       countryList:[],

  })




  const options = state.countryList.map((item, index) => {
        return (
            <Option key={item.id} value={item.name}>
                {item.name}
            </Option>
        )
  })

  const getCountry = () => {
    APIgetCountry().then(resp => {
      setState({
        ...state,
        countryList: resp.data.data
      })
    })

  }

  useEffect(() => {
    getCountry()

  },[])

  const addressOnFinish = (values) => {
     form.validateFields()
            .then(values => {
              form.resetFields()
              onCreate(values)
            })
            .catch(info => {
              console.log('Validate Failed:', info)
            })

  }

  return (
    <Modal
      visible={visible}
      width={330}
      onCancel={onCancel}
      okText='Submit'
      footer={null}>
      <div className="add-form" style={{ paddingTop:'40px' }}>
          <Form name="add-bank-account" {...layout} form={form}  onFinish={addressOnFinish} >
              <Form.Item label={getValue('address.name',language)} name="name">
                          <Input/>
              </Form.Item>
              <Form.Item label={getValue('address.contactNum',language)} name="contact_number">
                          <Input/>
              </Form.Item>
              <Form.Item label={getValue('address.address',language)} name="address">
                          <TextArea/>
              </Form.Item>
              <Form.Item label={getValue('address.country',language)} name="country">
                <Select showSearch>
                    {options}
                </Select>
              </Form.Item>
              <Form.Item label={getValue('address.postCode',language)} name="post_code">
                    <Input/>
              </Form.Item>
              <Form.Item  className="save-btn-wrapper" {...tailLayout}>
                <Button htmlType="submit" className="save-btn address-save-btn"  >{getValue('address.save',language)}</Button>
              </Form.Item>
              
          </Form>

      </div>



    </Modal>
  )

}






const CampaignDetail = (props) => {

  const language = props.language

  const [state, setState] = useState({
    evidenceImgs: [],
    links: [],
    promoCode: null,
    banner: null,
    title: '',
    earning: '',
    status: null,
    campaignId:'',
    taskCloseTime:'',
    taskDes:'',
    codeNum:0,
    reason:null,
    loading:false,
    uploadProgress:0,
    type:1,
    sampleOrder:null,
    addressModalVisible: false,
    proofRequirement:'',
    newContent:'',
    postExample:null,
    commission:0,
    steps:{},
    is_open_share:0,
    campaignDO:{},


    postExampleImage:[
      "https://swiperjs.com/demos/images/nature-1.jpg",
      "https://swiperjs.com/demos/images/nature-2.jpg",
      "https://swiperjs.com/demos/images/nature-3.jpg",
      "https://swiperjs.com/demos/images/nature-4.jpg",
      "https://swiperjs.com/demos/images/nature-5.jpg",
      "https://swiperjs.com/demos/images/nature-6.jpg"
    ],
    postExampleImageVis:false,

  })


  const getDetailInfo = () => {
    let id = props.match.params.id
    if (id) {
      APIgetTaskDetail({
        id: id,
      }).then((resp) => {
        let resData = resp.data.data
        let steps = resData.steps? JSON.parse(resData.steps):{}
        let evidenceImgs = resp.data.data.images
          ? JSON.parse(resp.data.data.images).map((item, index) => {
              return {
                img: item,
                id: getRandom(),
              }
            })
          : []  

       let links
       let content
       if(resData.type==3&&resData.status==2 || resData.status==7 || resData.status==8 || resData.status==9){
          links = []
          content = resData.linkes
          evidenceImgs = []



       }else{
          links = resp.data.data.linkes
          ? JSON.parse(resp.data.data.linkes).map((item, index) => {
              return {
                href: item,
                id: getRandom(),
              }
            })
          : []
       }

        
        if(resData.status == 4){
          evidenceImgs = []
          links = []

        }  

        setState({
          ...state,
          evidenceImgs: evidenceImgs,
          links: links,
          promoCode: resp.data.data.promo_code,
          banner: resp.data.data.banner,
          title: resp.data.data.title,
          earning: resp.data.data.earning,
          status: resp.data.data.status,
          campaignId: resp.data.data.campaign_id,
          taskCloseTime: resp.data.data.task_close_time,
          taskDes: resp.data.data.task_description,
          codeNum: resp.data.data.code_number,
          reason: resp.data.data.reason,
          type: resp.data.data.type,
          sampleOrder: resp.data.data.sample_order,
          addressModalVisible:false,
          proofRequirement: resp.data.data.proof_requirement,
          newContent:content,
          postExample: resp.data.data.post_example,
          commission: resp.data.data.commission,
          is_open_share: resp.data.data.is_open_share,
          campaignDO: resp.data.data.campaignDO,
          steps,
        })

      })
    }
  }

  const showhtml = (htmlString) => {
    var html = { __html: htmlString }
    return <div dangerouslySetInnerHTML={html} />
  }

  const likeClick = (id) => {
    APIlikeCampaign({ id, id }).then((resp) => {
      let resStatus = resp.data.status
      setState({
        ...state,
        is_like: resStatus,
      })
    })
  }

  const applyNow = (id) => {
    APIapplyCampaign({ id: id }).then((resp) => {
      message.success('success')
      props.history.push({
        pathname: lnPath+'/',
      })
    })
  }

  const addUriClick = () => {
    let resLinks = state.links || []
     if(resLinks.length>=3){
      return
    }
    resLinks.push({ href: '', id: getRandom() })
    setState({
      ...state,
      links: resLinks,
    })
  }

  const getRandom = () => {
    return Math.floor(Math.random() * 999 + 999)
  }

  const uploadFile = ({ file, onSuccess }) => {
    // setState({
    //   ...state,
    //   loading:true
    // })
    let num = 0
    let timer = setInterval(()=>{
      if(num>=90){
        setState({
          ...state,
          loading:true,
          uploadProgress:99
        })
      }else{
        num = num + Math.floor(Math.random()*5)
        setState({
        ...state,
        loading: true,
        uploadProgress: num
       })
      }
    },1000)

    APIuploadFile({ file: file }).then((resp) => {
        onSuccess()
      let resImgs = state.evidenceImgs || []
      resImgs.push({ img: resp.data.url, id: resp.data.url })
      clearInterval(timer)
      setState({
        ...state,
        evidenceImgs: resImgs,
        loading: false,
        uploadProgress:0,
      })

    })
  }

  const handleDelImg = (index) => {
    let resImgs = state.evidenceImgs
    resImgs.splice(index, 1)
    setState({
      ...state,
      evidenceImgs: resImgs,
    })
  }

  const handleDelInput = (index) => {
    let resLinks = state.links
    resLinks.splice(index, 1)
    setState({
      ...state,
      links: resLinks,
    })
  }

  const handleChangeUrl = (e, index) => {
    e.persist()
    let value = e.target.value
    let resLinks = state.links
    resLinks[index].href = value
    setState({
      ...state,
      links: resLinks,
    })
  }

  const handleSubmit = () => {
    let taskId = props.match.params.id;
    let images = state.evidenceImgs.map((item) => {
      return item.img
    })
    let linkes = state.links.map((item) => {
      return item.href
    })
    if(state.type == '3' && ( state.status == '2' || state.status == '8' )) {
      linkes = state.newContent
    }

    if(state.type == '3' && (state.status == '2' || state.status == '8')){
      const params = {
        taskJson: JSON.stringify({
          id: taskId,
          draft_images: images,
          draft_content: linkes
        })
      }
      APIContentSubmit(params).then((resp) => {
         message.success('success')
         props.history.push(lnPath+'/task')
      })
    }else{
        const params = {
          taskJson: JSON.stringify({
            id: taskId,
            images: images,
            linkes: linkes,
          }),
        }
        APIsubmitTask(params).then((resp) => {
        message.success('success')
        props.history.push(lnPath+'/task')
      })
    }


  }

  const applyPromoCode = () => {
    if(!state.codeNum) {
       return false
    }
    if (state.promoCode) {
      return
    }
    let taskId = props.match.params.id;
    let params = {
      campaign_id: state.campaignId,
      task_id: taskId,
    }
    APIgetPromoCode({ codeJson: JSON.stringify(params) }).then((resp) => {
      message.success('success')
      getDetailInfo()
    })
  }

  const handleCopy = () => {
    message.success('copy success')
  }

  const gotoCampaignDetail = () => {
      props.history.push({
      pathname: lnPath+'/campaign-detail/'+state.campaignId,
      query: { id: state.campaignId },
    })
    sessionStorage.setItem('query',JSON.stringify({ id: state.campaignId }))

  }

  const isLink = (str) => {
     let regx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/
     let re = new RegExp(regx)
     if(re.test(str)) {
       return true
     }else {
       return false
     }
  }

  const initScroll = () => {
     if(isMobile()){
      // document.getElementById('taskdetailTitle').scrollTop = '110px'
      let fixedBar = $('.fixed-bar').height()
      console.log('fixed',fixedBar)
      $('html, body').animate({
          scrollTop: $("#taskdetailTitle").offset().top - fixedBar
      })
     }
  }

  const isVideo = (str) => {
      if(str.split('%23')[1] == 'video'){
        return true
      } 
  }

  const applySample = () => {
    console.log('applySamplepush 测试')
    let id = props.match.params.id
    APIgetMyAddress().then(resp => {
      let resData = resp.data.data
      if(!resData.length) {
        setState({
          ...state,
          addressModalVisible: true
        })
      }else{
        APIApplySample({
          sampleOrderJson:JSON.stringify({
            task_id: id,
            campaign_id: state.campaignId,
            address: resData[0]
          })
        }).then(resp => {
          getDetailInfo()
        })
      }
    })    
  }

  const addressCreate = (values) => {
    console.log('addressCreate',values)
    APIcreateOrModifyAddress({
      userAddressJson:JSON.stringify(values)
    }).then(res => {
        getDetailInfo()
      })


  }

  const addressCancel = () => {
    setState({
      ...state,
      addressModalVisible:false
    })

  }

  const newContentChange = (e) => {
    setState({
      ...state,
      newContent: e.target.value
    })
  }

  const gotoSampleDetail = () => {
    let sampleOrder = state.sampleOrder
    if(sampleOrder.status == 1 || sampleOrder.status == 4 || (sampleOrder.status == 2 && sampleOrder.type ==2)){
      // props.history.push()
      if(isMobile()){
        props.history.push(lnPath+'/samples_m/'+sampleOrder.id)
      }else{
        props.history.push(lnPath+'/sampleDetail/'+sampleOrder.id)
      }
    }

  }

  const closeSwiper = () => {
    console.log('wocaoo')
    setState({
      ...state,
      postExampleImageVis: false
    })
  }

  const showSwiper = () => {
    let postExampleArr = state.postExample?JSON.parse(state.postExample):[]
    console.log('postExampleArr',postExampleArr)
    setState({
      ...state,
      postExampleImageVis: true,
      postExampleImage: postExampleArr
    })
  }


  useEffect(() => {
    initScroll()
    getDetailInfo()
  }, [])

  return (
    <div className="campaigns-detail task-detail animated fadeIn">
      <MySwiper visible={state.postExampleImageVis} activeIndex={1}  imgList={state.postExampleImage} closeSwiper={closeSwiper} />
      {/* <Modal visible={state.postExampleImageVis} footer={null} closable={false} className="swiper-modal" keyboard>
        <MySwiper imgList={state.postExampleImage} closeSwiper={closeSwiper} />
      </Modal> */}
      <AddressModal visible={state.addressModalVisible} onCreate={ addressCreate }  onCancel={ addressCancel } language={language}  />
      <div className="detail-con">
        <div className="box-wrapper">
           <div className="header-img">
          <img className="opa1" src={state.banner && JSON.parse(state.banner)[0]} onClick={gotoCampaignDetail}  />
        </div>
        <div className="detail-info">
          <div className="title">
            <div id="taskdetailTitle" className="title-text" onClick={gotoCampaignDetail}>{state.title}</div>
          </div>
          {
            state.taskCloseTime?
            <div className="campaign-duration">
              <span className="end-date">{getValue('taskDetail.endDate',language)} </span>
              <span className="duration-time">
                 {getYearMonthDay(state.taskCloseTime)}
              </span>
            </div>:null
          }
          {
            state.commission?
            <div className="campaign-duration task-reward">
              <span className="end-date">{getValue('campaigns.rewards',language)} </span>
              <span className="duration-time task-reward-amount">${state.commission}</span>
            </div>:null
          }
          <div className="pending">
            <div className="pending-ic">
              {
                state.status == 0?null:
                state.status == 2 || state.status == 1 && state.promoCode || state.status == 4 || state.status == 8 ?
                <img src={IcPendUpload} className="task-st" />:
                state.status == 5?
                <img  className="task-st" src={IcProofVe} />:
                <img src={TaskSt} className="task-st" />
              }
              <div  className={state.status==0?"task-close-txt":"pend-txt"}>
                  {state.status == 0
                    ?  getValue('task.taskState0',language)  :
                    state.status == 1?
                    (state.promoCode? getValue('task.taskState2',language)  : getValue('task.taskState1',language) )
                    : state.status == 2
                    ? getValue('task.taskPendContent',language) 
                    : state.status == 3
                    ? getValue('task.taskState3',language) 
                    : state.status == 4
                    ? getValue('task.taskState4',language) 
                    : state.status == 5
                    ? getValue('task.taskState5',language) 
                    : state.status == 7
                    ? getValue('task.taskState7',language)
                    : state.status == 8
                    ? getValue('task.taskState8',language)
                    : state.status == 9
                    ? getValue('task.taskState2')
                  : null}
              </div>
            </div>
            {
             state.reason && state.status != 5?
             <div className="status-reject-reason">
             {/* {getValue('common.rejectReason',language)}  */}
             <img src={reasonArrow} className="reason-arrow" /> 
             <img src={IcStatusZ}  className="ic-status-z"/>


             <span>{state.reason}</span>
             </div>:null
             }
          </div>
          <div className="status-des">
            {
            state.status == 1 && state.promoCode ?
            'Please take screenshot of your post for submission':
            state.status ==3?
            'Please wait while we are checking your submission':
            state.status == 4?
            'Please check our comment for reason of rejection':
            state.status == 7?
            '':
            state.status == 8?
            '':
            state.status == 9?
            'Please take screenshot of your post for submission':
            state.status == 5?
            'Congratulations! You have completed the campaign!':''
          }
          </div>
          
          {/* <div className="status-des">
            {
              state.taskDes
            }
          </div> */}
          
        </div>
        </div>
       
       <div className="box-wrapper-c">
          {
            state.sampleOrder?
            <div className="campaign-task-step">
              <div className="my-sample-app">{getValue('campaignDetail.mySampleApplication',language)}</div>
              <div className="sample-order " onClick={gotoSampleDetail}>
                <div className="product-image">
                  <Image src={state.sampleOrder.img_url?state.sampleOrder.img_url:'https://imgdev.s3.eu-west-3.amazonaws.com/dev/20210225/aa941195-754f-4afc-8f9b-4dd730a0a71b%23null'}/>
                </div>
                <div className="s-p-order">
                  <div className="sample-p high-light-text">
                    <span className="sample-span">Sample</span>
                    {state.sampleOrder.product_name}
                  </div>
                  {
                    state.sampleOrder.status == 1 ?
                    <div>
                      <span className="status-span">Status</span>
                      <span>Application submitted</span>
                    </div>:
                    state.sampleOrder.status == 2 ?
                    <div className="sample-s-2">
                      <span className="status-span">Status</span>
                      {
                        state.sampleOrder.type==1?
                        <div>
                        <div>Shipped on {state.sampleOrder.update_time} </div>
                        <a href={state.sampleOrder.courier + state.sampleOrder.tracking_number} className="track-url">Click to track</a>
                        </div>:
                        state.sampleOrder.type==2?
                        <div>
                        <div>Congrats! Your sample is ready to redeem!Discount will automatically applied at checkout </div>
                        {/* <a href={state.sampleOrder.coupon} className="track-url">Redeem your sample</a> */}
                        </div>:null
                      }
                      
                    </div>: 
                    state.sampleOrder.status == 3 ?
                    <div>
                      <span className="status-span">Status</span>
                      <span>Pending shipment</span>
                    </div>:
                    state.sampleOrder.status == 4 ?
                    <div>
                      <div>
                        <span className="status-span">Status</span>
                        <span>Application unsuccessful</span>
                      </div>
                      {
                        state.sampleOrder.reason&&state.sampleOrder.reason!==''?
                        <div className="error-sp">!{state.sampleOrder.reason}</div>:null
                      }
                    </div>:null 
                  }
                </div>

              </div>
              


            </div>:null
          }
          <div className="campaign-task-step">
          <div className="step-i step-1 step-1-1">
            <div className="left"> {getValue('campaignDetail.step')}1  </div>
            <div className="center step1-center">
              <div className="content-de">
                { state.steps.step1&&state.steps.step1!==''?state.steps.step1:(state.type == 1 || state.type == 4 ) ? getValue('taskDetail.step1',language): state.type == 3 ? getValue('campaignDetail.shortStepDes1',language)  : getValue('taskDetail.sampleStepDes',language)}
              </div>
              {/* <a className="how-to-use">
                  How to use promotion code efficient
              </a> */}
              {/* <img className="mini-step-arrow" src={StepArrow} /> */}
              {
                (state.status==2 || state.status==8) && state.type == 3 ?
                <>
                <div className="img-evidence">
                {state.evidenceImgs &&
                  state.evidenceImgs.map((item, index) => {
                    return (
                      <div key={item.id} className="evidence-item">
                        <a 
                          href={item.img}
                          target="_blank"
                          className="evidence-item-a">
                          <img    src={isVideo(item.img)?VideoIcon:item.img}  className={isVideo(item.img)?'evidence-item-img evidence-item-img-video':'evidence-item-img'}   />
                          {
                            isVideo(item.img)?<span className="uploaded-s">Uploaded!</span>:null
                          }
                        </a>
                        <CloseCircleFilled
                          className="del-icon"
                          onClick={() => {
                            handleDelImg(index)
                          }}
                        />
                      </div>
                    )
                  })}

                { ((state.status == '1' && state.promoCode)  || state.status == '4' || state.status == '2' || state.status == '8' || state.status == '9') ? (
                 <Upload customRequest={uploadFile} className="upload-evidence">
                  <div className="upload-style">
                    {
                    state.loading?<Spin indicator={antIcon} className="login-loading"  />:
                     <>
                     <div className="add-icon">+</div>
                      <div className="text">{getValue('taskDetail.newImages',language)}</div>
                     </>
                    }
                    {
                      (state.evidenceImgs && state.evidenceImgs.length) >=5 ?
                      <div className="zy-des">
                      <img src={IcZy} className="ic-zy" />
                      <span className="zy-des-s">{getValue('taskDetail.upPic',language)}</span>
                    </div>:null
                    }
                    {
                      state.loading?
                      <Progress style={{ position:'absolute',bottom:'-20px' }}  strokeColor={{ 
                      '0%': '#F79C37',
                      '100%': '#F0774A',
                     }} percent={state.uploadProgress} size="small" />:null
                    }
                    
                    

                  </div>
                 </Upload>
                ) : null}  
                
              </div>
              {
                (state.status == '7' || state.status == '8' || (state.type == '3' && state.status == '2'))?
                <div>
                  <div className="link-intr">
                     Submit your content description 
                  </div>
                  <div>
                    <TextArea rows={5} value={state.newContent} onChange={newContentChange}/>
                  </div>


                </div>:null
              }
               { ( state.status == '2')? (
                <div className="handle-submit" onClick={handleSubmit}>
                  {getValue('taskDetail.submit',language)}
                </div>
              ) : null}

                  

                </>:null
              }


            </div>
            <div className="right">
              {
                (state.type == 1 || state.type == 4) ?
                <div className="content-how ">
                {
                  state.promoCode?
                  <CopyToClipboard
                  text={state.promoCode} // 需要复制的文本
                  onCopy={handleCopy} // 复制完成的回调
                >
                  {/* <a href="javascript:;" className="promo-code">
                    Copy code
                  </a> */}
                  <div className="code-coupon">
                  <div className="code-num">{state.promoCode}</div>
                  <div className="code-des">{getValue('taskDetail.discountCode',language)}</div>
                  </div>
                </CopyToClipboard>:
                state.status == 0 ? null :
                <div
                  className={
                    state.codeNum? 'apply-btn-task' : 'apply-btn-task-dis'
                  }
                  onClick={applyPromoCode}>
                    {state.codeNum ? getValue('taskDetail.clicktoApply',language) :  getValue('taskDetail.noCode',language)}
                </div>
                }
                
              </div>:null
              }

              {
                state.type == 2 ?
                <div className="content-how">
                  {
                    state.status == 1?
                    <div className="apply-btn-task" onClick={applySample}>
                      {getValue('taskDetail.applySample',language)}
                    </div>:state.sampleOrder && state.sampleOrder.status == 1 ?
                    <div className="request-received">Your sample request received</div>:
                    state.sampleOrder?
                    <div style={{ fontWeight:'bold' }}>
                      <div className="request-received">Sample shipped!</div>
                      <div>
                        <span>Tracking Number:</span>
                        <a href={state.sampleOrder.courier_link} target="_blank">
                          <span> {state.sampleOrder.courier}</span>
                          <span>{state.sampleOrder.tracking_number}</span>
                        </a>
                        
                      </div>
                    </div>:null
                  }

                </div>:null
              }
              
            </div>
          </div>
        </div>

        <div className="campaign-task-step">
          <div className="step-i step-1">
            <div className="left">{getValue('campaignDetail.step')}2</div>
            <div className="center">
              <div className="content-de">{state.steps.step2&&state.steps.step2!==''?state.steps.step2:state.type == 3 ? getValue('campaignDetail.shortStepDes2',language)  : getValue('taskDetail.step2',language)}</div>
            </div>
          </div>
          {
            state.postExample?
            <div className="post-example-wrapper">
              {
                state.campaignDO.post_guidelines?
                state.campaignDO.post_guidelines.split(';').map((gl,index)=>{
                  return <div key={gl} className="post-guidelines">{gl}</div>
                }):null
              }
              <div className="post-s-t">{state.campaignDO.is_open_share==1?'Download images and share':'Post example'} </div>
              <div className="step-i post-ex">
                {
                  state.campaignDO.is_open_share==1?
                  <div>
                    <div className="post">
                      {
                        JSON.parse(state.postExample).map((item,index) => {
                          if(index<4){
                            return <img className="post-example" src={item} key={index}/>
                          }
                        })
                      }

                    </div>
                    <div className="view-more-txt" onClick={showSwiper}>
                       <span>View More</span>
                       <img src={MoreIcon} className="more-icon"/>
                    </div>
                  </div>:
                  JSON.parse(state.postExample).map((item,index) => {
                    return (
                      <Image className="post-example" src={item} key={index} />
                    )
                  })
                }
              </div>

            </div>:null
          }
        </div>

        <div className="campaign-task-step">
          <div className="step-i step-1">
            <div className="left">{getValue('campaignDetail.step')}3</div>
            <div className="center">
              <div className="content-de content-step3">
                { state.steps.step3&&state.steps.step3!==''?state.steps.step3:state.type == 3 ? getValue('campaignDetail.shortStepDes3',language) :getValue('taskDetail.submitScreenshot',language)}
              </div>
              <div className="how-to-pro" style={{ color:'#999' }}>
                {/* How to use promotion code efficiently? */}
                {/* {state.proofRequirement} */}
                {
                  state.proofRequirement&&state.proofRequirement.split(';').map((item,index) => {
                    return (
                      <div key={index}>{item}</div>
                    )
                  })
                }
              </div>
              {
                state.status==2 && state.type==3 ?null:
                <div className="img-evidence">
                {state.evidenceImgs &&
                  state.evidenceImgs.map((item, index) => {
                    return (
                      <div key={item.id} className="evidence-item">
                        <a 
                          href={item.img}
                          target="_blank"
                          className="evidence-item-a">
                          <img    src={isVideo(item.img)?VideoIcon:item.img}  className={isVideo(item.img)?'evidence-item-img evidence-item-img-video':'evidence-item-img'}   />
                          {
                            isVideo(item.img)?<span className="uploaded-s">Uploaded!</span>:null
                          }
                        </a>
                        <CloseCircleFilled
                          className="del-icon"
                          onClick={() => {
                            handleDelImg(index)
                          }}
                        />
                      </div>
                    )
                  })}

                { ((state.status == '1' && state.promoCode)  || state.status == '4' || state.status == '2' || state.status == '8' || state.status == '9') ? (
                 <Upload customRequest={uploadFile} className="upload-evidence">
                  <div className="upload-style">
                    {
                    state.loading?<Spin indicator={antIcon} className="login-loading"  />:
                     <>
                     <div className="add-icon">+</div>
                      <div className="text">{getValue('taskDetail.newImages',language)}</div>
                     </>
                    }
                    {
                      (state.evidenceImgs && state.evidenceImgs.length) >=5 ?
                      <div className="zy-des">
                      <img src={IcZy} className="ic-zy" />
                      <span className="zy-des-s">{getValue('taskDetail.upPic',language)}</span>
                    </div>:null
                    }
                    {
                      state.loading?
                      <Progress style={{ position:'absolute',bottom:'-20px' }}  strokeColor={{ 
                      '0%': '#F79C37',
                      '100%': '#F0774A',
                     }} percent={state.uploadProgress} size="small" />:null
                    }
                    
                    

                  </div>
                </Upload>
              ) : null}  
                
              </div>

              }
              
              {
                (state.status == '7' || state.status == '8' || (state.status == '2' && state.type == '3'))?null:
                <div>
                      <div className="link-intr">
                    {getValue('taskDetail.submitLinkL',language)}
                  </div>
                  {state.links &&
                    state.links.map((item, index) => {
                      return (
                          <div key={item.id} className="link-cont">
                          <Input
                            value={item.href}
                            className={isLink(item.href)?'link-input':'link-input input-warning'}
                            onChange={(e) => {
                              handleChangeUrl(e, index)
                            }}
                          />
                          {
                            !isLink(item.href)? <div className="input-warning-txt">{getValue('common.invalidLink',language)}</div>:null
                          }
                          <img src={IcDelectLink} onClick={() => { handleDelInput(index) }}    className="del-input" />

                        </div>
                        
                      )
                    })}

                    {
                      (state.links&& state.links.length >= 3)?
                      <div className="zy-des zy-des-link">
                          <img src={IcZy} className="ic-zy" />
                          <span className="up-des">{getValue('taskDetail.upLink',language)}</span>
                        </div>:null

                    }

                    { ((state.status == '1' && state.promoCode)  || state.status == '4' || state.status == '2' || state.status == '9')   ? (
                    <div className="add-uri-btn" onClick={addUriClick}>
                    + {getValue('taskDetail.addMoreLink',language)}
                  </div>
                  ) : null}

                </div>
              }
              
              {/* {
                (state.status == '7' || state.status == '8' || (state.type == '3' && state.status == '2'))?
                <div>
                  <div className="link-intr">
                     Submit your content description 
                  </div>
                  <div>
                    <TextArea rows={5} value={state.newContent} onChange={newContentChange}/>
                  </div>


                </div>:null
              } */}



               

              
              { ((state.status == '1' && state.promoCode)  || state.status == '4'  || state.status == '8' || state.status == '9' || state.status == '2' )? (
                <div className="handle-submit" onClick={handleSubmit}>
                  {getValue('taskDetail.submit',language)}
                </div>
              ) : null}
            </div>
          </div>
        </div>

       </div>

       
      </div>
    </div>
  )
}

const mapStateToProps = ({ counter, appReducers }) => ({
  count: counter.count,
  isIncrementing: counter.isIncrementing,
  isDecrementing: counter.isDecrementing,
  language: appReducers.language,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      increment,
      incrementAsync,
      decrement,
      decrementAsync,
      changeLanguage,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetail)
