const login = {
    emailPlacehold: 'Correo electrónico',
    passwordPlacehold: 'Contraseña',
    login: 'Iniciar sesión',
    forgetPassword: 'Olvidé mi contraseña',
    notMember: "¿No tiene una cuenta?",
    clickToRegister: 'Regístrese',
    or: 'o',
    socialAccount: 'Inicie sesión con una cuentas de red social',
    pleaseAccount: 'Falta el correo electrónico',
    pleasePassword: 'Falta la contraseña',

    resetPassword:"Reinicia tu pase",
    resetPasswordDes:"Ingrese y confirme su nueva contraseña a continuación para acceder a su cuenta",
    invitecodeTitle:"Para continuar, complete la código de invitación que recibió de nuestro representante.",
    emailCodeTitle:"Entrada el código de verificación de tu correo electrónico",
    emailCodeDes:"Háganos saber que esta dirección de correo electrónico le pertenece.",
    complete:"Completo",
    sendAgain:"Enviar de nuevo",

    haveAccount:'¿Ya tiene una cuenta?',
    activationDes:"Casi termino ",
    activationDes1:"Restablece tu contraseña para activar tu cuenta",
    linkExpired:"Ese enlace de activación ha caducado",
    requestNewActivation:"Solicite un nuevo correo electrónico de activación o cree una nueva cuenta",
    confirm:"Confirmar",
    googleRegister:"Registrarse en google",
    sendAcEmail:"Es posible que ya esté registrado. Continúe activando su cuenta a través del correo electrónico de activación enviado a ",
    hadRegister:"Es posible que ya haya creado una cuenta. Continúe con el inicio de sesión.",

}
export default login