const earn = {
    codesClaimed:"الرموز التي حصلت عليها",
    onGoingTasks:"المهام الجارية",
    totalEarning:"إجمالي الأرباح",
    earnDes:" يتم الكشف عن الأرباح في منتصف كل شهر ويتم تقديمها وفقاً لتقدير العلامة التجارية للحملة فقط.",
    duration:"المدة",
    promoCode:"رمز الترويج",
    earnUpToDate:"الربح حتى تاريخه",
    updatedOn:"تم تحديثها في",
    AccountBalance:"رصيد الحساب",
    withdraw:"سحب",
    lasestWithdrawRequest:"طلب السحب الأخير",
    toBankAccount:"إلى حساب",
    withdrawAmount:"كمية السحب",
    yourRequestProcessed:"طلبك قيد المعالجة",
    inProgress:"في تقدُّم",
    failed:"فشل",
    completed:"اكتمل",
    withdrawHistory:"سجل السحب",
    cancel:"إلغاء",
    apply:"تقدّم",
    minimum:"الحد الأدنى",
    pleaseContact:"يرجى التواصل معنا لمزيدٍ من المعلومات",
    to:"إلى",

}
export default earn