const email = {
    emailPlacehold: 'البريد الإلكتروني',
    codePlacehold: 'رمز تحقق مكون من 6 أرقام',
    emailPlease: 'البريد الإلكتروني مفقود',
    codePlease: 'رمز التفعيل مفقود',
    register: 'سجِّل',
    emailRegx: 'عنوان البريد الإلكتروني الذي أدخلته غير صالح',
    getCode: 'طلب',
    login: 'تسجيل الدخول',
    emailTs1:"للمتابعة، يرجى استكمال التحقق من خلال البريد الإلكتروني",
    emailTs2:"تم إرسال الرمز! يرجى تفقُّد بريدك الإلكتروني واستخدام الصندوق في الأعلى لإرسال رمز التحقق."
}

export default email