const task = {
    searchInput: 'Rechercher',
    applicationDate: "Date d'application",
    taskState0: 'En attente du code promo ',
    taskState1: "Nouvelle mission",
    taskState2: 'En attente de preuves',
    taskState3: 'En attente de vérification',
    taskState4: "En attente d'autres preuves",
    taskState5: "Elements preuves confirmés",
    all: "Toutes mes tâches",
    status:"Etat de la mission",

    activated:"Activated",
    submitted:"Submitted",
    verified:"Verified",

    taskPendContent: 'Pending content upload',

    status:"Statuts",
    trackingNumber:"Numéro de la requête",
    applicationUnsuccessful:"La demande n'a pas abouti",
    applicationSubmited:"Demande envoyée",
    sampleRedeem:"L'échantillon est prêt à être échangé",
    applicationTime:"Temps d'application",
    sampleHowToRedeem:"Comment racheter?",
    samplePending:"Votre demande d'échantillon est en cours d'examen par la marque, ce qui peut prendre jusqu'à 48 heures",
    samplePendingDes:"En attendant que votre échantillon soit approuvé, vous pouvez commencer à partager votre code de réduction d'affilié avec vos followers pour commencer à gagner des commissions.",
    sampleDelivery:"À propos de la livraison d'échantillons",
    deliveryDes:"Veuillez compter 7 à 14 jours ouvrables pour la livraison de l'échantillon. Remplissez votre tâche après avoir reçu votre échantillon. Cela nous aidera à vous identifier et à vous inviter à notre prochaine campagne exclusive.",
    redeemNow:"Rédigez Maintenant",


}

export default task

