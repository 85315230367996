const account = {
   myAccount: 'Minha Conta',
   myProfile: "Meu perfil",
   shippingAddress: "Endereço de envio",
   bankAccount: "Conta bancária",
   myEarnings: "Meu Ganhos",
   earningHistory: "Histórico de ganhos",
   withdraw: "Retirada",



   logout: "Sair",

   personalInfo: "Informação pessoal",
   email: "Email",
   invitationCode: "Código de convite",
   name:"Nome",
   gender: "Gênero",
   language:"Lingua",
   password:"Senha",
   confirmPassword:"Confirmar senha",
   changePassword:"Alterar senha",
   male:"Homem",
   female:"Mulher",
   other:"Prefiro não informar",

   socialProfiles: "Perfis sociais",
   follower:"Localização principal dos seguidores",
   fbName:"Usuário do Facebook",
   insName:"Link do Instagram",
   tiktokName:"Link do Tiktok",

   save:"Salvar",


   nil:"Nada",
   edit:"Editar",
   

}

export default account