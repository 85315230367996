const login = {
    emailPlacehold: 'Adresse e-mail',
    passwordPlacehold: 'Mot de passe',
    login: 'Se connecter',
    forgetPassword: 'Mot de passe oublié ?',
    notMember: "Vous n'avez pas encore de compte ?",
    clickToRegister: "S'inscrire",
    or: 'ou',
    socialAccount: 'Se connecter avec les comptes de réseaux sociaux',
    pleaseAccount: "L'adresse mail est manquante",
    pleasePassword: 'Mot de passe est manquante',

    resetPassword:"Réinitialisez votre passe",
    resetPasswordDes:"Veuillez saisir et confirmer votre nouveau mot de passe ci-dessous pour accéder à votre compte",
    invitecodeTitle:"Pour continuer, veuillez terminer la le code d'invitation que vous avez reçu de notre représentant.",
    emailCodeTitle:"Veuillez saisir le code envoyé à votre adresse électronique",
    emailCodeDes:"Faites-nous savoir que cette adresse électronique vous appartient.",
    complete:"Compléter",
    sendAgain:"Envoyer à nouveau",

    haveAccount:'Vous possédez déjà un compte?',
    activationDes:"Presque fini ",
    activationDes1:"Réinitialisez votre mot de passe pour activer votre compte",
    linkExpired:"Ce lien d'activation a expiré",
    requestNewActivation:"Demandez un nouvel e-mail d'activation ou créez un nouveau compte",
    confirm:"Confirmation",
    googleRegister:"S'inscrire avec google",
    sendAcEmail:"Vous êtes peut-être déjà inscrit. Continuez à activer votre compte grâce à l'e-mail d'activation envoyé à ",
    hadRegister:"Vous avez peut-être déjà créé un compte. Veuillez continuer la connexion.Você já deve ter criado uma conta. Continue o login.",
}
export default login

