const address = {
    shippingAddress: "Adres wysyłki",
    delete: "Usuń",
    edit:"Edytuj",
    newAddress:"Nowy adres",
    addAddress:"Dodaj adres",
    name:"Imię i nazwisko",
    contactNum:"Contact number",
    address:"Adresy",
    country:"Powiat",
    postCode:"Kod pocztowy",
    save:"Zapisz",
    province:"Prowincja",
    city:"City"

}

export default address