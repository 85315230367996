const messageUpdates = {
    anystarrUpdates: 'anyStarr Updates',
    contactAnystarr: 'Contact anyStarr',
    viewDetail: "View detail",

    contactTitle:" Have a question? We will be happy to assist you as soon as we can. ",
    contactTitle1:" Please fill the form below and attach a screenshot of the problem.",
    whatAbout: "What's the enquiry about?",
    yourCountry:"Your country",
    yourEmail:"Your email",
    userName:"Your name",
    details:"Details",
    support:"Supported formats .JPG .JPEG .PNG .PDF (Max.3MB)",




    campaignsRelated:"Campaigns related",
    taskRelated:"Task related",
    withdrawRelated:"Withdraw related",
    technicalIssues:"Technical issues",
    others:"others",

    save:"Send"

}

export default messageUpdates