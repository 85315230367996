const messageUpdates = {
    anystarrUpdates: 'Aktualizacje anyStarr',
    contactAnystarr: 'Kontakt z anyStarr',
    viewDetail: "Zobacz szczegó³y",

    contactTitle:" Chcesz zadaæ pytanie? Z przyjemnoœci¹ pomo¿emy tak szybko, jak to mo¿liwe. ",
    contactTitle1:" Wype³nij poni¿szy formularz i za³¹cz zrzut ekranu przedstawiaj¹cy problem.",
    whatAbout: "Czego dotyczy pytanie?",
    yourCountry:"Twój kraj",
    yourEmail:"Twój adres e-mail",
    userName:"Twoje imiê i nazwisko",
    details:"Szczegó³y",
    support:"Obs³ugiwane formaty: .JPG .JPEG .PNG .PDF (maks. 3 MB)",




    campaignsRelated:"Kampanii",
    taskRelated:"Zadania",
    withdrawRelated:"Wyp³aty pieniêdzy",
    technicalIssues:"Problemów technicznych",
    others:"Inny powód",

    save:"Wyœlij"

}

export default messageUpdates