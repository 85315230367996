const campaigns = {
    advanceSearch: 'Pesquisa avançada',
    duration: 'Duração',
    regionCoverage: 'Região de cobertura',
    AverageEarn: 'Média de ganhos',
    like: 'Gostar',
    joinNow: 'Juntar-se agora!',
    joined:"Ir para tarefa",
    comingSoon: 'Em breve',
    expired: 'Expirado',
    rewards:'Prêmios',
    worldWide:'Worldwide',



    


}

export default campaigns