const login = {
    emailPlacehold: '邮箱地址',
    passwordPlacehold: '密码',
    login: '登录',
    forgetPassword: '忘记密码',
    notMember: '还未注册',
    clickToRegister: '点击注册',
    or: '或者',
    socialAccount: '第三方账号快速登录',
    pleaseAccount: '请输入你的账号',
    pleasePassword: '请输入你的密码',
}
export default login