const campaignsDetail = {
    campaignId: 'ID da campanha',
    campaignType: 'Tipo da campanha',
    campaignDuration: 'Duração',
    freeSample: 'Amostra grátis',
    yes:'Sim',
    no:'Não',
    averageEarn: 'Ganhos médios',
    like: 'Gostar',
    applyNow: 'Aplicar agora',
    task: 'Tarefa',
    step: 'Passo',
    step1: 'Aplicar e receber um código promocional exclusivo.',
    step2: 'Compartilhar a campanha em suas mídias sociais.',
    step3: 'Enviar prints de prova ou links para anyStarr.',

    shortStep1:'Criar conteúdo que encontre os requerimentos da campanha',
    shortStep2:'Eviar o rascunho do conteúdo para revisão da marca',

    shortStepDes:'Pequenos passos',
    shortStepDes1:'Upload the content for review',
    shortStepDes2:'Share on social media',
    shortStepDes3:'Upload your post screenshot and link',
    followRule1:'1.Follow @abcomoofficial instagram account',
    followRule2:'2.Commited to post unboxing video + post with yourself wearing it',
    followRule3:'3.Sharing of your unique code to your followers in the post',
    sampleApplication: 'Aplicação da amostra',
    terms: 'Termos',
    mySampleApplication: 'A minha aplicação de amostra',
    submitApplication: 'Submeter aplicação',
    yourIns: "O seu Instagram",

    abcomoStep1:'Aplicar e receber a amostra',
    abcomoStep2:'Compartilhar a campanha em suas mídias sociais.',
    abcomoStep3:'Enviar prints de prova ou links para anyStarr.',

}

export default campaignsDetail