const login = {
    emailPlacehold: 'E-mail',
    passwordPlacehold: 'Has³o',
    login: 'Zaloguj siê',
    forgetPassword: 'Nie pamiêtam has³a',
    notMember: "Nie masz konta?",
    clickToRegister: 'Zarejestruj siê',
    or: 'lub',
    socialAccount: 'Zaloguj przez portal spo³ecznoœciowy',
    pleaseAccount: 'Nie podano adresu e-mail',
    pleasePassword: 'Nie podano has³a',

    resetPassword:"Zresetuj has³o",
    resetPasswordDes:"WprowadŸ i potwierdŸ nowe has³o poni¿ej, aby uzyskaæ dostêp do swojego konta",
    invitecodeTitle:"Aby kontynuowaæ, wprowadŸ kod zaproszenia otrzymany od naszego przedstawiciela.",
    emailCodeTitle:"WprowadŸ kod weryfikacyjny z wiadomoœci e-mail wys³anej na adres podany podczas rejestracji.",
    emailCodeDes:"PotwierdŸ, ¿e ten adres e-mail nale¿y do Ciebie.",
    complete:"Ukoñczono",
    sendAgain:"Wyœlij ponownie",

    haveAccount:'Konto zosta³o ju¿ utworzone',
    activationDes:"Prawie skończone ",
    activationDes1:"Zresetuj hasło, aby aktywować swoje konto",
    linkExpired:"Ten link aktywacyjny wygasł",
    requestNewActivation:"Poproś o nowy e-mail aktywacyjny lub utwórz nowe konto",
    confirm:"potwierdzić",
    googleRegister:"Zarejestruj się w google",
    sendAcEmail:"Być może jesteś już zarejestrowany. Kontynuuj aktywację swojego konta poprzez email aktywacyjny wysłany na adres ",
    hadRegister:"Być może już utworzyłeś konto. Proszę kontynuować logowanie.",

}
export default login