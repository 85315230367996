
import { param } from 'jquery'
import host from '../tools/host'
import request from '../tools/request'


//登录
export const APIlogin = params => {
    return request.get(`${host}/api/user/login`, params)
}

//登出
export const APIlogout = (params) => {
    return request.get(`${host}/api/user/logout`, params)
}

//注册
export const APIregister = params => {
    return request.post(`${host}/api/user/register`, params)
}

// 注册判断
export const APIverifyUserMailbox = params => {
    return request.post(`${host}/api/user/verify_user_mailbox`, params)
}

//发送激活链接邮件
export const APIsendActiveEmailNew = params => {
    return request.post(`${host}/api/user/send_active_email_new`, params)
}

//emial login
export  const APIemailLogin = params => {
    return request.post(`${host}/api/user/email_login`, params)
}

//获取国家
export const APIgetCountry = params => {
    return request.get(`${host}/common/sys/find_countries`, params)
}

//根据邀请码获取邮箱
export const APIgetEmailByInvitecode = params => {
    return request.get(`${host}/api/user/get_email`, params)
}

//第三方登录
export const APIfederatedLogin = params => {
    return request.post(`${host}/api/user/federated_login`, params)
}

//发送邮箱验证码
export const APIsendVerificationCode = params => {
    return request.post(`${host}/api/user/send_verification_code`, params)
}

//第三方登录后续账号绑定
export const APIbindUser = params => {
    return request.post(`${host}/api/user/bind_user`, params)
}

//获取AllCampaigns
export const APIgetAllCampaigns = params => {
    return request.post(`${host}/api/campaign/find_all_campaigns`, params)
}

//根据id 获取campaign 详情
export const APIgetCampaignDetail = params => {
    return request.post(`${host}/api/campaign/get_campaign_detail`, params)
}

//campaign like or unlike
export const APIlikeCampaign = params => {
    return request.post(`${host}/api/campaign/like_campaign`, params)
}

//申领campaign
export const APIapplyCampaign = (params,props) => {
    return request.post(`${host}/api/campaign/apply_campaign`, params, props)
}

//my task list
export const APIfindMyTasks = (params, props) => {
    return request.post(`${host}/api/task/find_my_tasks`, params)
}

//task 详情
export const APIgetTaskDetail = params => {
    return request.post(`${host}/api/task/get_task_detail`, params)
}

//上传文件
export const APIuploadFile = params => {
    return request.upload(`${host}/common/upload/upload_pic`, params)
}

//提交task
export const APIsubmitTask = params => {
    return request.post(`${host}/api/task/submit_task`, params)
}

//申请促销码
export const APIgetPromoCode = params => {
    return request.post(`${host}/api/task/get_promo_code`, params)
}

//校验邮箱验证码
export const APIcheckVerificationCode = params => {
    return request.post(`${host}/api/user/check_verification_code`, params)
}


//校验邮箱需要判断是否注册过
export const APIcheckVerificationCodeNormal = params => {
    return request.post(`${host}/api/user/check_email_and_code`, params)
}


//my profile

export const APIgetMyProfile = (params,props) => {
    return request.post(`${host}/api/user/get_my_profile`, params, props)
}

//modify profile

export const APImodifyUserInfo = params => {
    return request.post(`${host}/api/user/modify_user_information`, params)
}

//modify password
export const APImodifyPassword = params => {
    return request.postJson(`${host}/api/user/change_my_password`, params)
}

//modify social
export const APImodifySocial = params => {
    return request.post(`${host}/api/user/set_user_social`, params)
}

//modify social
export const APImodifySocialList = params => {
    return request.postJson(`${host}/api/user/set_user_social_list`, params)
}

//account balance
export const APIgetAccountBalance = (params,props) => {
    return request.post(`${host}/api/payment/get_account_balance`, params,props)
}

//apply withdrawlal
export const APIapplyWithdrawal = params => {
    return request.post(`${host}/api/payment/apply_withdrawal`, params)
}


//find my pay account
export const APIgetMyPayAccount = params => {
    return request.post(`${host}/api/user/find_my_pay_account`, params)
}

export const APIgetWithdrawHistory = params => {
    return request.post(`${host}/api/payment/find_my_payout`, params)
}

export const APIgetToBeReviewed = params => {
    return request.post(`${host}/api/payment/find_my_review_payout`, params)
}


//get  bank list
export const APIgetBankList = params => {
    return request.get(`${host}/common/sys/find_bank`, params)
}


//create bank account
export const APIcreatePayAccount = params => {
    return request.post(`${host}/api/user/create_pay_account`, params)
}

//delete bank account
export const APIdeletePayAccount = params => {
    return request.post(`${host}/api/user/remove_my_pay_account`, params)
}

//modify user avatar
export const APImodifyUserAvatar = params => {
    return request.post(`${host}/api/user/modify_user_avatar`, params)
}


//send message
export const APIsendMessage = params => {
    return request.post(`${host}/api/message/send_message`, params)
}

//anystarr updates list
export const APIgetMyPostList = (params, props) => {
    return request.post(`${host}/api/message/find_my_post_task`, params, props)
}


//get post detail
export const APIgetPostTaskDetail = params => {
    return request.post(`${host}/api/message/get_post_task_detail`, params)
}

//get post number
export const APIgetPostNum = params => {
    return request.post(`${host}/api/message/get_unread_quantity`, params)
}

//get my address
export const APIgetMyAddress = (params, props) => {
    return request.post(`${host}/api/user/find_my_address`, params, props)
}

//modify or create address
export const APIcreateOrModifyAddress = params => {
    return request.post(`${host}/api/user/create_or_modify_user_address`, params)
}

//delete address
export const APIdeleteAddress = params => {
    return request.post(`${host}/api/user/remove_user_address`, params)
}


// code commission list
export const APIgetMyCodeCommission = params => {
    return request.post(`${host}/api/payment/find_my_code_commission`, params)

}

// user code task total earning
export const APIgetMyCodeDetail = params => {
    return request.post(`${host}/api/payment/find_my_code_detail`, params)
}

//verification password
export const APIverificationPassword = params => {
    return request.post(`${host}/api/user/check_original_password`, params)
}


//message list
export const APIgetMyMessage = params => {
    return request.post(`${host}/api/message/find_my_message`, params)
}

//message detail
export const APIgetMessageDetail = params => {
    return request.post(`${host}/api/message/get_message_detail`, params)
}

//message close
export const APIcloseTicket = params => {
    return request.post(`${host}/api/message/close_this_ticket`, params)
}

//message ask
export const APIaskTicket = params => {
    return request.post(`${host}/api/message/ask_again`, params)
}


//register category list
export const APIgetCategory = params => {
    return request.post(`${host}/api/category/find_category_show`, params)
}

//apply sample
export const APIApplySample = params => {
    return request.post(`${host}/api/task/apply_sample`, params)
}

// task 二次审核接口
export const APIContentSubmit = params => {
    return request.post(`${host}/api/task/submit_content`, params)
}

//user 是否需要激活
export const APIactiveUser = params => {
    return request.post(`${host}/api/user/active_user`, params)
}

//get campaigns country list
export const APIgetCampaignCountries = params => {
    return request.post(`${host}/api/campaign/get_campaign_countries`, params)
}

//send active email
export const APIsendActiveEmail = params => {
    return request.post(`${host}/api/user/send_active_email`, params)
}

//apply sample
export const APIapplySampleNew = (params, props) => {
    return request.post(`${host}/api/sample/apply_sample_new`, params, props)
}

//get campaign content
export const APIgetCampaignContent = (params) => {
    return request.get(`${host}/api/campaign/get_campaign_content`, params)
}

//ins bind
export const APIinsAccountInfo = (params) => {
    return request.postJson(`${host}/api/user/ins_account_info`, params)
}

//my sample list
export const APIgetMySampleList = (params) => {
    return request.get(`${host}/api/sample/my_sample_list`, params)
}

// sample detail
export const APIgetSampleDetail = (params) => {
    return request.get(`${host}/api/sample/get_sample_detail`, params)
}

// get referral code
export const APIgetReferralCode = (params,token) => {

    return request.get(`${host}/api/user/my_referral_code`,params,token)
}

export const APIgetMyReferralList = (params,token) => {
    return request.get(`${host}/api/user/my_referral_list`,params,token)
}

export const APIgetReferralEarn = (params,token) => {
    return request.get(`${host}/api/user/my_referral_earnings`,params,token)
}

export const APIgetCampaignDetailNew = (params) => {
    return request.get(`${host}/api/campaign/detail`, params)
}

//新版campaign list
export const APIgetCampaignListNew = (params) => {
    return request.postJson(`${host}/api/campaign/campaign_list`, params)
}

// 新版 task list
export const APIgetTaskListNew = (params) => {
    return request.postJson(`${host}/api/new_task/my_tasks`, params)
}

// 新版任务详情
export const APIgetTaskDetailNew = (params) => {
    return request.get(`${host}/api/new_task/task_detail`, params)
}

// 领取活动码
export const APIreceivePromoCode = (params) => {
    return request.get(`${host}/api/new_task/receive_promo_code`, params)
}

// 任务提交
export const APIsubmitTaskNew = (params) => {
    return request.postJson(`${host}/api/new_task/submit_task`,params)
}


export const APIfbAccountInfo = (params) => {
    return request.post(`${host}/api/user/fb_account_info`, params)
}

export const APIshareMediaPage = (params) => {
    return request.get(`${host}/api/task/share/media/page`,params)
}

export const APIshareMediaPublish = (params) => {
    return request.post(`${host}/api/task/share/media/pulish`, params)
}

export const APIgetBankCountry = (params) => {
    return request.get(`${host}/api/bank/country/list`, params)
}

export const APIgetExternalProductList = (params) => {
    return request.postJson(`${host}/api/product/external_product_list`, params)
}

export const APIgetExternalProductDetail = (params) => {
    return request.get(`${host}/api/product/external_product_detail`,params)
}

export const APIgetBankType = (params) => {
    return request.get(`${host}/api/bank/type`, params)
}

export const APIGetCompainList = (params) => {
    return request.get(`${host}/api/v1/tiktokCampaign/getCampaign`, params)
}
export const APIGetCompainById = (params) => {
    return request.get(`${host}/api/v1/tiktokCampaign/getPostTotalDetail?campaignId=${params.id}`)
}

export const APIGetCompainInfo = (params) =>{
  return request.get(`${host}/api/v1/tiktokCampaignDetail/getCampaign`, params)
}

export const APIGetDashDataTotal = () => {
  return request.get(`${host}/api/v1/tiktokCampaign/getPostTotal`)
}

export const APISaveShopping = (params) => {
  return request.postJson(`${host}/api/v1/tiktokBusinessInfo/save`, params)
}

export const APIGetList = () => {
  return request.get(`${host}/api/v1/tiktokBusinessInfo/list`)
}

export const APIGetKolStat = (params) => {
  return request.get(`${host}/api/v1/kolStatistics/homeDefault`, params)
}

export const APIGetProductStat = (params) => {
    return request.get(`${host}/api/v1/productStatistics/homeDefault`, params)
}

export const APIGetDashDataTotalDownLoadPost = (params) => {
  return request.get(`${host}/api/v1/tiktokCampaign/downloadPost`, params)
}

export const APIGetDashDataTotalDownLoadOrder = (params) => {
  return request.get(`${host}/api/v1/tiktokCampaign/downloadOrder`, params)
}

export const APIGetCompaignLine = (params) => {
  return request.get(`${host}/api/v1/campaignStatistics/getTotalCampaignStatistics?campaignId=${params.id}`)
}

export const APIGetKolData = (params) => {
  return request.get(`${host}/api/v1/kolStatistics/getTotalCampaignStatistics?kolName=${params.name}`)
}

export const APIGetProductLineData = (params) => {
    return request.get(`${host}/api/v1/productStatistics/getTotalCampaignStatistics?productId=${params.id}`)
}

export const APIRefresh = () => {
  return request.get(`${host}/api/v1/tiktokCampaign/forceRefresh`)
}

export const APIGetNumber = () => {
  return request.get(`${host}/api/v1/tiktokTapTable/getUnDuplicateRemote`)
}

export const APIGetNewNumber = () => {
    return request.get(`${host}/api/v1/tiktokTapTable/getUnDuplicateByHandle`)
}

// export const APIGetViewDataCharts = (day) => {
//     return request.get(`${host}/api/v1/tiktokCampaign/getHomeStatistics?dataKey=${day}`)
// }

export const APIGetStatistics = (time) => {
    return request.postJson(`${host}/api/v1/tiktokCampaign/getHomeStatistics`, JSON.stringify({
      time:time
    }))
}

export const APIGetTypeStatistics = (time) => {
    return request.postJson(`${host}/api/v1/tiktokCampaign/getHomeStatisticsCategory`, JSON.stringify({
        time:time
    }))
}

export const APIGetGMVListData = () => {
  return request.get(`${host}/api/v1/tiktokCampaign/dataHandle`, {})
}

export const APIGetGMVListDataByGandle = (handleName) => {
  return request.get(`${host}/api/v1/tiktokCampaign/dataHandleDetail?handle=${handleName}`, {})
}

export const APIGetL2PostNum = () => {
  return request.post(`${host}/api/v1/handleAnalyze/getAllPost`)
}

export const APIPostGoogle = () => {
  return request.get(`${host}/api/v1/googleExcel/processData`, {})
}

export const APIGetPreDownload = (params) => {
  return request.get(`${host}/api/v1/handlesTiktokData/preDownload`, params)
}
